import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Link, useConfirm, useSnackbars } from '@onesy/ui-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IForm } from '@onesy/api';

import IconMaterialDemography from '@onesy/icons-material-rounded-react/IconMaterialDemographyW100';

import { FormFormResponseRead, ItemCard, ItemMenu } from 'ui';
import { AppService, FormService } from 'services';
import { getDate, getErrorMessage } from 'utils';

export interface IFormItem extends IBaseElement {
  object: IForm;
}

const Element: React.FC<IFormItem> = React.forwardRef((props, ref: any) => {
  const {
    object,

    ...other
  } = props;

  const navigate = useNavigate();
  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const onOpen = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      open: true,

      secondary: true,

      children: (
        <FormFormResponseRead
          object={object}

          form={object.form}

          onConfirm={() => FormService.queryObjectsResponses.value!.refetch()}
        />
      )
    });
  }, [object]);

  const onRemove = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Removing form response`
    });

    if (!confirmed) return;

    const result = await FormService.removeResponse(object.form.id, object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Form response removed`
      });
    }

    // refresh 
    FormService.queryObjectsResponses.value!.refetch();
  }, [object]);

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          icon: IconMaterialDemography,
          onClick: onOpen
        },
        {
          name: 'Name',
          value: object.name,
          onClick: onOpen
        },

        ...(object.customer ? [
          {
            name: 'Client',
            value: (
              <Link
                version='t3'

                onClick={() => navigate(`/clients/${object.customer.id}`)}
              >
                {object.customer.name}
              </Link>
            )
          }
        ] : []),
      ]}

      items_end={[
        {
          name: 'Added at',
          value: getDate(object?.added_at!),
          align: 'flex-end',
          propsValue: {
            whiteSpace: 'nowrap'
          }
        },
        {
          value: (
            <ItemMenu
              items={[
                { name: 'Open', onClick: onOpen },
                { name: 'Remove', onClick: onRemove, props: { color: 'error' } }
              ]}
            />
          )
        }
      ]}

      {...other}
    />
  )
});

export default Element; 