import React from 'react';

import { capitalize, is, setObjectValue } from '@onesy/utils';
import { Line, ListItem } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import { Input, NumericTextField, Select } from 'ui';

const useStyle = style(theme => ({
  root: {
    '& .onesy-Select-input-wrapper': {
      width: 'auto'
    },

    '& .onesy-Select-input': {
      justifyContent: 'flex-start'
    },

    '& .onesy-TextField-input-wrapper, & .onesy-TextField-sufix': {
      paddingBlock: '4px !important'
    }
  }
}), { name: 'onesy-DurationForm' })

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    name = 'Duration',

    form,

    property = 'duration',

    object = props.form?.value?.[property] || {},

    start,

    end,

    onUpdate,

    rerender = true,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const update = React.useCallback((...args: any) => {
    if (form) form.onChange(...args);
    else if (object !== undefined && onUpdate) {
      const items = is('array', args[0]) ? args[0] : [args];

      const object_ = object || {};

      items.forEach(item => {
        setObjectValue(object_, item[2], item[1]);
      });

      onUpdate(property, object_);
    }
  }, [form, object, onUpdate]);

  const options = React.useMemo(() => {
    return [
      {
        name: 'No duration',
        onClick: () => {
          setType('regular');

          update(property, {});
        }
      },
      {
        name: '15 minutes',
        value: '15 minute',
        onClick: () => {
          setType('regular');

          update([
            [property, 15, 'value'],
            [property, 'minute', 'unit']
          ]);
        }
      },
      {
        name: '30 minutes',
        value: '30 minute',
        onClick: () => {
          setType('regular');

          update([
            [property, 30, 'value'],
            [property, 'minute', 'unit']
          ]);
        }
      },
      {
        name: '45 minutes',
        value: '45 minute',
        onClick: () => {
          setType('regular');

          update([
            [property, 45, 'value'],
            [property, 'minute', 'unit']
          ]);
        }
      },
      {
        name: '1 hour',
        value: '1 hour',
        onClick: () => {
          setType('regular');

          update([
            [property, 1, 'value'],
            [property, 'hour', 'unit']
          ]);
        }
      },
      {
        name: '2 hours',
        value: '2 hour',
        onClick: () => {
          setType('regular');

          update([
            [property, 2, 'value'],
            [property, 'hour', 'unit']
          ]);
        }
      },
      {
        name: '5 hours',
        value: '5 hour',
        onClick: () => {
          setType('regular');

          update([
            [property, 5, 'value'],
            [property, 'hour', 'unit']
          ]);
        }
      },
      {
        name: '10 hours',
        value: '10 hour',
        onClick: () => {
          setType('regular');

          update([
            [property, 10, 'value'],
            [property, 'hour', 'unit']
          ]);
        }
      },
      {
        name: '1 day',
        value: '1 day',
        onClick: () => {
          setType('regular');

          update([
            [property, 1, 'value'],
            [property, 'day', 'unit']
          ]);
        }
      },
      {
        name: '1 week',
        value: '1 week',
        onClick: () => {
          setType('regular');

          update([
            [property, 1, 'value'],
            [property, 'week', 'unit']
          ]);
        }
      },
      {
        name: 'Custom',
        value: 'custom',
        onClick: () => {
          setType('custom');
        }
      }
    ]
  }, [form]);

  const empty = !Object.keys(object || {}).length;

  const optionExists = options.find(item => `${object?.value} ${object?.unit}` === item.value);

  const [type, setType] = React.useState<any>((!empty && !optionExists) ? 'custom' : 'regular');

  const isCustom = !!(!empty && (type === 'custom' || !optionExists));

  const value = object !== undefined ? object?.value : form?.values?.duration?.value?.value;

  const unit = object !== undefined ? object?.unit : form?.values?.duration?.value?.unit;

  const optionsDuration = React.useMemo(() => ['minute', 'hour', 'day', 'week', 'month', 'year'].map(item => ({
    name: `${capitalize(item)}${value === 1 ? '' : 's'}`,
    value: item
  })), [value]);

  return (
    <Input
      gap={0}

      name={name}

      TypeProps={{
        version: 't3'
      }}

      MainProps={{
        gap: 1,
        direction: 'row',
        align: 'center',
        flexNo: true
      }}

      size='small'

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {start}

      <Select
        {...rerender ? {
          value: empty ? null : type === 'custom' ? 'custom' : `${object?.value} ${object?.unit}`
        } : {
          valueDefault: empty ? null : type === 'custom' ? 'custom' : `${object?.value} ${object?.unit}`
        }}

        version='text'

        noSelectText='No duration'

        align='center'

        enabled={!empty}

        minimal

        WrapperProps={{
          style: {
            width: 'auto',
            height: 'unset',
            minWidth: 'unset'
          }
        }}

        style={{
          width: 'auto',
          height: 'unset',
          minWidth: 'unset'
        }}
      >
        {options.map((item: any) => (
          <ListItem
            key={item.name}

            primary={item.name}

            value={item.value}

            onClick={item.onClick}

            selected={item.name === 'No duration' ? !!empty : item.name === 'Custom' ? isCustom : undefined}

            button
          />
        ))}
      </Select>

      {type === 'custom' && (
        <Line
          gap={1}

          direction='row'

          align='center'

          fullWidth
        >
          <NumericTextField
            version='text'

            placeholder='100'

            {...rerender ? {
              value
            } : {
              valueDefault: value
            }}

            onChange={(valueNew: any) => update(property, valueNew, 'value')}

            min={0}

            align='end'

            minimal

            fullWidth

            style={{
              width: 'auto',
              minWidth: 34
            }}
          />

          <Select
            version='text'

            {...rerender ? {
              value: unit
            } : {
              valueDefault: unit
            }}

            onChange={(valueNew: any) => update(property, valueNew, 'unit')}

            options={optionsDuration}

            noSelectText='Unit'

            minimal

            fullWidth

            WrapperProps={{
              style: {
                width: 60,
                height: 'unset',
                minWidth: 'unset'
              }
            }}

            style={{
              width: 60,
              height: 'unset',
              minWidth: 'unset'
            }}
          />
        </Line>
      )}

      {end}
    </Input>
  );
});

export default Element;
