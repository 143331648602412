import { Customer } from '@onesy/api';
import { IResponse } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService } from 'services';

class CustomerService extends BaseService<Customer> {
  public customer: Customer;

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('customers');
  }

  public async email(id: string): Promise<IResponse> {
    return MainService.sdk.customers.email(id);
  }

}

export default new CustomerService();
