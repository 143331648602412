import React from 'react';

import { setObjectValue } from '@onesy/utils';
import { Line, useSubscription } from '@onesy/ui-react';

import { Input, NumericTextField } from 'ui';
import { AuthService } from 'services';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    object,

    onUpdate,

    rerender = true,

    ...other
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const currency = signedIn.organization?.settings?.currency || 'EUR';

  const update = React.useCallback((property: string, valueNew: any, propertyNested: any, other?: any) => {
    if (form) form.onChange(property, valueNew, propertyNested, other);
    else if (object !== undefined && onUpdate) {
      const price = object || {};

      setObjectValue(price, propertyNested, valueNew);

      onUpdate('price', price);
    }
  }, [form, object, onUpdate]);

  return (
    <Input
      name='Price'

      endHeader={(
        <Line
          gap={1}

          direction='row'

          wrap='wrap'

          align='center'

          justify={{
            default: 'flex-start',
            700: 'flex-end'
          }}

          fullWidth={{
            default: false,
            700: true
          }}

          flexNo
        >
          <NumericTextField
            placeholder='100'

            {...rerender ? {
              value: object !== undefined ? object?.value : form?.values?.price?.value?.value
            } : {
              valueDefault: object !== undefined ? object?.value : form?.values?.price?.value?.value
            }}

            onChange={(valueNew: any) => update('price', valueNew, 'value')}

            min={0}

            sufix={currency}

            align='end'

            minimal

            fullWidth

            style={{
              width: 'auto',
              minWidth: 124
            }}
          />
        </Line>
      )}

      {...other}
    />
  );
});

export default Element;
