import React from 'react';

import { is } from '@onesy/utils';
import { CalendarMenu, ListItem, Type, useSnackbars, useSubscription } from '@onesy/ui-react';
import { style } from '@onesy/style-react';
import { IUserPush } from '@onesy/sdk/other';
import { OnesyDate, add, format, is as Is, remove, startOf } from '@onesy/date';
import { ICalendarMenu } from '@onesy/ui-react/CalendarMenu/CalendarMenu';

import IconMaterialCalendarToday from '@onesy/icons-material-rounded-react/IconMaterialCalendarTodayW100';

import { AuthService, UserService } from 'services';
import { getErrorMessage } from 'utils';
import { ISignedIn } from 'types';
import config from 'config';
import Reminders from './Reminders';
import Repeat from './Repeat';

const useStyle = style(theme => ({
  root: {
    '& .onesy-TimePicker-inputs': {
      '& input': {
        width: '100% !important'
      }
    }
  },

  weekday: {
    cursor: 'pointer'
  },

  calendar: {
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',

    '& .onesy-Calendar-header': {
      paddingTop: 0
    },

    '& .onesy-Calendar-calendars': {
      paddingBottom: 4
    }
  },

  repeatEndMenu: {
    '& .onesy-List-root': {
      width: '100%'
    }
  },

  minorMenu: {
    '& .onesy-List-root': {
      width: '100%'
    }
  },

  menuMore: {
    width: '100%',
    padding: 12,
    borderRadius: 4,

    '& .onesy-TextField-input-wrapper': {
      paddingBlock: 12,
      height: 40
    },

    '& .onesy-TextField-icon-end': {
      paddingBlock: 8
    }
  },

  item: {
    '& .onesy-ListItem-root': {
      minHeight: '30px !important'
    },

    '&.onesy-Surface-root': {
      background: 'transparent'
    }
  },

  iconInactive: {
    opacity: 0,
    pointerEvents: 'none'
  },

  endDate: {
    '&.onesy-ListItem-root': {
      padding: '2px 4px'
    },

    '& .onesy-ListItem-start-icon': {
      paddingInline: '0 4px'
    }
  },

  endDateWrapper: {
    flex: '0 0 auto',

    '&.onesy-ListItem-wrapper': {
      width: 'auto'
    }
  }
}), { name: 'onesy-CalendarMenu' });

const Element: React.FC<ICalendarMenu> = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    dateProperty = 'ends_at',

    menuOpen,

    menuOpenDefault,

    time = false,

    onChange: onChange_,

    onRemove: onRemove_,

    noRepeat,

    noReminders,

    RepeatProps,

    RemindersProps,

    style: styleProps,

    children,

    ...other
  } = props;

  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const { classes } = useStyle();

  const [minorMenuOpen, setMinorMenuOpen] = React.useState<any>(menuOpenDefault !== undefined ? menuOpenDefault : menuOpen !== undefined ? menuOpen : false)
  const [timeMenuOpen, setTimeMenuOpen] = React.useState<any>(false);

  const refs = {
    value: React.useRef(value),
    dateProperty: React.useRef(dateProperty),
    time: React.useRef<HTMLElement>(undefined),
    repeat: React.useRef<HTMLElement>(undefined),
    repeatEnd: React.useRef<HTMLElement>(undefined),
    onChange: React.useRef(onChange_),
    signedIn: React.useRef(signedIn),
    repeatCount: React.useRef<any>({})
  };

  refs.dateProperty.current = dateProperty;

  refs.value.current = value;

  refs.onChange.current = onChange_;

  refs.signedIn.current = signedIn;

  const onChange = React.useCallback(async (...args: any) => {
    const values = is('array', args?.[0]) ? args?.[0] : [...args];

    if (is('function', refs.onChange.current)) refs.onChange.current(...args);

    const reminderActive = values.find((item: any) => item?.[0] === 'reminders' && item?.[2]?.includes('active') && item?.[1]);

    // push notification subscribe
    if (reminderActive) {
      if ('serviceWorker' in navigator) {
        const registration = await window.navigator.serviceWorker.ready;

        if (registration) {
          try {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: config.value?.webPush.vapid.public_key
            });

            const push = refs.signedIn.current.user?.settings?.push || [];

            const exists = push.find((item: any) => item.value?.endpoint === subscription.endpoint);

            if (!exists && subscription) {
              const body = {
                push: {
                  value: subscription.toJSON()
                }
              } as IUserPush;

              const result = await UserService.pushSubscribe(body);

              if (result.status >= 400) {
                snackbars.add({
                  color: 'error',
                  primary: getErrorMessage(result)
                });
              }
              else {
                snackbars.add({
                  primary: `Push notifications enabled on this device`
                });
              }

              AuthService.me();
            }
          }
          catch (error) {
            console.error('Push notification subscribe', error);
          }
        }
      }
    }
  }, []);

  const onMinorMenuOpen = React.useCallback((value: string) => {
    setMinorMenuOpen(value || true);

    setTimeMenuOpen(false);
  }, []);

  const onMinorMenuClose = React.useCallback(() => {
    setMinorMenuOpen(false);
  }, []);

  const onRemove = React.useCallback(() => {
    if (is('function', onRemove_)) onRemove_(value);
  }, [value, onRemove_]);

  let valueDate: string = 'Due date';

  const dates: any = {};

  if (value?.[dateProperty]) {
    const now = new OnesyDate();
    const ends = new OnesyDate(value?.[dateProperty]);

    valueDate = format(ends, `DD MMM${time ? ', HH:mm[h]' : ''}`);

    const starts = {
      now: startOf(now, 'day'),
      ends: startOf(ends, 'day')
    };

    dates.inThePast = Is(starts.ends, 'before', starts.now);

    dates.yesterday = format(remove(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.today = format(now, 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    dates.tomorrow = format(add(1, 'day', now), 'DD MMM YYYY') === format(ends, 'DD MMM YYYY');

    if (dates.yesterday) valueDate = `Yesterday, ${valueDate}`;

    if (dates.today) valueDate = `Today, ${valueDate}`;

    if (dates.tomorrow) valueDate = `Tomorrow, ${valueDate}`;
  }

  const color = !value?.[dateProperty] ? 'default' : !dates.inThePast ? 'success' : 'error';

  return (
    <CalendarMenu
      value={value}

      dateProperty={dateProperty}

      onChange={onChange}

      onRemove={onRemove}

      menuOpen={menuOpen}

      menuOpenDefault={menuOpenDefault}

      timeMenuOpen={timeMenuOpen}

      endBottom={<>
        {!noReminders && value[dateProperty] && (
          <Reminders
            value={value}

            onChange={onChange}

            minorMenuOpen={minorMenuOpen}

            onMinorMenuOpen={onMinorMenuOpen}

            onMinorMenuClose={onMinorMenuClose}

            classes={classes}

            {...RemindersProps}
          />
        )}

        {!noRepeat && value?.[dateProperty] && (
          <Repeat
            value={value}

            onChange={onChange}

            minorMenuOpen={minorMenuOpen}

            onMinorMenuOpen={onMinorMenuOpen}

            onMinorMenuClose={onMinorMenuClose}

            classes={classes}

            {...RepeatProps}
          />
        )}
      </>}

      includeParentQueries={['.onesy-repeat', '.onesy-repeat-end', '.onesy-time', '.onesy-reminder']}

      {...other}
    >
      {children || (
        <ListItem
          primary={(
            <Type
              version='l2'

              weight={400}

              color={color}

              whiteSpace='nowrap'
            >
              {valueDate}
            </Type>
          )}

          start={(
            <IconMaterialCalendarToday
              color={color}
            />
          )}

          startAlign='center'

          size='small'

          button

          noBackground

          Component='div'

          WrapperProps={{
            className: classes.endDateWrapper
          }}

          RootProps={{
            className: classes.endDate
          }}

          style={{
            ...styleProps
          }}
        />
      )}
    </CalendarMenu>
  );
});

export default Element;
