import React from 'react';

import { is } from '@onesy/utils';
import { useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';

import { AutoCompleteObjects, DatePicker, Input, Inputs, ModalForm, NumericTextField, Repeat, Select } from 'ui';
import { AppService, AuthService, CategoryService, OrganizationService, TransactionService } from 'services';
import { getErrorMessage, optionsPaymentWith, optionsTransactionFor } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm
  } = props;

  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'payment.value': {
        name: 'Payment value',
        required: true,
        is: 'number',
        min: 0
      },
      'for': {
        name: 'For',
        is: 'string',
        value: 'salary'
      },
      'payment_with': {
        name: 'Payment with',
        value: 'cash',
        is: 'string'
      },
      'made_at': {
        name: 'Payment date',
        required: true,
        value: OnesyDate.milliseconds,
        is: 'number'
      },
      'employee': {
        name: 'Employee',
        is: 'object'
      },
      'categories': {
        name: 'Expense categories',
        is: 'array',
        of: 'object'
      },
      'repeat': {
        name: 'Repeat',
        value: {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      ...valueForm
    } = refs.form.current.value;

    const body = {
      type: 'expense',

      ...valueForm,

      apps: ['personal-trainer']
    };

    if (body.employee) body.employee = {
      id: body.employee.id,
      name: body.employee.name
    };

    if (is('array', body.categories)) body.categories = body.categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = await TransactionService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Expense added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [form, onConfirm, onClose]);

  const currency = signedIn?.organization?.settings?.currency || 'EUR';

  const modal: any = {
    write: (
      <Inputs>
        <Input
          name='Payment'

          description='Payment amount'
        >
          <NumericTextField
            placeholder='1000'

            value={form.values['payment.value'].value}

            error={form.values['payment.value'].error}

            helperText={form.values['payment.value'].error}

            onChange={(valueNew: any) => form.onChange('payment.value', +valueNew)}

            min={0}

            sufix={currency}

            fullWidth
          />
        </Input>

        <Input
          name='Payment date'
        >
          <DatePicker
            value={form.values['made_at'].value ? new OnesyDate(form.values['made_at'].value) : null}

            error={form.values['made_at'].error}

            helperText={form.values['made_at'].error}

            onChange={(valueNew: any) => form.onChange('made_at', valueNew?.milliseconds || null)}

            fullWidth
          />
        </Input>

        <Input
          name='Expense for'
        >
          <Select
            value={form.values['for'].value}

            error={form.values['for'].error}

            helperText={form.values['for'].error}

            onChange={(valueNew: any) => form.onChange('for', valueNew)}

            options={optionsTransactionFor}

            fullWidth
          />
        </Input>

        <Input
          name='Payment using'
        >
          <Select
            options={optionsPaymentWith}

            value={form.values['payment_with'].value}

            error={form.values['payment_with'].error}

            helperText={form.values['payment_with'].error}

            onChange={(valueNew: any) => form.onChange('payment_with', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='Employee'

          description='Payment made for which employee (optional)'
        >
          <AutoCompleteObjects
            name='Employee'

            value={form.values.employee.value}

            onChange={(valueNew: any) => form.onChange('employee', valueNew)}

            service={OrganizationService}

            method='queryUsersPost'

            fullWidth
          />
        </Input>

        <Input
          name='Expense categories'
        >
          <AutoCompleteObjects
            name='Category'

            value={form.values.categories.value}

            onChange={(valueNew: any) => form.onChange('categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'expense',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'expense',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Repeat
          form={form}

          description='Auto repeat the expense'
        />
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      {...modal}

      add

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
