import React from 'react';

import { AudioRecorder } from '@onesy/ui-react'
import { IAudioRecorder } from '@onesy/ui-react/AudioRecorder/AudioRecorder';

const Element: React.FC<IAudioRecorder> = props => {

  return (
    <AudioRecorder
      IconProps={{
        size: props.size || 'large'
      }}

      {...props}
    />
  );
};

export default Element;
