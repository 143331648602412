import React from 'react';

import { Line, Type } from '@onesy/ui-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { classNames, style } from '@onesy/style-react';

const useStyles = style(theme => ({
  root: {

  },

  container: {
    position: 'relative'
  },

  example_logo: {
    position: 'absolute',
    top: '99px',
    left: '16px',
    height: 40,
    width: 'auto',
    cursor: 'default',
    pointerEvents: 'none'
  },

  example_favicon: {
    position: 'absolute',
    top: '8px',
    left: '97px',
    height: 'auto',
    width: 'auto',
    maxHeight: 20,
    maxWidth: 20,
    cursor: 'default',
    pointerEvents: 'none'
  },

  image: {
    userSelect: 'none',
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  imageSelected: {
    outline: `4px solid ${theme.palette.color.primary.main}`
  }
}), { name: 'onesy-PreviewTheme' });

export interface IPreviewTheme extends IBaseElement {
  start?: string;

  version?: 'light-preview' | 'dark-preview' | 'auto-preview';

  url?: string;

  selected?: boolean;

  example?: 'logo' | 'favicon';
}

const Element: React.FC<IPreviewTheme> = React.forwardRef((props, ref: any) => {
  const {
    name,

    version,

    start,

    selected,

    url,

    example,

    className,

    ...other
  } = props;

  const { classes } = useStyles();

  return (
    <Line
      gap={2}

      direction={{
        default: 'row',
        1100: 'column'
      }}

      align='flex-start'

      justify='flex-start'

      fullWidth

      className={classes.root}
    >
      {start}

      <Line
        gap={1}

        align='center'

        className={classes.container}
      >
        <img
          alt=''

          src={`/assets/images/preview/${version}.png`}

          className={classNames([
            className,
            example ? classes.imageExample : classes.image,
            selected && classes.imageSelected
          ])}

          draggable={false}

          style={{
            ...(example && {
              width: 280,
              aspectRatio: '1.53 / 1'
            })
          }}

          {...other}
        />

        {url && (
          <img
            alt=''

            src={url}

            className={classes[`example_${example}`]}
          />
        )}

        {name && (
          <Type
            version='b1'

            align='center'
          >
            {name}
          </Type>
        )}
      </Line>
    </Line>
  );
});

export default Element;
