import React from 'react';

import { SpeechToText } from '@onesy/ui-react'
import { ISpeechToText } from '@onesy/ui-react/SpeechToText/SpeechToText';

const Element: React.FC<ISpeechToText> = props => {

  return (
    <SpeechToText
      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
