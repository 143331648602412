import React from 'react';

import { capitalize, getID, innerHTMLToText, is, stringToColor, textToInnerHTML } from '@onesy/utils';
import { Buttons, Chip, IconButton, Tooltip, useForm, useSnackbars } from '@onesy/ui-react';
import { useOnesyTheme } from '@onesy/style-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, Color, DurationForm, Input, Inputs, MediaForm, ModalForm, NumericTextField, Select, SelectAColor, TextField } from 'ui';
import { AppService, ExerciseService, WorkoutService } from 'services';
import { getErrorMessage, optionsDayTime, optionsWorkoutStepType } from 'utils';
import Workout from 'pages/main/WorkoutPlans/Workouts/Workout';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'steps': {
        name: 'Steps',
        value: object?.steps || [],
        is: 'array',
        of: 'object'
      },
      'day_time': {
        name: 'Time of day',
        value: object?.day_time || 'morning',
        is: 'string'
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (body.steps) body.steps = body.steps.map(item => {
      if (item.exercise) item.exercise = {
        id: item.exercise.id,
        name: textToInnerHTML(item.exercise.name)
      };

      return item;
    });

    const result = !object?.id ? await WorkoutService.add(body) : await WorkoutService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Workout ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onAddStep = React.useCallback(() => {
    const steps = [...refs.form.current.values.steps.value];

    steps.push({
      id: getID(),
      type: 'exercise',
      repeat: 0,
      duration: {
        value: 10,
        unit: 'minute'
      }
    });

    refs.form.current.onChange('steps', steps);
  }, []);

  const onRemoveStep = React.useCallback((index: number) => {
    const steps = [...refs.form.current.values.steps.value];

    steps.splice(index, 1);

    refs.form.current.onChange('steps', steps);
  }, []);

  const modal: any = {
    read: <>
      <Workout
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    Info: <>
      <Inputs>
        <Input
          name='Time of day'
        >
          <Select
            value={form.value.day_time}

            error={form.values['day_time'].error}

            helperText={form.values['day_time'].error}

            onChange={(valueNew: any) => form.onChange('day_time', valueNew)}

            options={optionsDayTime}

            fullWidth
          />
        </Input>

        <DurationForm
          description='How long does the workout take'

          form={form}
        />

        <Inputs
          gap={3}

          gapMain={2.5}

          name='Steps'

          size='large'

          endName={(
            <Tooltip
              name='Add step'
            >
              <IconButton
                onClick={onAddStep}
              >
                <IconMaterialAdd
                  size='large'
                />
              </IconButton>
            </Tooltip>
          )}
        >
          {form.value.steps?.map((item, index: number) => {
            const palette = theme.methods.color(item.type === 'exercise' ? stringToColor(item.exercise?.name || 'exercise') : '#00b9ff');

            return (
              <Input
                key={item.id}

                name={`Step ${index + 1} ${capitalize(item.type)}: ${item.type === 'rest' ? ` ${item.duration.value} ${item.duration.unit}${item.duration.value === 1 ? '' : 's'}` : ` ${item.exercise?.name || 'Not provided'}`}`}

                endName={(
                  <Tooltip
                    name={`Remove step ${index + 1}`}
                  >
                    <IconButton
                      onClick={() => onRemoveStep(index)}
                    >
                      <IconMaterialRemove
                        size='large'
                      />
                    </IconButton>
                  </Tooltip>
                )}

                size='large'

                style={{
                  padding: 16,
                  background: palette[theme.palette.light ? 90 : 20]
                }}

                expand
              >
                <Buttons
                  value={item.type}
                >
                  {optionsWorkoutStepType.map((itemOption, indexOption) => (
                    <Chip
                      key={indexOption}

                      value={itemOption.value}

                      onClick={() => {
                        form.onChange('steps', itemOption.value, `${index}.type`)
                      }}
                    >
                      {itemOption.name}
                    </Chip>
                  ))}
                </Buttons>

                {item.type === 'exercise' && <>
                  <Input
                    name='Exercise'

                    description='Select an exercise for this step'

                    size='small'
                  >
                    <AutoCompleteObjects
                      name='Exercises'

                      value={item.exercise}

                      onChange={valueNew => form.onChange('steps', valueNew, `${index}.exercise`)}

                      service={ExerciseService}

                      query={{
                        onesy: true
                      }}

                      fullWidth
                    />
                  </Input>

                  <Input
                    name='Repeat'

                    description='How many times to repeat it'

                    size='small'
                  >
                    <NumericTextField
                      valueDefault={item.repeat}

                      onChange={(valueNew: any) => form.onChange('steps', valueNew, `${index}.repeat`)}

                      min={0}

                      fullWidth
                    />
                  </Input>
                </>}

                <DurationForm
                  object={item.duration}

                  onUpdate={(name: string, valueNew: any) => form.onChange('steps', valueNew, `${index}.duration`)}
                />
              </Input>
            );
          })}
        </Inputs>
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Description'
      >
        <TextField
          placeholder='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={3}

          maxRows={4}

          multiline

          fullWidth
        />
      </Input>

      <MediaForm
        type='image'

        name='Image'

        form={form}
      />

      <MediaForm
        type='audio'

        name='Audio'

        form={form}
      />

      <MediaForm
        type='video'

        name='Video'

        form={form}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Info', Icon: IconMaterialSmartDisplay },
        { name: 'About', Icon: IconMaterialInfo }
      ] : undefined}

      onChangeTab={onChangeTab}

      startHeaderRight={mode === 'update' ? (
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      ) : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;
