import React from 'react';

import { is } from '@onesy/utils';
import { useForm, useSnackbars } from '@onesy/ui-react';

import { Input, Inputs, ModalForm, TextField } from 'ui';
import { AppService, IngredientService } from 'services';
import { getErrorMessage, ingredientNutritionProperties } from 'utils';
import Ingredient from 'pages/main/NutritionPlans/Ingredients/Ingredient';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'nutrition': {
        name: 'Nutrition',
        value: object?.nutrition || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    const result = !object?.id ? await IngredientService.add(body) : await IngredientService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Ingredient ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    read: <>
      <Ingredient
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          gapMain={1.5}

          name='Nutritional info'

          description='Per 100g'
        >
          {[ingredientNutritionProperties.map(item => (
            <TextField
              name={item}

              valueDefault={form.values.nutrition.value[item]}

              onChange={(valueNew: any) => form.onChange('nutrition', valueNew, item)}

              fullWidth
            />
          ))]}
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      noWrite={object?.onesy}
    />
  );
});

export default Element;
