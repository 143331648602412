import React from 'react';

import { Line, Properties } from '@onesy/ui-react';
import { classNames } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IIngredient } from '@onesy/api';

export interface IIngredientElement extends IBaseElement {
  object: IIngredient;
}

const Element: React.FC<IIngredientElement> = React.forwardRef((props, ref: any) => {
  const {
    object,

    className,

    ...other
  } = props;

  const noValue = 'Not provided';

  return (
    <Line
      ref={ref}

      gap={4}

      flex

      fullWidth

      className={classNames([
        'onesy-Exercise',

        className
      ])}

      {...other}
    >
      <Line
        gap={2.5}

        fullWidth
      >
        <Properties
          version='row'

          rowGap={0.5}

          values={Object.keys(object.nutrition || {}).map(item => ({
            name: item,
            value: object.nutrition?.[item] !== undefined ? object.nutrition?.[item] : noValue
          }))}

          size='large'
        />
      </Line>
    </Line>
  );
});

export default Element;
