import React from 'react';

import { is } from '@onesy/utils';
import { Switch, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';

import { AutoCompleteObjects, DatePicker, Input, Inputs, ModalForm, NumericTextField, Repeat, Select } from 'ui';
import { AppService, AuthService, CategoryService, CustomerService, DiscountService, ServiceService, TransactionService } from 'services';
import { getErrorMessage, optionsPaymentWith } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    secondary,

    customer,

    ...other
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'payment.value': {
        name: 'Payment value',
        required: true,
        is: 'number',
        min: 0
      },
      'payment_with': {
        name: 'Payment with',
        value: 'cash',
        is: 'string'
      },
      'made_at': {
        name: 'Payment date',
        required: true,
        value: OnesyDate.milliseconds,
        is: 'number'
      },
      'customer': {
        name: 'Customer',
        value: customer ? {
          id: customer.id,
          name: customer.name
        } : undefined,
        is: 'object'
      },
      'service': {
        name: 'Service',
        is: 'object'
      },
      'discounts': {
        name: 'Discounts',
        is: 'array',
        of: 'object'
      },
      'categories': {
        name: 'Payment categories',
        is: 'array',
        of: 'object'
      },
      'repeat': {
        name: 'Repeat',
        value: {},
        is: 'object'
      },
      'email_receipt': {
        name: 'Email receipt',
        value: false,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages[!secondary ? 'add' : 'addSecondary'].emit({
      ...AppService.pages[!secondary ? 'add' : 'addSecondary'].value,

      open: false
    });
  }, [secondary]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      email_receipt,

      ...valueForm
    } = refs.form.current.value;

    const body = {
      type: 'payment',

      ...valueForm,

      apps: ['personal-trainer']
    };

    if (body.customer) body.customer = {
      id: body.customer.id,
      name: body.customer.name
    };

    if (body.service) {
      body.for = body.service.type;

      body.service = {
        id: body.service.id,
        name: body.service.name
      };
    }

    if (is('array', body.discounts)) body.discounts = body.discounts.map((item: any) => ({
      id: item.id,
      name: item.name,
      type: item.type,
      value: item.value_onesy_original
    }));

    if (is('array', body.categories)) body.categories = body.categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = await TransactionService.add(body, { query: { email_receipt } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Payment added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [form, onConfirm, onClose]);

  const currency = signedIn?.organization?.settings?.currency || 'EUR';

  const modal: any = {
    write: (
      <Inputs>
        <Input
          name='Payment'

          description='Payment amount'
        >
          <NumericTextField
            placeholder='1000'

            value={form.values['payment.value'].value}

            error={form.values['payment.value'].error}

            helperText={form.values['payment.value'].error}

            onChange={(valueNew: any) => form.onChange('payment.value', +valueNew)}

            min={0}

            sufix={currency}

            fullWidth
          />
        </Input>

        <Input
          name='Payment date'
        >
          <DatePicker
            value={form.values['made_at'].value ? new OnesyDate(form.values['made_at'].value) : null}

            error={form.values['made_at'].error}

            helperText={form.values['made_at'].error}

            onChange={(valueNew: any) => form.onChange('made_at', valueNew?.milliseconds || null)}

            fullWidth
          />
        </Input>

        <Input
          name='Payment using'
        >
          <Select
            options={optionsPaymentWith}

            value={form.values['payment_with'].value}

            error={form.values['payment_with'].error}

            helperText={form.values['payment_with'].error}

            onChange={(valueNew: any) => form.onChange('payment_with', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='Client'

          description='Who made the payment (optional)'
        >
          <AutoCompleteObjects
            name='Client'

            valueInput={form.value.customer?.name}

            value={form.values.customer.value}

            onChange={(valueNew: any) => form.onChange('customer', valueNew)}

            service={CustomerService}

            query={{
              apps: ['personal-trainer']
            }}

            fullWidth

            disabled={!!customer}
          />
        </Input>

        <Input
          name='Service'

          description='Payment made for what service (optional)'
        >
          <AutoCompleteObjects
            name='Service'

            value={form.values.service.value}

            query={{
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('service', valueNew)}

            service={ServiceService}

            fullWidth
          />
        </Input>

        <Input
          name='Discounts'

          description='Discounts to be applied to this payment (optional)'
        >
          <AutoCompleteObjects
            name='Discount'

            value={form.values.discounts.value}

            query={{
              remaining: { $gt: 0 },
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('discounts', valueNew)}

            service={DiscountService}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Payment categories'
        >
          <AutoCompleteObjects
            name='Category'

            value={form.values.categories.value}

            onChange={(valueNew: any) => form.onChange('categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Repeat
          form={form}

          description='Auto repeat the payment'
        />

        <Input
          startName={(
            <Switch
              value={form.values['email_receipt'].value}

              onChange={(valueNew: any) => form.onChange('email_receipt', valueNew)}
            />
          )}

          name='Email receipt'
        />
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...other}

      {...modal}

      add

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
