import React from 'react';

import { is, setObjectValue } from '@onesy/utils';
import { Line, Medias, Type, useSnackbars } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';

import { AudioRecorder, Button, Info, Input, TextField, useLibrary } from 'ui';
import { audioFix, getDate, getErrorMessage, mediasToValue, mediaToValue } from 'utils';
import { MediaService } from 'services';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    type = 'image',

    form,

    object,

    onUpdate,

    ...other
  } = props;

  const media = useLibrary();
  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);

  const update = React.useCallback((property: string, valueNew: any, propertyNested: any, other?: any) => {
    if (form) form.onChange(property, valueNew, propertyNested, other);
    else if (object !== undefined && onUpdate) {
      const price = object || {};

      setObjectValue(price, propertyNested, valueNew);

      onUpdate('price', price);
    }
  }, [form, object, onUpdate]);

  const value = (object !== undefined ? object : form?.value?.[type]) || {};

  const onOpenMedia = React.useCallback(() => {
    media.open({
      search: {
        mime: [type]
      },

      multiple: false,

      selected: [value],

      onConfirm: (valueNew: any) => {
        let response = is('array', valueNew) ? valueNew.map((item: any) => mediaToValue(item)) : mediaToValue(valueNew);

        response = is('array', response) ? response[0] : response;

        if (form) form.onChange(type, response);
        else onUpdate(type, response);
      }
    });
  }, [value, media, type, form, onUpdate]);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const {
      blob: media,
      duration
    } = await audioFix(value);

    console.log('onAudioRecorderConfirm audioFix', media, duration);

    if (!media) {
      console.log('Media make error', media, duration);

      snackbars.add({
        primary: 'Voice record issue. Your device might not support voice recording properly',
        color: 'error'
      });

      setLoading(false);

      return;
    }

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${getDate(undefined, 'entire')}`;

    // meta
    const meta: any = {};

    if (duration) meta.duration = duration;

    const result = await MediaService.add({
      name,

      meta,

      app: 'chat',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      const value = mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: OnesyDate.milliseconds
      });

      if (form) form.onChange(type, value);
      else onUpdate(type, value);
    }

    setLoading(false);
  }, [form, onUpdate]);

  return (
    <Input
      name='Media'

      description={type === 'video' && 'You can upload your own or provide a URL (Youtube, Instagram, Tiktok, etc.)'}

      endName={type === 'video' && (
        <Info
          name='How to'

          title='URL format'
        >
          <Line
            gap={1}

            fullWidth
          >
            <Type
              version='b2'
            >
              <b>Youtube</b>: https://youtu.be/...
            </Type>

            <Type
              version='b2'
            >
              <b>Instagram</b>: https://www.instagram.com/reels/...
            </Type>

            <Type
              version='b2'
            >
              <b>Tiktok</b>: https://www.tiktok.com/@.../video/...
            </Type>

            <Type
              version='b2'
            >
              <b>Vimeo</b>: https://vimeo.com/...
            </Type>
          </Line>
        </Info>
      )}

      {...other}
    >
      {(value?.id || value?.urlEmbed) && (
        <Medias
          values={{
            value: value
          }}

          size='regular'

          noName
        />
      )}

      {type === 'video' && (
        <TextField
          name='External URL'

          value={value?.urlEmbed}

          onChange={(valueNew: any) => update(type, valueNew, 'urlEmbed')}

          type='url'

          size='small'

          fullWidth
        />
      )}

      <Line
        gap={1}

        direction='row'

        align='center'

        fullWidth
      >
        <Button
          version='outlined'

          color='inherit'

          size='regular'

          onClick={onOpenMedia}
        >
          Choose {type}
        </Button>

        {type === 'audio' && (
          <AudioRecorder
            onConfirm={onAudioRecorderConfirm}

            size='regular'

            disabled={loading}
          />
        )}
      </Line>
    </Input>
  );
});

export default Element;
