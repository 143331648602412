import React from 'react';

import { is, stringToColor } from '@onesy/utils';
import { IconButton, ListItem, Tooltip, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { User } from '@onesy/api';

import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, Avatar, List, ModalForm, SelectRoles, TextField } from 'ui';
import { AppService, OrganizationService, UserGroupService } from 'services';
import { getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'users': {
        name: 'Users',
        value: object?.users,
        is: 'array',
        of: 'object'
      },
      'roles': {
        name: 'Roles',
        value: object?.roles,
        is: 'array',
        of: 'string',
        min: 1
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'active': {
        name: 'Active',
        value: object?.active,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (is('array', body.users)) body.users = body.users.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await UserGroupService.add(body) : await UserGroupService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `User group ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddUser = React.useCallback((user: User) => {
    const exists = form.values.users.value?.find((item: any) => item.id === user.id);

    if (user && !exists) {
      const valueNew = [...(form.values.users.value || [])];

      valueNew.push(user);

      form.onChange('users', valueNew);
    }
  }, [form]);

  const onRemoveUser = React.useCallback((index: number) => {
    const valueNew = [...(form.values.users.value || [])];

    valueNew.splice(index, 1);

    form.onChange('users', valueNew);
  }, [form]);

  const modal: any = {
    write: <>
      <TextField
        name='Name'

        valueDefault={form.values['name'].value}

        error={form.values['name'].error}

        helperText={form.values['name'].error}

        onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

        fullWidth
      />

      <AutoCompleteObjects
        name='Select employees'

        value={form.values.users.value}

        onChange={onAddUser}

        service={OrganizationService}

        method='queryUsersPost'

        clearInputOnSelect

        fullWidth
      />

      {!!form.values.users.value?.length && (
        <List
          paddingVertical='none'
        >
          {form.values.users.value?.map((item: any, index: number) => (
            <ListItem
              startAlign='center'

              start={(
                <Avatar
                  tonal

                  color={stringToColor(item.name)}

                  size='small'
                >
                  {item?.name?.slice(0, 1)}
                </Avatar>
              )}

              primary={(
                <Type
                  version='b1'
                >
                  {item.name}
                </Type>
              )}

              end={(
                <Tooltip
                  name='Remove'

                  color='inverted'
                >
                  <IconButton
                    onClick={() => onRemoveUser(index)}
                  >
                    <IconMaterialRemove
                      size='large'
                    />
                  </IconButton>
                </Tooltip>
              )}

              size='small'
            />
          ))}
        </List>
      )}

      <SelectRoles
        value={form.values.roles?.value}

        onChange={(valueNew: boolean) => form.onChange('roles', valueNew)}

        error={!!form.values.roles.error}

        helperText={form.values.roles.error}

        fullWidth
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
