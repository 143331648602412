import React from 'react';

import { is, textToInnerHTML } from '@onesy/utils';
import { Label, Switch, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { BookingRequest } from '@onesy/api';

import { Input, Inputs, ModalForm } from 'ui';
import { AppService, BookingRequestService } from 'services';
import { getDate, getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'resolved': {
        name: 'Resolved',
        value: object?.resolved
      }
    },

    valueDefault: {
      ...object
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    } as BookingRequest;

    const result = await BookingRequestService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking requested updated`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const inputPropsRead: any = {
    gap: 1
  };

  const typeProps: any = {
    version: 't2',
    weight: 300
  };

  const modal: any = {
    read: (
      <Inputs
        size='small'
      >
        <Input
          name='Resolved'

          {...inputPropsRead}
        >
          <Type
            {...typeProps}
          >
            {object.resolved ? 'Yes' : 'No'}
          </Type>
        </Input>

        {object.service && (
          <Input
            name='Service'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {textToInnerHTML(object.service.name)}
            </Type>
          </Input>
        )}

        {object.booking && (
          <Input
            name='Booking'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {textToInnerHTML(object.booking.name)}
            </Type>
          </Input>
        )}

        {object.booked_at && (
          <Input
            name='Booking at'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {getDate(object.booked_at, 'entire')}
            </Type>
          </Input>
        )}

        {object.name && (
          <Input
            name='Name'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {textToInnerHTML(object.name)}
            </Type>
          </Input>
        )}

        {object.email && (
          <Input
            name='Email'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {textToInnerHTML(object.email)}
            </Type>
          </Input>
        )}

        {object.mobile && (
          <Input
            name='Mobile'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}
            >
              {textToInnerHTML(object.mobile)}
            </Type>
          </Input>
        )}

        {object.description && (
          <Input
            name='Note'

            {...inputPropsRead}
          >
            <Type
              {...typeProps}

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          </Input>
        )}
      </Inputs>
    ),

    write: (
      <Inputs>
        <Input
          name='Resolved'

          description='If you read and decided what to do with this booking request'
        >
          <Label
            value={form.value.resolved}

            onChange={(valueNew: any) => form.onChange('resolved', valueNew)}
          >
            <Switch />

            Resolved
          </Label>
        </Input>
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      name='Booking request'

      object={object}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      {...modal}
    />
  );
});

export default Element;
