import React from 'react';

import { capitalize, is, textToInnerHTML } from '@onesy/utils';
import { Line, Link, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { style } from '@onesy/style-react';
import { IMedia } from '@onesy/api';

import IconMaterialPerson from '@onesy/icons-material-rounded-react/IconMaterialPersonFilledW100';
import IconMaterialCorporateFare from '@onesy/icons-material-rounded-react/IconMaterialCorporateFareW100';
import IconMaterialLocationOn from '@onesy/icons-material-rounded-react/IconMaterialLocationOnW100';
import IconMaterialLink from '@onesy/icons-material-rounded-react/IconMaterialLinkW100';
import IconMaterialCalendarToday from '@onesy/icons-material-rounded-react/IconMaterialCalendarTodayW100';
import IconMaterialMail from '@onesy/icons-material-rounded-react/IconMaterialMailW100';
import IconMaterialPhoneEnabled from '@onesy/icons-material-rounded-react/IconMaterialPhoneEnabledW100';

import { Avatar, Input, Inputs, ModalForm, PropertiesContact, SmartTextField, useLibrary } from 'ui';
import { AppService, AuthService, ContactService } from 'services';
import { getDate, getErrorMessage, mediaToValue } from 'utils';
import { ISignedIn } from 'types';

export const versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'];

const toName = (value_: any, toCapitalize = true) => {
  let valueNew = value_;

  if (value_ === 'urls') valueNew = 'links';

  if (value_ === 'custom') valueNew = 'more';

  return toCapitalize ? capitalize(valueNew) : valueNew;
};

export const getContactProperty = (item: any) => {
  let Element = <Type version='b1'>{item.value}</Type>

  const propsLink = {
    version: 'b1',
    target: 'blank'
  };

  let Icon: any;

  if (item.version === 'email') {
    Element = (
      <Link
        href={`mailto:${item.value}`}

        {...propsLink}
      >
        {item.value}
      </Link>
    );

    Icon = IconMaterialMail;
  }

  if (['mobile', 'tel', 'fax'].includes(item.version)) {
    Element = (
      <Link
        href={`tel:${item.value}`}

        {...propsLink}
      >
        {item.value}
      </Link>
    );

    Icon = IconMaterialPhoneEnabled;
  }

  if (item.version === 'url') {
    Element = (
      <Link
        href={item.value}

        {...propsLink}
      >
        {item.value}
      </Link>
    );

    Icon = IconMaterialLink;
  }

  if (['birthday', 'date'].includes(item.version)) {
    Element = (
      <Type
        version='b1'
      >
        {getDate(item.value)}
      </Type>
    );

    Icon = IconMaterialCalendarToday;
  }

  if (['address'].includes(item.version)) {
    Icon = IconMaterialLocationOn;
  }

  if (['company'].includes(item.version)) {
    Icon = IconMaterialCorporateFare;
  }

  if (['title'].includes(item.version)) {
    Icon = IconMaterialPerson;
  }

  return (
    <Line
      key={item.id}

      gap={1}

      direction='row'

      align='center'
    >
      {Icon && <Icon size='regular' />}

      {React.cloneElement(Element)}
    </Line>
  );
};

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    width: 114,
    height: 114,
    borderRadius: '50%',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&.onesy-Button-root': {
      cursor: 'pointer',
      userSelect: 'none',

      '& .onesy-Type-root': {
        fontSize: '2.25rem !important'
      }
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  ...theme.classes(theme)
}), { name: 'onesy-Contact' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();
  const library = useLibrary();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name || 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      properties: {
        name: 'Properties',
        is: 'object',
        value: object?.properties || {}
      },
      image: {
        name: 'Image',
        is: 'object',
        value: object?.image
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    // init form
    initForm();
  }, [object]);

  const initForm = React.useCallback(() => {
    form.onChange([
      ['seo.name', object?.seo?.name],
      ['seo.description', object?.seo?.description],
    ]);
  }, [object, form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['contact']
    };

    const result = !object?.id ? await ContactService.add(body) : await ContactService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Contact ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, onConfirm, onClose]);

  const onImageProfileConfirm = React.useCallback((value: IMedia[]) => {
    const image = Array.isArray(value) ? value[0] : value;

    if (image) {
      form.onChange('image', mediaToValue(image));
    }
  }, [form]);

  const onOpenMediaImageProfile = React.useCallback(() => {
    library.open({
      search: {
        mime: ['image']
      },
      selected: [refs.form.current.values.image?.value].filter(Boolean),
      onConfirm: onImageProfileConfirm
    });
  }, [library, onImageProfileConfirm]);

  const contact = object ? form.value : object;

  let initials = '';

  if (signedIn) {
    const [firstName, lastName] = (form.values.name.value || '')?.split(' ');

    initials += firstName.charAt(0);

    initials += ((lastName ? lastName.charAt(0) : firstName.charAt(1)) || '');
  }

  initials = initials?.toLowerCase();

  const modal: any = {
    read: <>
      <Line
        fullWidth
      >
        <Line
          gap={1.5}

          align='center'

          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <Avatar
            size='large'

            media={form.value?.image}

            className={classes.avatar}
          >
            {initials}
          </Avatar>

          <Type
            version='h2'

            align='center'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(form.value?.name)
            }}
          />
        </Line>

        {!!Object.keys(contact?.properties || {})?.length && (
          <Inputs
            name='Details'
          >
            {versions.filter(version => !!contact?.properties[version]?.length).map((version: string) => (
              <Input
                key={version}

                name={toName(version)}
              >
                <Line
                  gap={1}

                  fullWidth
                >
                  {contact.properties[version]?.map((item: any) => getContactProperty(item))}
                </Line>
              </Input>
            ))}
          </Inputs>
        )}
      </Line>
    </>,

    write: <>
      <Inputs
        fullWidth
      >
        <Line
          gap={1.5}

          align='center'

          fullWidth

          style={{
            marginBottom: 24
          }}
        >
          <Avatar
            size='large'

            media={form.values.image?.value}

            onClick={onOpenMediaImageProfile}

            className={classes.avatar}
          >
            {initials}
          </Avatar>

          <SmartTextField
            name='Name'

            valueDefault={textToInnerHTML(form.values.name.value || '')}

            error={!!form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: string) => form.onChange('name', textToInnerHTML(valueNew), undefined, { rerenderOnUpdate: false })}

            additional={{
              version: 'h2',
              align: 'center',
              whiteSpace: 'pre-line',
              style: {
                width: 'auto'
              }
            }}
          />
        </Line>

        <PropertiesContact
          form={form}
        />
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      nameRead='Contact'

      object={object}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
