import React from 'react';

import { is, setObjectValue } from '@onesy/utils';
import { Form } from '@onesy/ui-react';

import { Button, Input, TextField } from 'ui';
import AutoCompleteGooglePlaces from './AutoCompleteGooglePlaces';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    object,

    onUpdate,

    fullWidth,

    size,

    Component,

    ...other
  } = props;

  const refs = {
    form: React.useRef(form),
    geocoder: React.useRef<any>(undefined)
  };

  refs.form.current = form;

  const location = object !== undefined ? object : form.values.location.value;

  const init = React.useCallback(async () => {
    if (!(window as any)?.google) return;

    const google = (window as any).google;

    const { Geocoder } = await google.maps.importLibrary('geocoding');

    refs.geocoder.current = new Geocoder();
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  const update = React.useCallback((...args: any) => {
    if (form) form.onChange(...args);
    else if (object !== undefined && onUpdate) {
      const items = is('array', args[0]) ? args[0] : [args];

      items.forEach(item => {
        setObjectValue(object, item[2], item[1]);
      });

      onUpdate('location', object);
    }
  }, [object, onUpdate, form]);

  const onChangeAddressInput = React.useCallback(async (valueNew: any) => {
    const address = valueNew?.value || valueNew || '';

    update([
      ['location', address, 'name']
    ]);
  }, []);

  const onChangeAddress = React.useCallback(async (valueNew: any) => {
    const address = valueNew?.value || valueNew || '';

    const result = address ? await refs.geocoder.current?.geocode({ address }) : null;

    const locationGoogle = result?.results?.[0]?.geometry?.location;

    const coordinates = [locationGoogle?.lat(), locationGoogle?.lng()].filter(Boolean);

    const locationNew = {
      ...refs.form.current.values.location.value,

      name: address,
      type: 'Point',
      coordinates
    };

    update([
      ['location', locationNew],
      ['coordinates', coordinates?.join(', ')]
    ]);
  }, []);

  const onOpenMap = React.useCallback(() => {
    const coordinates = refs.form.current.values.coordinates.value;

    window.open(`https://maps.google.com/?q=${coordinates.replace(' ', '')}`);
  }, []);

  const hasCoordinates = form && form.values.coordinates.value?.split(',')?.length === 2;

  const Root = Component || Form;

  return (
    <Root
      gap={2}

      wrapper

      fullWidth

      {...other}
    >
      <Input
        name='Country'

        size={size}
      >
        <TextField
          placeholder='Country'

          valueDefault={location?.country?.name}

          onChange={(valueNew: any) => update('location', valueNew, 'country.name')}

          fullWidth={fullWidth}
        />
      </Input>

      <Input
        name='City'

        size={size}
      >
        <TextField
          placeholder='City'

          valueDefault={location?.city}

          onChange={(valueNew: any) => update('location', valueNew, 'city')}

          fullWidth={fullWidth}
        />
      </Input>

      <Input
        name='Address'

        size={size}
      >
        <AutoCompleteGooglePlaces
          placeholder='Address'

          valueInputDefault={location?.name}

          onChangeInput={onChangeAddressInput}

          onChange={onChangeAddress}

          fullWidth={fullWidth}
        />
      </Input>

      {form && (
        <Input
          name='Coordinates'

          description='If you want to add precise location. We will try to estimate ourselves based on the address name.'

          size={size}

          endName={(
            <Button
              onClick={onOpenMap}

              color='inherit'

              version='outlined'

              size='small'

              disabled={!hasCoordinates}
            >
              Open in the map
            </Button>
          )}

          fullWidth
        >
          <TextField
            placeholder='Coordinates'

            value={form.values['coordinates'].value || ''}

            error={form.values['coordinates'].error}

            helperText={form.values['coordinates'].error || 'Latitude, Longitude separated with comma ie. 24, 44'}

            onChange={(valueNew: any) => update('coordinates', valueNew)}

            fullWidth={fullWidth}
          />
        </Input>
      )}
    </Root>
  );
});

export default Element;
