import React from 'react';

import { is } from '@onesy/utils';
import { Buttons, Chip, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';

import { AutoCompleteObjects, Input, Inputs, ModalForm, NumericTextField, TextField } from 'ui';
import { AppService, AuthService, CustomerService, DiscountService, ServiceService } from 'services';
import { getErrorMessage, optionsDiscountType } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'type': {
        name: 'Type',
        is: 'string',
        value: 'percentage'
      },
      'value': {
        name: 'Amount',
        is: 'number',
        value: 1,
        required: true
      },
      'limit': {
        name: 'Limit',
        is: 'number',
        value: 1
      },
      'services': {
        name: 'Services',
        is: 'array',
        of: 'object'
      },
      'customers': {
        name: 'Customers',
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (is('array', body.customers)) body.customers = body.customers.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.services)) body.services = body.services.map((item: any) => ({
      id: item.id,
      name: item.name,
      type: item.type
    }));

    const result = !object?.id ? await DiscountService.add(body) : await DiscountService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Discount ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const currency = signedIn?.organization?.settings?.currency || 'EUR';

  const modal: any = {
    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            value={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='Type'

          description='Percentage or fixed discount'
        >
          <Buttons
            value={form.value.type}
          >
            {optionsDiscountType.map((item, index) => (
              <Chip
                key={index}

                value={item.value}

                onClick={() => form.onChange('type', item.value)}
              >
                {item.name}
              </Chip>
            ))}
          </Buttons>
        </Input>

        <Input
          name='Amount'

          description={form.value.type === 'percentage' ? 'From 0% to 100% of the price to discount' : `Fixed amount of ${currency} to discount`}
        >
          <NumericTextField
            placeholder='Amount'

            value={form.values['value'].value}

            error={form.values['value'].error}

            helperText={form.values['value'].error}

            onChange={(valueNew: any) => form.onChange('value', +valueNew)}

            min={0}

            max={form.value.type === 'percentage' ? 100 : undefined}

            sufix={form.value.type === 'percentage' ? '%' : currency}

            fullWidth
          />
        </Input>

        <Input
          name='Limit'

          description='Limit the number of times discount can be used'
        >
          <NumericTextField
            placeholder='Limit'

            value={form.values['limit'].value}

            error={form.values['limit'].error}

            helperText={form.values['limit'].error}

            onChange={(valueNew: any) => form.onChange('limit', +valueNew)}

            min={1}

            fullWidth
          />
        </Input>

        <Input
          name='Clients'

          description='Clients that can use this discount (optional)'
        >
          <AutoCompleteObjects
            name='Clients'

            value={form.values.customers.value}

            onChange={(valueNew: any) => form.onChange('customers', valueNew)}

            service={CustomerService}

            query={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Services'

          description='Services this discount can be used for (optional)'
        >
          <AutoCompleteObjects
            name='Services'

            value={form.values.services.value}

            query={{
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('services', valueNew)}

            service={ServiceService}

            multiple

            fullWidth
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
