import React from 'react';

import { elementToCanvas, textToInnerHTML } from '@onesy/utils';
import { Line, Rating, Type, useSubscription } from '@onesy/ui-react';
import { style, useOnesyTheme } from '@onesy/style-react';

import IconMaterialStyle from '@onesy/icons-material-rounded-react/IconMaterialStyleW100';
import IconMaterialStarRate from '@onesy/icons-material-rounded-react/IconMaterialStarRateW100';

import { Button, Input, Inputs, ModalForm, SelectAColor } from 'ui';
import { AppService, AuthService } from 'services';
import { colorOptions, getDate } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    '& .onesy-Tab-root': {
      maxWidth: 'unset'
    }
  },

  logo: {
    maxWidth: 'clamp(34px, 7vi, 14%)',
    width: 'auto',
    userSelect: 'none'
  },

  image: {
    maxWidth: 'clamp(150px, 100%, 740px)',
    width: '70vi',
    padding: 'clamp(24px, 4vb, 40px) clamp(24px, 2vi, 140px)'
  },

  wrapperText: {
    gap: '1.4vb',
    height: 0
  },

  text: {
    padding: '28px 24px',

    '&.onesy-Type-root': {
      fontSize: 'clamp(16px, 4vi, 4rem)',
      lineHeight: '1.15',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'pre-wrap',
      background: theme.palette.text.default.primary,
    }
  },

  textMeta: {
    '&.onesy-Type-root': {
      fontSize: 'clamp(14px, 3vi, 2.4rem)',
      lineHeight: '1.15'
    }
  },

  textRating: {
    '&.onesy-Type-root': {
      fontSize: 'clamp(14px, 2vi, 1.7rem)',
      lineHeight: '1.15'
    }
  },

  ...theme.classes(theme)
}), { name: 'onesy-Review' });

const ratios = {
  Instagram: '1 / 1.77',
  Tiktok: '1 / 1.77',
  Facebook: '1 / 1.77',
  LinkedIn: '1.91 / 1'
};

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const theme = useOnesyTheme();

  const [tab, setTab] = React.useState('Review');
  const [socialMedia] = React.useState('Instagram');
  const [color, setColor] = React.useState();

  const refs = {
    image: React.useRef<HTMLElement>(undefined)
  };

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onChangeColor = React.useCallback((valueNew: any) => {
    setColor(valueNew);
  }, []);

  const onDownload = React.useCallback(() => {
    const width = refs.image.current.clientWidth;
    const height = refs.image.current.clientHeight;

    const smaller = width !== 740;

    // not sure why, but it works 
    const coefficient = 0.7134;

    elementToCanvas(refs.image.current, {
      response: 'download',

      width: width * 2,
      height: height * 2,

      crop: {
        x: 0,
        y: 0,
        width: !smaller ? width : width / coefficient,
        height: !smaller ? height : height / coefficient
      },

      download: {
        name: `Review from ${object.customer.name}`,
        quality: 1
      }
    } as any);
  }, [object]);

  const organization = signedIn.organization;
  const logo = organization.settings.logos?.logo_light?.url;

  const palette = React.useMemo(() => {
    return theme.methods.color(color ? colorOptions[color].color : theme.palette.color.primary.main);
  }, [color]);

  const inputProps: any = {
    gap: 1
  };

  const modal: any = {
    Review: (
      <Inputs>
        {object.rating !== undefined && (
          <Input
            name='Rating'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              <b>{object.rating}</b> out of 5
            </Type>
          </Input>
        )}

        {object.name && (
          <Input
            name='Title'

            {...inputProps}
          >
            <Type
              version='l1'
            >
              {object.name}
            </Type>
          </Input>
        )}

        {object.description && (
          <Input
            name='Review'

            {...inputProps}
          >
            <Type
              version='l1'

              whiteSpace='pre-wrap'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          </Input>
        )}
      </Inputs>
    ),

    'Social Media Post': (
      <Inputs
        name='Image design'

        description='Style review as an image for social media'

        endHeader={(
          <SelectAColor
            value={color}

            onChange={onChangeColor}
          />
        )}

        MainProps={{
          align: 'center'
        }}

        className={classes.wrapper}
      >
        <Line
          ref={refs.image}

          gap={2}

          align='center'

          justify='flex-start'

          className={classes.image}

          style={{
            aspectRatio: ratios[socialMedia],
            backgroundColor: palette.main,
            color: theme.methods.palette.color.text(palette.main),

            ...(socialMedia === 'LinkedIn' && {
              maxWidth: 'clamp(150px, 100%, 1140px)'
            })
          }}
        >
          {logo ? (
            <img
              src={logo}

              alt='Logo'

              className={classes.logo}
            />
          ) : (
            <Type
              version='t1'

              align='center'

              className={classes.textMeta}
            >
              {textToInnerHTML(organization.name)}
            </Type>
          )}

          <Line
            gap={1}

            align='center'

            justify='center'

            flex

            fullWidth

            className={classes.wrapperText}
          >
            <Type
              version='l1'

              className={classes.text}

              fullWidth

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}

              style={{
                background: theme.palette.light ? palette[99] : palette[10],
                color: theme.palette.light ? palette[30] : palette[80]
              }}
            />

            <Line
              gap='0.24vb'

              align='flex-end'

              fullWidth
            >
              <Type
                version='b1'

                align='right'

                fullWidth

                className={classes.textMeta}
              >
                — {textToInnerHTML(object.customer.name)}
              </Type>

              <Line
                gap='0.7vb'

                direction='row'

                align='center'
              >
                <Type
                  version='b1'

                  align='right'

                  className={classes.textRating}
                >
                  {object.rating} out of 5
                </Type>

                <Rating
                  value={object.rating}

                  size='large'

                  color={theme.methods.palette.color.text(palette.main) as any}

                  colorInactive='inverted'

                  tonal={false}

                  IconProps={{
                    size: 'clamp(14px, 4vi, 4rem)'
                  }}

                  readOnly
                />
              </Line>
            </Line>
          </Line>
        </Line>
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={[
        { name: 'Review', Icon: IconMaterialStarRate },
        { name: 'Social Media Post', Icon: IconMaterialStyle }
      ]}

      onChangeTab={onChangeTab}

      name={`Review from ${object.customer.name} on ${getDate(object.added_at, 'entire')}`}

      add

      footerRightEnd={tab === 'Social Media Post' && (
        <Button
          version='outlined'

          onClick={onDownload}
        >
          Download
        </Button>
      )}

      onClose={onClose}

      maxHeight

      TabProps={{
        style: {
          maxWidth: 'unset'
        }
      }}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;