import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { Line, Switch, Type, useForm, useSnackbars } from '@onesy/ui-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialToday from '@onesy/icons-material-rounded-react/IconMaterialTodayW100';

import { AutoCompleteObjects, Color, DurationForm, Input, Inputs, MediaForm, ModalForm, PayForm, PriceForm, SelectAColor, TextField } from 'ui';
import { AppService, CategoryService, LocationService, OrganizationService, RoomService, ServiceService, UserGroupService } from 'services';
import { getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState('update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'price': {
        name: 'Price',
        value: object?.price || {
          value: 0
        },
        is: 'object'
      },
      'payment_categories': {
        name: 'Payment categories',
        is: 'array',
        of: 'object',
        value: object?.payment_categories
      },
      'categories': {
        name: 'Session categories',
        is: 'array',
        of: 'object',
        value: object?.categories
      },
      'employees': {
        name: 'Trainers',
        is: 'array',
        of: 'object',
        value: object?.employees
      },
      'employee_groups': {
        name: 'Trainer groups',
        is: 'array',
        of: 'object',
        value: object?.employee_groups
      },
      'locations': {
        name: 'Locations',
        is: 'array',
        of: 'object',
        value: object?.locations
      },
      'rooms': {
        name: 'Rooms',
        is: 'array',
        of: 'object',
        value: object?.rooms
      },
      'pay': {
        name: 'Pay',
        value: object?.pay || {},
        is: 'object'
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'cancellation_policy': {
        name: 'Cancellation policy',
        value: object?.cancellation_policy || {
          value: 1,
          unit: 'day'
        },
        is: 'object'
      },
      'online_booking': {
        name: 'Online booking',
        value: object?.online_booking,
        is: 'boolean'
      },
      'image': {
        name: 'Image',
        value: object?.image || {},
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio || {},
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      type: 'session',
      apps: ['personal-trainer']
    };

    if (is('array', body.payment_categories)) body.payment_categories = body.payment_categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.categories)) body.categories = body.categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employees)) body.employees = body.employees.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employee_groups)) body.employee_groups = body.employee_groups.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.locations)) body.locations = body.locations.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.rooms)) body.rooms = body.rooms.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await ServiceService.add(body) : await ServiceService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Service ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const modal: any = {
    Info: <>
      <Inputs>
        <PriceForm
          description='Session price'

          form={form}
        />

        <Input
          name='Trainers'
        >
          <AutoCompleteObjects
            name='Trainers'

            value={form.values.employees.value}

            onChange={(valueNew: any) => form.onChange('employees', valueNew)}

            service={OrganizationService}

            method='queryUsersPost'

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Trainer groups'
        >
          <AutoCompleteObjects
            name='Trainer groups'

            value={form.values.employee_groups.value}

            onChange={(valueNew: any) => form.onChange('employee_groups', valueNew)}

            service={UserGroupService}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Payment categories'
        >
          <AutoCompleteObjects
            name='Payment category'

            value={form.values.payment_categories.value}

            onChange={(valueNew: any) => form.onChange('payment_categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('payment_categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Input
          name='Session categories'
        >
          <AutoCompleteObjects
            name='Session category'

            value={form.values.categories.value}

            onChange={(valueNew: any) => form.onChange('categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'session',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'session',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Input
          name='Locations'
        >
          <AutoCompleteObjects
            name='Locations'

            value={form.values.locations.value}

            onChange={(valueNew: any) => form.onChange('locations', valueNew)}

            onAdd={(valueNew: any) => form.onChange('locations', [...(form.value.locations || []), valueNew])}

            service={LocationService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />
        </Input>

        <Input
          name='Rooms'
        >
          <AutoCompleteObjects
            name='Rooms'

            value={form.values.rooms.value}

            onChange={(valueNew: any) => form.onChange('rooms', valueNew)}

            onAdd={(valueNew: any) => form.onChange('rooms', [...(form.value.rooms || []), valueNew])}

            service={RoomService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />
        </Input>

        <PayForm
          name='Custom payment'

          description={`If you want to set custom payment for this session specifically, for all trainers. It will override each trainer's individual payment details.`}

          form={form}
        />

        <Input
          name='Cancellation policy'

          description='How long until it can be canceled'

          endHeader={(
            <Line
              gap={1}

              direction='row'

              wrap='wrap'

              align='center'

              justify={{
                default: 'flex-start',
                700: 'flex-end'
              }}

              fullWidth={{
                default: false,
                700: true
              }}

              flexNo
            >
              <DurationForm
                name=''

                start={(
                  <Type
                    version='b1'

                    whiteSpace='nowrap'
                  >
                    Until
                  </Type>
                )}

                end={(
                  <Type
                    version='b1'

                    whiteSpace='nowrap'
                  >
                    before
                  </Type>
                )}

                form={form}

                property='cancellation_policy'

                fullWidth={false}
              />
            </Line>
          )}
        />

        <Input
          startName={(
            <Switch
              value={form.values['online_booking'].value}

              onChange={(valueNew: any) => form.onChange('online_booking', valueNew)}
            />
          )}

          name='Online booking'

          description='Check if you want this session to show on the website as available for users to choose for booking online'
        />
      </Inputs>
    </>,

    About: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={3}

            maxRows={4}

            multiline

            fullWidth
          />
        </Input>

        <MediaForm
          type='image'

          name='Image'

          form={form}
        />

        <MediaForm
          type='audio'

          name='Audio'

          form={form}
        />

        <MediaForm
          type='video'

          name='Video'

          form={form}
        />
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name={object ? object?.name : 'New session'}

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialToday },
        { name: 'About', Icon: IconMaterialInfo }
      ]}

      onChangeTab={onChangeTab}

      startHeaderRight={mode === 'update' ? <>
        <DurationForm
          form={form}

          style={{
            marginRight: 8
          }}
        />

        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </> : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
