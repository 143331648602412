import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { Line, useForm, useSnackbars } from '@onesy/ui-react';
import { style } from '@onesy/style-react';
import { IMedia } from '@onesy/api';

import { Avatar, Input, Inputs, ModalForm, PayForm, SelectRoles, SmartTextField, TextField, useLibrary } from 'ui';
import { AppService, UserService } from 'services';
import { getErrorMessage, mediaToValue } from 'utils';

const useStyle = style(theme => ({
  root: {

  },

  avatar: {
    width: 114,
    height: 114,
    borderRadius: '50%',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&.onesy-Button-root': {
      cursor: 'pointer',
      userSelect: 'none',

      '& .onesy-Type-root': {
        fontSize: '2.25rem !important'
      }
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  ...theme.classes(theme)
}), { name: 'onesy-UserAdd' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const library = useLibrary();

  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'email': {
        name: 'Email',
        isValid: 'email',
        required: true
      },
      'mobile': {
        name: 'Mobile',
        is: 'string'
      },
      'pay': {
        name: 'Pay',
        value: {
          value: 0,
          unit: 'booking'
        },
        is: 'object'
      },
      'settings.images.profile': {
        name: 'Image',
        is: 'object'
      },
      'note': {
        name: 'Note',
        is: 'string'
      },

      roles: {
        name: 'Roles',
        is: 'array',
        of: 'string',
        min: 1
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    if (is('function', onClose_)) onClose_();

    // clear 
    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const onAddRoles = React.useCallback((valueNew: any) => {
    refs.form.current.onChange('roles', valueNew);
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    const result = await UserService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Employee invited!`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [onConfirm, onClose]);

  const onImageProfileConfirm = React.useCallback((value: IMedia[]) => {
    const image = Array.isArray(value) ? value[0] : value;

    if (image) {
      form.onChange('settings.images.profile', mediaToValue(image));
    }
  }, [form]);

  const onOpenMediaImageProfile = React.useCallback(() => {
    library.open({
      search: {
        mime: ['image']
      },
      selected: [refs.form.current.values['settings.images.profile']?.value].filter(Boolean),
      onConfirm: onImageProfileConfirm
    });
  }, [library, onImageProfileConfirm]);

  const modal: any = {
    write: <>
      <Inputs>
        <Line
          gap={2}

          align='center'

          fullWidth
        >
          <Avatar
            size='large'

            media={form.values['settings.images.profile']?.value}

            onClick={onOpenMediaImageProfile}

            className={classes.avatar}
          >
            U
          </Avatar>

          <SmartTextField
            placeholder='Name'

            onChange={(valueNew: string) => form.onChange('name', textToInnerHTML(valueNew), undefined, { rerenderOnUpdate: false })}

            error={!!form.values['name'].error}

            helperText={form.values['name'].error}

            additional={{
              version: 'h2',
              align: 'center',
              whiteSpace: 'pre-line',
              style: {
                width: 'auto'
              }
            }}
          />
        </Line>

        <Input
          name='Email'
        >
          <TextField
            placeholder='Email'

            value={form.values.email.value}

            onChange={(valueNew: any) => form.onChange('email', valueNew)}

            error={!!form.values['email'].error}

            helperText={form.values['email'].error}

            type='email'

            fullWidth
          />
        </Input>

        <Input
          name='Mobile'
        >
          <TextField
            placeholder='Mobile'

            value={form.values.mobile.value}

            onChange={(valueNew: any) => form.onChange('mobile', valueNew)}

            error={!!form.values['mobile'].error}

            helperText={form.values['mobile'].error}

            type='tel'

            fullWidth
          />
        </Input>

        <PayForm
          form={form}
        />

        <Input
          name='Roles'

          description='When employees accept their invite, they will be automatically assigned these roles'

          fullWidth
        >
          <SelectRoles
            value={refs.form.current.values.roles.value}

            onChange={onAddRoles}

            error={!!form.values['roles'].error}

            helperText={form.values['roles'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Note'
        >
          <TextField
            placeholder='Note'

            onChange={(valueNew: any) => form.onChange('note', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            error={!!form.values['note'].error}

            helperText={form.values['note'].error}

            minRows={3}

            maxRows={4}

            multiline

            fullWidth
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Add employee'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
