import React from 'react';

import { arrayMoveItem, cleanValue, copy, getID, innerHTMLToText, is, isValid, merge, textToInnerHTML } from '@onesy/utils';
import { Chip, IconButton, Image, Label, Line, Rating, Switch, Tooltip, Type, useConfirm, useForm, useSnackbars } from '@onesy/ui-react';
import { OnesyTheme, classNames, style, Theme, useOnesyTheme } from '@onesy/style-react';
import { App, themesMap } from '@onesy/themes';
import { ValidationError } from '@onesy/errors';
import { Website } from '@onesy/api';

import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialCheckCircle from '@onesy/icons-material-rounded-react/IconMaterialCheckCircleW100';
import IconMaterialHideSource from '@onesy/icons-material-rounded-react/IconMaterialHideSourceW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialKeyboardArrowUp from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialWebStories from '@onesy/icons-material-rounded-react/IconMaterialWebStoriesW100';
import IconMaterialViewStream from '@onesy/icons-material-rounded-react/IconMaterialViewStreamW100';
import IconMaterialOpenInNew from '@onesy/icons-material-rounded-react/IconMaterialOpenInNewW100';
import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { Action, Button, Divider, Input, Inputs, ModalForm, NoResults, SelectMedia, SEO, TextField } from 'ui';
import { AppService, PageService, StorageService, WebsiteService } from 'services';
import { getErrorMessage, mediaToValue } from 'utils';
// import DevTheme from './theme';
import config from 'config';

const useStyle = style(theme => ({
  root: {

  },

  section: {
    transition: theme.methods.transitions.make(['opacity', 'border', 'max-width']),

    '& $sectionImage': {
      width: '100%',
      height: 'auto'
    }
  },

  section_regular: {
    maxWidth: 740
  },

  section_open: {
    padding: 24,
    border: `2px solid ${theme.palette.text.default.primary}`,
    borderRadius: 32,
    maxWidth: 1100
  },

  sectionImage: {
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.07)',
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make(['opacity', 'box-shadow']),

    '&:hover': {
      opacity: 0.7,
      boxShadow: 'none'
    }
  },

  sectionImageSmall: {
    maxWidth: 100,
    maxHeight: 40,
    cursor: 'pointer',
    userSelect: 'none'
  },

  sectionHidden: {
    '& $sectionImage': {
      opacity: 0.24
    }
  },

  sectionEditForm: {
    marginTop: 16
  }
}), { name: 'onesy-Websites-Page' })

const Element = React.forwardRef((props: any) => {
  const {
    parent,

    object,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [openSection, setOpenSection] = React.useState<any>();
  const [tab, setTab] = React.useState('Preview');
  const [showHidden, setShowHidden] = React.useState(!!StorageService.get('website-page-show-hidden'));
  const [loading, setLoading] = React.useState<any>(false);

  const website = parent as Website;

  const themeObject = React.useMemo(() => {
    return themesMap[parent.theme.id];
  }, [parent]);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'url': {
        name: 'URL',
        value: object?.url,
        method: value => {
          const valid = !value?.length || isValid('domain-name', value);

          if (!valid) throw new ValidationError(`URL has to be a valid URL name. Only letters, optionally dashes.`);
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'image': {
        name: 'Image',
        value: object?.image
      },

      'home': {
        name: 'Home',
        value: object?.home || false,
        is: 'boolean'
      },
      'active': {
        name: 'Active',
        value: object?.active !== undefined ? object?.active : true,
        is: 'boolean'
      },

      'sections': {
        name: 'Sections',
        value: copy(object?.sections || themeObject.main.sections || [])
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value
    };

    if (body.url === object?.url) delete body.url;

    const result = !object ? await WebsiteService.addPage(parent.id, body) : await PageService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Page ${!object ? 'added' : 'updated'}`
      });

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [parent, object, form, onConfirm, onClose]);


  const onHome = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: 'Making this page a home page'
    });

    if (!confirmed) return;

    setLoading(true);

    const result = await PageService.update(object.id, {
      home: true
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Pade made a home page`
      });

      refs.form.current.onChange('home', true);

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [object, onConfirm]);

  const onVisible = React.useCallback(async () => {
    const active = refs.form.current.values.active.value;

    const confirmed = await confirm.open({
      name: active ? 'Making website private' : 'Publishing the website'
    });

    if (!confirmed) return;

    setLoading(true);

    const result = await PageService.update(object?.id, {
      active: !active
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Page ${active ? 'made private' : 'published'}`
      });

      refs.form.current.onChange('active', !active);

      if (is('function', onConfirm)) onConfirm();
    }

    setLoading(false);
  }, [object, onConfirm]);

  const onUpdateSection = React.useCallback((id: string, property: string, valueNew: boolean) => {
    const sectionsNew = [...(refs.form.current.values.sections.value || [])];

    const index = sectionsNew.findIndex(item => item.id === id);

    refs.form.current.onChange('sections', valueNew, `${index}.${property}`);
  }, []);

  const onMove = React.useCallback((indexPrevious: number, indexNew: number) => {
    const sectionsNew = [...(refs.form.current.values.sections.value || [])];

    arrayMoveItem(sectionsNew, indexPrevious, indexNew);

    refs.form.current.onChange('sections', sectionsNew);
  }, []);

  const onAddItem = React.useCallback((id: string, property: string, valueNew?: any) => {
    const sectionsNew = [...(refs.form.current.values.sections.value || [])];

    const index = sectionsNew.findIndex(item => item.id === id);

    const items = sectionsNew[index][property] || [];

    items.push({
      id: getID(),

      ...valueNew
    });

    refs.form.current.onChange('sections', items, `${index}.${property}`);
  }, []);

  const onRemoveItem = React.useCallback((id: string, property: string, indexItem: number) => {
    const sectionsNew = [...(refs.form.current.values.sections.value || [])];

    const index = sectionsNew.findIndex(item => item.id === id);

    const items = sectionsNew[index][property] || [];

    items.splice(indexItem, 1);

    refs.form.current.onChange('sections', items, `${index}.${property}`);
  }, []);

  const onChangeTab = React.useCallback((valueNew: any) => {
    setOpenSection(null);

    setTab(valueNew);
  }, []);

  const onOpenLink = React.useCallback(() => {
    const websitesURL = new URL(config.value.apps.websites.url);

    window.open(`${websitesURL.protocol}//${website.url}.${websitesURL.host}/${object.url}`, '_blank');
  }, [website, object]);

  const onChangeShowHidden = React.useCallback((valueNew: any) => {
    StorageService.update('website-page-show-hidden', valueNew);

    setShowHidden(valueNew);
  }, []);

  const value = form.value;

  const sections = form.values.sections.value;

  const sectionsVisible = sections.filter(item => showHidden || item.active);

  const isOpenSection = section => openSection?.id === section.id;

  const getURL = item => item?.url || item?.urlSmall || item?.src || item;

  const iconProps: any = {
    size: 'large'
  };

  const textFieldProps: any = {
    minRows: 4,
    maxRows: 4,

    fullWidth: true
  };

  const dividerProps: any = {
    size: 'small'
  };

  const imageProps: any = {
    alt: '',

    style: {
      maxWidth: 140,
      maxHeight: 140
    }
  };

  const getSectionEdit = React.useCallback((section: any, index: number) => {
    let elements: any;

    if (section.id === 'hero') {
      elements = (
        <Inputs>
          <Input
            name='Headline'
          >
            <TextField
              placeholder='Headline'

              valueDefault={section.props.headline || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.headline`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Image 1'

            startName={(
              <SelectMedia
                value={section.props.image1}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image1`)}
              />
            )}
          >
            <Image
              src={getURL(section.props.image1)}

              {...imageProps}
            />
          </Input>

          <Input
            name='Image 2'

            startName={(
              <SelectMedia
                value={section.props.image2}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image2`)}
              />
            )}
          >
            <Image
              src={getURL(section.props.image2)}

              {...imageProps}
            />
          </Input>

          <Input
            name='Action'
          >
            <Action
              item={section.props.action}

              form={form}

              prefix='sections'

              property={`${index}.props.action`}
            />
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'about') {
      elements = (
        <Inputs>
          <Input
            name='Title'
          >
            <TextField
              placeholder='Title'

              valueDefault={section.props.title || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.title`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Description'
          >
            <TextField
              placeholder='Description'

              valueDefault={section.props.description || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.description`, { rerenderOnUpdate: false })}

              multiline

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Image'

            startName={(
              <SelectMedia
                value={section.props.image}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image`)}
              />
            )}
          >
            <Image
              src={getURL(section.props.image)}

              {...imageProps}
            />
          </Input>

          <Input
            name='Action'
          >
            <Action
              item={section.props.action}

              form={form}

              prefix='sections'

              property={`${index}.props.action`}
            />
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'widget') {
      elements = (
        <Inputs>
          <Input
            name='Text'
          >
            <TextField
              placeholder='Text'

              valueDefault={section.props.text || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.text`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'cards') {
      elements = (
        <Inputs
          name='Cards'

          startName={(
            <Tooltip
              name='Add card'
            >
              <IconButton
                onClick={() => onAddItem(section.id, 'items')}
              >
                <IconMaterialAdd {...iconProps} />
              </IconButton>
            </Tooltip>
          )}
        >
          <Input
            name='Headline'
          >
            <TextField
              placeholder='Headline'

              valueDefault={section.props.headline || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.headline`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Main action'
          >
            <Action
              item={section.props.action}

              form={form}

              prefix='sections'

              property={`${index}.props.action`}
            />
          </Input>

          {!!section.props.items?.length && section.props.items?.map((item, indexItem) => (
            <Input
              key={item.id}

              name={`Card ${indexItem + 1}`}

              startName={(
                <Tooltip
                  name={`Remove card ${indexItem + 1}`}
                >
                  <IconButton
                    onClick={() => onRemoveItem(section.id, 'items', indexItem)}
                  >
                    <IconMaterialRemove {...iconProps} />
                  </IconButton>
                </Tooltip>
              )}

              expand
            >
              <Input
                name='Title'
              >
                <TextField
                  placeholder='Title'

                  valueDefault={item.title || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.title`, { rerenderOnUpdate: false })}

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Description'
              >
                <TextField
                  placeholder='Description'

                  valueDefault={item.description || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.description`, { rerenderOnUpdate: false })}

                  multiline

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Image'

                startName={(
                  <SelectMedia
                    value={section.props.image}

                    onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.image`)}
                  />
                )}
              >
                <Image
                  src={getURL(item.image)}

                  {...imageProps}
                />
              </Input>

              <Input
                name='Action'
              >
                <Action
                  item={item.action}

                  form={form}

                  prefix='sections'

                  property={`${index}.props.items.${indexItem}.action`}
                />
              </Input>
            </Input>
          ))}
        </Inputs>
      );
    }

    if (section.id === 'info') {
      elements = (
        <Inputs>
          <Input
            name='Text'
          >
            <TextField
              placeholder='Text'

              valueDefault={section.props.description || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.description`, { rerenderOnUpdate: false })}

              multiline

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Action'
          >
            <Action
              item={section.props.action}

              form={form}

              prefix='sections'

              property={`${index}.props.action`}
            />
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'logos') {
      elements = (
        <Inputs>
          <Input
            name='Images'

            startName={(
              <SelectMedia
                value={section.props.images}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.images`)}

                multiple
              />
            )}
          >
            {!!section.props.images?.length && (
              <Line
                gap={2}

                direction='row'

                wrap='wrap'

                align='center'
              >
                {section.props.images?.map(image => (
                  <Image
                    src={getURL(image)}

                    {...imageProps}
                  />
                ))}
              </Line>
            )}
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'gallery') {
      elements = (
        <Inputs>
          <Input
            name='Image 1'

            startName={(
              <SelectMedia
                value={section.props.images}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image1`)}

                multiple
              />
            )}
          >
            <Image
              src={getURL(section.props.image1)}

              {...imageProps}
            />

            <TextField
              placeholder='Title'

              valueDefault={section.props.title1 || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.title1`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Image 2'

            startName={(
              <SelectMedia
                value={section.props.images}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image2`)}

                multiple
              />
            )}
          >
            <Image
              src={getURL(section.props.image2)}

              {...imageProps}
            />

            <TextField
              placeholder='Title'

              valueDefault={section.props.title2 || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.title2`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Image 3'

            startName={(
              <SelectMedia
                value={section.props.images}

                onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.image3`)}

                multiple
              />
            )}
          >
            <Image
              src={getURL(section.props.image3)}

              {...imageProps}
            />

            <TextField
              placeholder='Title'

              valueDefault={section.props.title3 || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.title3`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>
        </Inputs>
      );
    }

    if (section.id === 'reviews') {
      elements = (
        <Inputs
          name='Reviews'

          startName={(
            <Tooltip
              name='Add review'
            >
              <IconButton
                onClick={() => onAddItem(section.id, 'items')}
              >
                <IconMaterialAdd {...iconProps} />
              </IconButton>
            </Tooltip>
          )}
        >
          {!!section.props.items?.length && section.props.items?.map((item, indexItem) => (
            <Input
              key={item.id}

              name={`Review ${indexItem + 1}`}

              startName={(
                <Tooltip
                  name={`Remove review ${indexItem + 1}`}
                >
                  <IconButton
                    onClick={() => onRemoveItem(section.id, 'items', indexItem)}
                  >
                    <IconMaterialRemove {...iconProps} />
                  </IconButton>
                </Tooltip>
              )}

              expand
            >
              <Input
                name='Rating'
              >
                <Rating
                  valueDefault={item.rating}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.rating`)}
                />
              </Input>

              <Input
                name='Title'
              >
                <TextField
                  placeholder='Title'

                  valueDefault={item.title || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.title`, { rerenderOnUpdate: false })}

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Review'
              >
                <TextField
                  placeholder='Review'

                  valueDefault={item.description || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.description`, { rerenderOnUpdate: false })}

                  multiline

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Image'

                startName={(
                  <SelectMedia
                    value={section.props.image}

                    onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.image`)}
                  />
                )}
              >
                <Image
                  src={getURL(item.image)}

                  {...imageProps}
                />
              </Input>
            </Input>
          ))}
        </Inputs>
      );
    }

    if (section.id === 'list') {
      elements = (
        <Inputs
          name='List items'

          startName={(
            <Tooltip
              name='Add list item'
            >
              <IconButton
                onClick={() => onAddItem(section.id, 'items')}
              >
                <IconMaterialAdd {...iconProps} />
              </IconButton>
            </Tooltip>
          )}
        >
          <Input
            name='Headline'
          >
            <TextField
              placeholder='Headline'

              valueDefault={section.props.headline || ''}

              onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.headline`, { rerenderOnUpdate: false })}

              {...textFieldProps}
            />
          </Input>

          <Input
            name='Main action'
          >
            <Action
              item={section.props.action}

              form={form}

              prefix='sections'

              property={`${index}.props.action`}
            />
          </Input>

          {!!section.props.items?.length && section.props.items?.map((item, indexItem) => (
            <Input
              key={item.id}

              name={`List item ${indexItem + 1}`}

              startName={(
                <Tooltip
                  name={`Remove list item ${indexItem + 1}`}
                >
                  <IconButton
                    onClick={() => onRemoveItem(section.id, 'items', indexItem)}
                  >
                    <IconMaterialRemove {...iconProps} />
                  </IconButton>
                </Tooltip>
              )}

              expand
            >
              <Input
                name='Title'
              >
                <TextField
                  placeholder='Title'

                  valueDefault={item.title || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.title`, { rerenderOnUpdate: false })}

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Description'
              >
                <TextField
                  placeholder='Description'

                  valueDefault={item.description || ''}

                  onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.description`, { rerenderOnUpdate: false })}

                  multiline

                  {...textFieldProps}
                />
              </Input>

              <Input
                name='Image'

                startName={(
                  <SelectMedia
                    value={item.image}

                    onChange={(valueNew: any) => form.onChange('sections', valueNew, `${index}.props.items.${indexItem}.image`)}
                  />
                )}
              >
                <Image
                  src={getURL(item.image)}

                  {...imageProps}
                />
              </Input>

              <Input
                name='Action'
              >
                <Action
                  item={item.action}

                  form={form}

                  prefix='sections'

                  property={`${index}.props.items.${indexItem}.action`}
                />
              </Input>
            </Input>
          ))}
        </Inputs>
      );
    }

    return (
      <Line
        gap={1}

        fullWidth

        className={classes.sectionEditForm}
      >
        {elements}
      </Line>
    );
  }, []);

  const valueTheme = React.useMemo(() => {
    const palette: any = merge(
      {
        color: {
          onesy: {
            main: '#fafa00'
          }
        }
      },
      website.options.theme.palette
    );

    if (palette.light === 'auto') delete palette.light;

    const fontFamily = themeObject.options.theme.typography.font_family;

    const typography = {
      font_family: fontFamily,

      font_size: {
        html: 17
      },

      values: {
        d1: { fontFamily: fontFamily.primary, fontWeight: 700 },

        d2: { fontFamily: fontFamily.primary, fontWeight: 700 },

        d3: { fontFamily: fontFamily.primary, fontWeight: 700 },

        h1: { fontFamily: fontFamily.primary, fontWeight: 700 },

        h2: { fontFamily: fontFamily.primary, fontWeight: 700 },

        h3: { fontFamily: fontFamily.primary, fontWeight: 700 },

        t1: { fontFamily: fontFamily.primary, fontWeight: 700 },

        t2: { fontFamily: fontFamily.primary, fontWeight: 700 },

        t3: { fontFamily: fontFamily.primary, fontWeight: 700 },

        l1: { fontFamily: fontFamily.secondary, fontWeight: 700 },

        l2: { fontFamily: fontFamily.secondary, fontWeight: 700 },

        l3: { fontFamily: fontFamily.secondary, fontWeight: 700 },

        b1: { fontFamily: fontFamily.secondary },

        b2: { fontFamily: fontFamily.secondary },

        b3: { fontFamily: fontFamily.secondary },

        m1: { fontFamily: fontFamily.tertiary },

        m2: { fontFamily: fontFamily.tertiary },

        m3: { fontFamily: fontFamily.tertiary }
      }
    };

    const value = new OnesyTheme({
      palette,

      typography,

      ...themeObject.onesyTheme
    });

    return value;
  }, [website, themeObject]);

  const otherWebsiteProps = {
    website,

    page: {
      ...object,

      ...form.value
    }
  };

  const ui = {
    'Preview': (
      <Line
        gap={0}

        fullWidth

        className={classes.theme}
      >
        <Theme
          value={valueTheme}
        >
          {/* <DevTheme
            {...otherWebsiteProps}
          /> */}

          <App
            {...otherWebsiteProps}

            test
          />
        </Theme>
      </Line>
    ),

    'Sections': (
      <Inputs
        name='Sections'

        description='Your page is made out of the list of sections, you can manage them here'

        NameProps={{
          justify: 'space-between'
        }}

        maxWidth
      >
        <Line
          gap={6}

          align='center'

          fullWidth
        >
          {sectionsVisible.filter(item => showHidden || item.active).map((section, index) => (
            <Input
              key={section.id}

              name={(
                <Line
                  gap={1}

                  direction='row'

                  align='center'
                >
                  <Type
                    version='h2'

                    color={section.active ? 'inherit' : 'warning'}
                  >
                    {section.name || cleanValue(section.id, { capitalize: true })}
                  </Type>

                  {!section.active && (
                    <Type
                      version='h3'

                      weight={200}
                    >
                      Hidden
                    </Type>
                  )}
                </Line>
              )}

              startName={isOpenSection(section) && (
                <img
                  alt=''

                  src={theme.palette.light ? section.image : section.imageDark}

                  onClick={() => setOpenSection(isOpenSection(section) ? null : section)}

                  draggable={false}

                  className={classes.sectionImageSmall}
                />
              )}

              endName={(
                <Line
                  key={section.id}

                  gap={1}

                  direction='row'

                  align='center'
                >
                  <Button
                    version='outlined'

                    color='inherit'

                    size='large'

                    onClick={() => setOpenSection(isOpenSection(section) ? null : section)}
                  >
                    {isOpenSection(section) ? 'Back' : 'Edit'}
                  </Button>

                  <Tooltip
                    name={section.active ? 'Hide' : 'Show'}
                  >
                    <IconButton
                      onClick={() => onUpdateSection(section.id, 'active', !section.active)}
                    >
                      {section.active ? <IconMaterialHideSource {...iconProps} /> : <IconMaterialCheckCircle {...iconProps} />}
                    </IconButton>
                  </Tooltip>

                  {showHidden && <>
                    <Tooltip
                      name='Move up'
                    >
                      <IconButton
                        onClick={() => onMove(index, index - 1)}

                        disabled={!index}
                      >
                        <IconMaterialKeyboardArrowUp {...iconProps} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      name='Move down'
                    >
                      <IconButton
                        onClick={() => onMove(index, index + 1)}

                        disabled={index === sections.length - 1}
                      >
                        <IconMaterialKeyboardArrowDown {...iconProps} />
                      </IconButton>
                    </Tooltip>
                  </>}
                </Line>
              )}

              NameProps={{
                direction: {
                  default: 'row',
                  700: 'column'
                },

                justify: {
                  default: 'space-between'
                }
              }}

              className={classNames([
                classes.section,
                classes[`section_${isOpenSection(section) ? 'open' : 'regular'}`],
                !section.active && classes.sectionHidden
              ])}
            >
              {isOpenSection(section) ? getSectionEdit(section, index) : <>
                <img
                  alt=''

                  src={theme.palette.light ? section.image : section.imageDark}

                  onClick={() => setOpenSection(isOpenSection(section) ? null : section)}

                  draggable={false}

                  className={classes.sectionImage}
                />
              </>}
            </Input>
          ))}

          {!sectionsVisible?.length && (
            <NoResults>
              <Line
                gap={0.75}

                align='center'

                fullWidth
              >
                <Type
                  version='t1'

                  align='center'
                >
                  All sections are hidden
                </Type>

                <Type
                  version='b1'

                  align='center'
                >
                  Click <b>Show all</b> in the top-right corner and unhide some sections
                </Type>
              </Line>
            </NoResults>
          )}
        </Line>
      </Inputs>
    ),

    'Info': (
      <Inputs
        maxWidth
      >
        <SEO
          name={value.name}

          description={value.description}

          url={value.url}

          image={value.image}
        />

        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            value={form.values['name'].value || ''}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew)}

            {...textFieldProps}
          />
        </Input>

        <Input
          name='URL'

          description={(
            <Type
              version='b2'
            >
              Page url  ie. example.com<b>/our-program</b> <br />
              URL would be <b>our-program</b>. It has to be unique within your website
            </Type>
          )}
        >
          <TextField
            placeholder='about'

            value={form.values['url'].value || ''}

            error={form.values['url'].error}

            helperText={form.values['url'].error}

            onChange={(valueNew: any) => form.onChange('url', valueNew)}

            {...textFieldProps}
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            value={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew))}

            minRows={4}

            maxRows={4}

            multiline

            edit

            {...textFieldProps}
          />
        </Input>

        <Input
          name='Image'

          description='Used as a thumbnail for your page and Google SEO'
        >
          {form.values.image.value && (
            <Image
              src={form.values.image.value.url}

              style={{
                maxHeight: 140
              }}
            />
          )}

          <SelectMedia
            value={form.values.image.value}

            onChange={(valueNew: any) => form.onChange('image', mediaToValue(valueNew))}
          />
        </Input>

        {object && <>
          <Divider
            {...dividerProps}
          />

          {!form.values.home.value && (
            <Input
              name='Home page'

              description='Make this page a home page'

              footer={!object.home && (
                <Button
                  version='outlined'

                  color='inherit'

                  onClick={onHome}
                >
                  Home page
                </Button>
              )}
            />
          )}

          <Input
            name='Visibility'

            description={`Published page is visible to anyone, private pages will be hidden from everyone`}

            footer={(
              <Button
                version='outlined'

                color={form.values.active.value ? 'warning' : 'success'}

                onClick={onVisible}
              >
                {form.values.active.value ? 'Private page' : 'Publish page'}
              </Button>
            )}
          />
        </>}
      </Inputs>
    )
  };

  const tabs = React.useMemo(() => {

    return [
      { name: 'Preview', Icon: IconMaterialWebStories },
      { name: 'Sections', Icon: IconMaterialViewStream },
      { name: 'Info', Icon: IconMaterialInfo }
    ];
  }, []);

  return (
    <ModalForm
      {...props}

      name={(
        <Line
          gap={2}

          direction='row'

          align='center'
        >
          <Type
            version='h3'
          >
            {form.values.name.value || 'Page'}
          </Type>

          {form.values.home.value && (
            <Chip
              size='small'

              disabled
            >
              Home page
            </Chip>
          )}
        </Line>
      )}

      tab={tab}

      onChangeTab={onChangeTab}

      tabs={tabs}

      onClose={onClose}

      onNext={onNext}

      loading={loading}

      fullScreen={tab === 'Preview'}

      footer={!['Analytics', 'Pages'].includes(tab)}

      startHeaderRight={<>
        {tab === 'Sections' && (
          <Label
            value={showHidden}

            onChange={onChangeShowHidden}

            style={{
              marginRight: 8
            }}
          >
            <Switch />

            <Type
              version='b1'
            >
              Show all
            </Type>
          </Label>
        )}

        {parent?.active && object?.active && (
          <Button
            onClick={onOpenLink}

            version='outlined'

            color='inherit'

            size='regular'

            end={(
              <IconMaterialOpenInNew
                size='regular'
              />
            )}
          >
            Open
          </Button>
        )}
      </>}

      add

      maxHeight
    >
      {ui[tab] || null}
    </ModalForm>
  );
});

export default Element;
