import React from 'react';

import { capitalize, is } from '@onesy/utils';
import { Buttons, Chip, Switch, useForm, useSnackbars } from '@onesy/ui-react';

import { AutoCompleteObjects, Input, Inputs, ModalForm } from 'ui';
import { AppService, CustomerService, DiscountService, PurchaseService, ServiceService, TransactionService } from 'services';
import { getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    type = 'package',

    customer,

    secondary,

    onConfirm,

    ...other
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'customer': {
        name: 'Customer',
        value: customer ? {
          id: customer.id,
          name: customer.name
        } : undefined,
        is: 'object'
      },
      'object': {
        name: 'Service',
        is: 'object'
      },
      'discounts': {
        name: 'Discounts',
        is: 'array',
        of: 'object'
      },
      'auto_payment': {
        name: 'Auto payment',
        value: true,
        is: 'boolean'
      },
      'auto_charge': {
        name: 'Auto charge',
        value: false,
        is: 'boolean'
      },
      'without_payment': {
        name: 'Without payment',
        value: false,
        is: 'boolean'
      },
      'email_receipt': {
        name: 'Email receipt',
        value: false,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages[!secondary ? 'add' : 'addSecondary'].emit({
      ...AppService.pages[!secondary ? 'add' : 'addSecondary'].value,

      open: false
    });
  }, [secondary]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      email_receipt,

      ...valueForm
    } = refs.form.current.value;

    const body = {
      type,

      ...valueForm,

      apps: ['personal-trainer']
    };

    if (body.customer) body.customer = {
      id: body.customer.id,
      name: body.customer.name
    };

    if (body.object) {
      body.object = {
        id: body.object.id,
        name: body.object.name
      };
    }

    if (is('array', body.discounts)) body.discounts = body.discounts.map((item: any) => ({
      id: item.id,
      name: item.name,
      type: item.type,
      value: item.value_onesy_original
    }));

    const result = await PurchaseService.add(body, { query: { email_receipt } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${capitalize(type)} purchased`
      });

      setObject(result.response.response);

      // payments refresh 
      TransactionService.refetch();

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    write: (
      <Inputs>
        <Input
          name='Client'

          description='Who made the payment (optional)'
        >
          <AutoCompleteObjects
            name='Client'

            valueInput={form.value.customer?.name}

            value={form.values.customer.value}

            onChange={(valueNew: any) => form.onChange('customer', valueNew)}

            service={CustomerService}

            query={{
              apps: ['personal-trainer']
            }}

            fullWidth

            disabled={!!customer}
          />
        </Input>

        <Input
          name={capitalize(type)}
        >
          <AutoCompleteObjects
            name={capitalize(type)}

            value={form.values.object.value}

            query={{
              type,
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('object', valueNew)}

            service={ServiceService}

            fullWidth
          />
        </Input>

        <Input
          name='Discounts'

          description='Discounts to be applied for the payment/charge (optional)'
        >
          <AutoCompleteObjects
            name='Discount'

            value={form.values.discounts.value}

            query={{
              remaining: { $gt: 0 },
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('discounts', valueNew)}

            service={DiscountService}

            multiple

            fullWidth
          />
        </Input>

        <Buttons
          value={form.value.auto_payment ? 'auto-payment' : form.value.auto_charge ? 'auto-charge' : 'without-payment'}

          orientation={{
            default: 'horizontal',
            700: 'vertical'
          }}
        >
          <Chip
            value='auto-payment'

            onClick={() => form.onChange([
              ['auto_payment', true],
              ['auto_charge', false],
              ['without_payment', false]
            ])}
          >
            Auto payment
          </Chip>

          <Chip
            value='auto-charge'

            onClick={() => form.onChange([
              ['auto_payment', false],
              ['auto_charge', true],
              ['without_payment', false]
            ])}
          >
            Auto charge
          </Chip>

          <Chip
            value='without-payment'

            onClick={() => form.onChange([
              ['auto_payment', false],
              ['auto_charge', false],
              ['without_payment', true]
            ])}
          >
            Without payment
          </Chip>
        </Buttons>

        <Input
          startName={(
            <Switch
              value={form.values['email_receipt'].value}

              onChange={(valueNew: any) => form.onChange('email_receipt', valueNew)}
            />
          )}

          name='Email receipt'
        />
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...other}

      name={`Purchase new ${type}`}

      object={object}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
