import React from 'react';

import { is } from '@onesy/utils';
import { ListItem, Type } from '@onesy/ui-react';
import { IDatePicker } from '@onesy/ui-react/DatePicker/DatePicker';
import { OnesyDate, endOf, remove, startOf } from '@onesy/date';

import { DateRangePicker, Select } from '.';
import { getDate } from 'utils';

export interface IDate extends IDatePicker {
  preview?: boolean;
}

const Element: React.FC<IDate> = React.forwardRef((props, ref: any) => {
  const {
    name,

    valueDefault,

    value: valueProps,

    onChange: onChangeProps,

    preview = true,

    className,

    ...other
  } = props;

  const [type, setType] = React.useState('default');
  const [value, setValue] = React.useState<any>(valueDefault !== undefined ? valueDefault : valueProps !== undefined ? valueProps : []);

  React.useEffect(() => {
    if (valueProps !== value) setValue(valueProps);

    if (!(valueProps as any)?.length) setType('default');
  }, [valueProps]);

  const onChange = React.useCallback((valueNew_: any) => {
    const valueNew = valueNew_?.map((item: OnesyDate) => item.milliseconds);

    setValue(valueNew);

    if (is('function', onChangeProps)) onChangeProps!(valueNew);
  }, [onChangeProps]);

  const options = React.useMemo(() => {
    return [
      {
        name: 'Today',
        onClick: () => {
          setType('Today');
          onChange([startOf(new OnesyDate(), 'day'), endOf(new OnesyDate(), 'day')]);
        }
      },
      {
        name: 'This week',
        onClick: () => {
          setType('This week');
          onChange([startOf(new OnesyDate(), 'week'), endOf(new OnesyDate(), 'week')]);
        }
      },
      {
        name: 'This month',
        onClick: () => {
          setType('This month');
          onChange([startOf(new OnesyDate(), 'month'), endOf(new OnesyDate(), 'month')]);
        }
      },
      {
        name: 'This year',
        onClick: () => {
          setType('This year');
          onChange([startOf(new OnesyDate(), 'year'), endOf(new OnesyDate(), 'year')]);
        }
      },
      {
        name: 'Last 7 days',
        onClick: () => {
          setType('Last 7 days');
          onChange([startOf(remove(7, 'day'), 'day'), endOf(new OnesyDate(), 'day')]);
        }
      },
      {
        name: 'Last 30 days',
        onClick: () => {
          setType('Last 30 days');
          onChange([startOf(remove(30, 'day'), 'day'), endOf(new OnesyDate(), 'day')]);
        }
      },
      {
        name: 'Custom',
        onClick: () => {
          setType('Custom');
          onChange([startOf(remove(30, 'day'), 'day'), endOf(new OnesyDate(), 'day')]);
        }
      }
    ]
  }, []);

  const onChangeSelect = React.useCallback((valueNew: any) => {
    if (valueNew === null) {
      setType('clear');

      onChange([]);
    }
  }, [onChange]);

  let text;

  const values = value?.filter(Boolean);

  if (values?.length) {
    text = `${getDate(values[0])}`;

    if (values[1]) text = `${text} — ${getDate(values[1])}`;

    if (!values[1]) text = `From ${text}`;
  }

  return <>
    <Select
      name={name}

      value={type === 'default' ? 'Date' : type}

      onChange={onChangeSelect}

      noSelectText='Date'

      enabled={!!(value as any)?.length}

      {...other as any}

      clear={!!(value as any)?.length}
    >
      {options.map((item: any) => (
        <ListItem
          key={item.name}

          primary={item.name}

          value={item.name}

          onClick={item.onClick}

          selected={type === item.name}

          button
        />
      ))}
    </Select>

    {type === 'Custom' && (
      <DateRangePicker
        value={value?.map((item: number) => new OnesyDate(item))}

        onChange={onChange}

        WrapperProps={{
          style: {
            flex: '0 0 auto'
          }
        }}
      />
    )}

    {preview && type !== 'Custom' && text && (
      <Type
        version='b1'

        whiteSpace='nowrap'
      >
        {text}
      </Type>
    )}
  </>;
});

export default Element;
