import React from 'react';

import { DateTimeRangePicker } from '@onesy/ui-react'
import { IDateTimeRangePicker } from '@onesy/ui-react/DateTimeRangePicker/DateTimeRangePicker';

const Element: React.FC<IDateTimeRangePicker> = props => {

  return (
    <DateTimeRangePicker
      size='small'

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
};

export default Element;
