import React from 'react';

import { is } from '@onesy/utils';
import { IconButton, Line, List, ListItem, Menu, Tooltip, Type, useConfirm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { IConfirmOpen } from '@onesy/ui-react/Confirm/Confirm';
import { classNames, style } from '@onesy/style-react';
import { Template } from '@onesy/api';

import IconMaterialBookmark from '@onesy/icons-material-rounded-react/IconMaterialBookmarkW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialDelete from '@onesy/icons-material-rounded-react/IconMaterialDeleteW100';

import { Button, FormTemplate } from 'ui';
import { AppService, TemplateService } from 'services';
import { getErrorMessage } from 'utils';
import { IQuerySubscription } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  add: {
    '&.onesy-Button-root': {
      marginTop: '8px !important'
    }
  },

  addEmpty: {
    '&.onesy-Button-root': {
      marginTop: 0
    }
  },

  list: {
    '&.onesy-List-root': {
      minWidth: 154,
      maxWidth: '170px !important',
      maxHeight: 240,
      overflowY: 'auto',

      '& .onesy-ListItem-middle': {
        margin: 0
      }
    }
  }
}), { name: 'onesy-Filters' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onChange: onChange_,

    model,

    value,

    // className,

    // ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const queryItems = useSubscription<IQuerySubscription>(TemplateService.queryObjects);

  const init = React.useCallback(async () => {
    const result = await TemplateService.queryObjects.value!.query({
      query: {
        query: {
          model
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, [model]);

  React.useEffect(() => {
    // init
    init();
  }, [model]);

  const onRemove = React.useCallback(async (template: Template, event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const confirmed = await (confirm.open as (value: IConfirmOpen) => Promise<any>)({
      name: 'Remove a template',
      description: 'Are you sure you want to remove this template?'
    });

    if (!confirmed) return;

    const result = await TemplateService.remove(template.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else TemplateService.refetch();
  }, []);

  const onChange = React.useCallback((valueNew: Template) => {
    if (is('function', onChange_)) onChange_(valueNew);
  }, [onChange_]);

  const onAddTemplate = React.useCallback(() => {
    AppService.pages.addTertiary.emit({
      open: true,

      minWidth: 'rg',

      children: (
        <FormTemplate
          onConfirm={() => TemplateService.refetch()}

          model={model}

          value={value}
        />
      )
    });
  }, [AppService.pages.addTertiary, value, model]);

  const ListItemProps: any = {
    menuCloseOnClick: true,

    PrimaryProps: {
      version: 'b3'
    }
  };

  const menuList = (queryItems?.response || []).map((item: any, index: number) => (
    <ListItem
      key={index}

      primary={(
        <Type
          version='b2'
        >
          {item?.name}
        </Type>
      )}

      onClick={() => onChange(item)}

      end={(
        <Tooltip
          name='Remove'

          color='default'
        >
          <IconButton
            onClick={(event: any) => onRemove(item, event)}

            size='small'
          >
            <IconMaterialDelete size='regular' />
          </IconButton>
        </Tooltip>
      )}

      size='small'

      button

      {...ListItemProps}
    />
  ));

  return <>
    <Menu
      name={(methodItem: any) => (
        <Line
          gap={0}

          align='center'

          fullWidth
        >
          {!!menuList.length && (
            <List
              color='default'

              menu

              className={classes.list}

              size='small'
            >
              {menuList.map((item: any, index: number) => (
                React.cloneElement(item, methodItem(item, index))
              ))}
            </List>
          )}

          <Button
            size='small'

            color='default'

            onClick={onAddTemplate}

            className={classNames([
              classes.add,
              classes.addEmpty
            ])}
          >
            <IconMaterialAdd
              size='regular'
            />

            <Type
              version='b2'
            >
              Add new template
            </Type>
          </Button>
        </Line>
      )}

      ListProps={{
        className: classes.list,
        size: 'small'
      }}
    >
      <span>
        <Tooltip
          name='Saved templates'
        >
          <IconButton>
            <IconMaterialBookmark size='large' />
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  </>;
});

export default Element;