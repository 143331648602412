import React from 'react';

import { innerHTMLToText, is, isValid, setObjectValue, textToInnerHTML } from '@onesy/utils';
import { Image, Line, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { useOnesyTheme } from '@onesy/style-react';
import { themesList, themesMap } from '@onesy/themes';
import { ValidationError } from '@onesy/errors';
import { Website } from '@onesy/api';

import { Button, Input, Inputs, ModalForm, Palette, PreviewTheme, SelectMedia, SEO, TextField, Themes } from 'ui';
import { AppService, WebsiteService } from 'services';
import { getErrorMessage, getMediaUrl, mediaToValue } from 'utils';

const defaultTheme = themesList[0];

const colorDefaultTheme = defaultTheme.options.theme.palette.color;

const Element = React.forwardRef((props: any) => {
  const {
    onConfirm
  } = props;

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        min: 1,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'url': {
        name: 'URL',
        method: value => {
          const valid = !value?.length || isValid('domain-name', value);

          if (!valid) throw new ValidationError(`URL has to be a valid URL name. Only letters, optionally dashes.`);
        }
      },
      'description': {
        name: 'Description',
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'image': {
        name: 'Image'
      },

      'theme': {
        name: 'Theme',
        value: { id: defaultTheme.id, name: defaultTheme.name, image: defaultTheme.image },
        is: 'object',
        required: true
      },

      'options.logos.logo_light': {
        name: 'Logo light'
      },
      'options.logos.favicon_light': {
        name: 'Favicon light'
      },

      'options.theme.palette.image': {
        name: 'Theme image'
      },

      'options.theme.palette.color.primary.main': {
        name: 'Theme color primary',
        is: 'string',
        value: colorDefaultTheme.primary.main
      },
      'options.theme.palette.color.secondary.main': {
        name: 'Theme color secondary',
        is: 'string',
        value: colorDefaultTheme.secondary.main
      },
      'options.theme.palette.color.tertiary.main': {
        name: 'Theme color tertiary',
        is: 'string',
        value: colorDefaultTheme.tertiary.main
      },
      'options.theme.palette.color.quaternary.main': {
        name: 'Theme color quaternary',
        is: 'string',
        value: colorDefaultTheme.quaternary.main
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: Website = {
      active: true,

      ...refs.form.current.value
    };

    const themeObject = themesMap[body.theme.id];

    // default 
    // palette 
    if (!body.options?.theme?.palette) {
      setObjectValue(body, 'options.theme.palette', themeObject.options.theme.palette);
    }

    // header 
    if (!body.options.header) {
      setObjectValue(body, 'options.header', themeObject.options.header);
    }

    // footer 
    if (!body.options.footer) {
      setObjectValue(body, 'options.footer', themeObject.options.footer);
    }

    const result = await WebsiteService.add(body);

    // page add 
    const website = result.response.response;

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Website added`
      });

      // page add 
      const resultPage = await WebsiteService.addPage(website.id, {
        name: 'Home page',
        url: '',
        home: true,
        active: true,
        sections: themeObject.main.sections
      } as any);

      if (resultPage.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: `Website page added`
        });
      }

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [form, onConfirm, onClose]);

  const onChangePalette = React.useCallback((valueNew: any) => {
    refs.form.current.onChange([
      ['options.theme.palette.image', mediaToValue(valueNew.image)],
      ['options.theme.palette.color.primary.main', valueNew.primary],
      ['options.theme.palette.color.secondary.main', valueNew.secondary],
      ['options.theme.palette.color.tertiary.main', valueNew.tertiary],
      ['options.theme.palette.color.quaternary.main', valueNew.quaternary]
    ]);
  }, []);

  const onSelectTheme = React.useCallback((valueNew: any) => {
    const themeObjectNew = themesMap[valueNew.id];

    const color = themeObjectNew.options.theme.palette.color;

    refs.form.current.onChange([
      ['options.theme.palette.color.primary.main', color.primary.main],
      ['options.theme.palette.color.secondary.main', color.secondary.main],
      ['options.theme.palette.color.tertiary.main', color.primary.main],
      ['options.theme.palette.color.quaternary.main', color.quaternary.main]
    ]);

    refs.form.current.onChange('theme', valueNew);
  }, []);

  const onNextStep = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setStep(step + 1);
  }, [step]);

  const validSteps = React.useMemo(() => {
    const valid = {
      1: true,
      2: true
    };

    valid['3'] = valid[1] && form.values.theme.value;

    return valid;
  }, [form]);

  const value = form.value;

  const steps = {
    1: (
      <Inputs>
        <SEO
          name={value.name}

          description={value.description}

          url={value.url}

          image={value.image}
        />

        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            value={form.values['name'].value || ''}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='URL'

          description='Free subdomain name of your website, ie. ana.websites.onesy.me. You can add your own domain later'
        >
          <TextField
            placeholder='ana'

            value={form.values['url'].value || ''}

            error={form.values['url'].error}

            helperText={form.values['url'].error}

            onChange={(valueNew: any) => form.onChange('url', valueNew)}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            value={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew))}

            minRows={4}

            multiline

            edit

            fullWidth
          />
        </Input>

        <Input
          name='Image'

          description='Used as a thumbnail for your website and Google SEO'
        >
          {form.values.image.value && (
            <Image
              src={form.values.image.value.url}

              style={{
                maxHeight: 140
              }}
            />
          )}

          <SelectMedia
            value={form.values.image.value}

            onChange={(valueNew: any) => form.onChange('image', mediaToValue(valueNew))}
          />
        </Input>
      </Inputs>
    ),

    2: (
      <Inputs>
        <Line
          gap={2}

          fullWidth
        >
          <Line
            gap={2}

            direction={{
              default: 'row',
              700: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Logo
              </Type>

              <SelectMedia
                value={form.values['options.logos.logo_light'].value}

                onChange={(valueNew: any) => form.onChange('options.logos.logo_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['options.logos.logo_light'].value)}

              example='logo'
            />
          </Line>

          <Line
            gap={2}

            direction={{
              default: 'row',
              700: 'column'
            }}

            fullWidth
          >
            <Line
              gap={1.5}
            >
              <Type
                version='t2'
              >
                Favicon
              </Type>

              <SelectMedia
                value={form.values['options.logos.favicon_light'].value}

                onChange={(valueNew: any) => form.onChange('options.logos.favicon_light', mediaToValue(valueNew))}
              />
            </Line>

            <PreviewTheme
              version={`${theme.palette.light ? 'light' : 'dark'}-preview`}

              url={getMediaUrl(form.values['options.logos.favicon_light'].value)}

              example='favicon'
            />
          </Line>
        </Line>

        <Input
          name='Colors'
        >
          <Palette
            value={{
              image: form.values[`options.theme.palette.image`].value,
              primary: form.values[`options.theme.palette.color.primary.main`].value || '#ffffff',
              secondary: form.values[`options.theme.palette.color.secondary.main`].value || '#ffffff',
              tertiary: form.values[`options.theme.palette.color.tertiary.main`].value || '#ffffff',
              quaternary: form.values[`options.theme.palette.color.quaternary.main`].value || '#ffffff'
            }}

            onChange={onChangePalette}
          />
        </Input>
      </Inputs>
    ),

    3: (
      <Inputs>
        <Themes
          selected={form.values.theme.value}

          onSelect={onSelectTheme}
        />
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      name={step === 1 ? 'Step 1: Info' : step === 2 ? 'Step 2: Branding (optional)' : 'Step 3: Design'}

      onClose={onClose}

      footerLeftStart={<>
        {[2, 3].includes(step) && (
          <Button
            version='text'

            onClick={() => setStep(step - 1)}
          >
            Back
          </Button>
        )}
      </>}

      footerRightEnd={<>
        {[1, 2].includes(step) && (
          <Button
            version='filled'

            onClick={onNextStep}

            disabled={!validSteps[step]}
          >
            Next
          </Button>
        )}

        {step === 3 && (
          <Button
            version='filled'

            onClick={onNext}

            disabled={!validSteps[step] || loading}
          >
            Create website
          </Button>
        )}
      </>}

      loading={loading}

      close={false}

      add

      maxHeight
    >
      {steps[step]}
    </ModalForm>
  );
});

export default Element;
