import React from 'react';

import { TimeRangePicker } from '@onesy/ui-react'
import { ITimeRangePicker } from '@onesy/ui-react/TimeRangePicker/TimeRangePicker';

const Element: React.FC<ITimeRangePicker> = props => {

  return (
    <TimeRangePicker
      size='small'

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
};

export default Element;
