import React from 'react';

import { TextToSpeech } from '@onesy/ui-react'
import { ITextToSpeech } from '@onesy/ui-react/TextToSpeech/TextToSpeech';

const Element: React.FC<ITextToSpeech> = props => {

  return (
    <TextToSpeech
      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
