import React from 'react';

import { cleanValue, is, textToInnerHTML } from '@onesy/utils';
import { Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';

import { DatePicker, Input, Inputs, ModalForm, NumericTextField, Select, SmartTextField, TextField } from 'ui';
import { AppService, AssessmentService, AuthService } from 'services';
import { getDate, getErrorMessage, optionsGender } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    customer,

    onConfirm
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      date: {
        name: 'Date',
        value: object?.response?.date || OnesyDate.milliseconds,
        is: 'number'
      },
      weight: {
        name: 'Weight',
        value: object?.response?.weight,
        is: 'string'
      },
      height: {
        name: 'Height',
        value: object?.response?.height,
        is: 'string'
      },
      BMI: {
        name: 'BMI',
        value: object?.response?.BMI,
        is: 'string'
      },
      age: {
        name: 'Age',
        value: object?.response?.age,
        is: 'number'
      },
      gender: {
        name: 'Gender',
        value: object?.response?.gender,
        is: 'string'
      },
      blood_pressure: {
        name: 'Blood pressure',
        value: object?.response?.blood_pressure,
        is: 'string'
      },
      body_fat: {
        name: 'Body fat',
        value: object?.response?.body_fat,
        is: 'string'
      },
      circumference: {
        name: 'Circumference',
        value: object?.response?.circumference,
        is: 'string'
      },
      skinfolds: {
        name: 'Skinfold',
        value: object?.response?.skinfolds,
        is: 'string'
      },
      note: {
        name: 'Note',
        value: object?.note,
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      note,

      ...otherForm
    } = refs.form.current.value;

    const body = {
      response: {
        ...otherForm
      },

      note,

      customer: {
        id: customer.id,
        name: customer.name
      },

      apps: ['personal-trainer']
    };

    const result = !object?.id ? await AssessmentService.add(body) : await AssessmentService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Assessment ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, signedIn, onConfirm, onClose]);

  const inputPropsRead: any = {
    gap: 0.5,
    size: 'small'
  };

  const inputProps: any = {
    size: 'small'
  };

  const modal: any = {
    read: (
      <Inputs
        gapMain={2}
      >
        {object?.response?.date && (
          <Input
            name='Date'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {getDate(object.response.date)}
            </Type>
          </Input>
        )}

        {object?.response?.height !== undefined && (
          <Input
            name='Height'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.height}
            </Type>
          </Input>
        )}

        {object?.response?.weight !== undefined && (
          <Input
            name='Weight'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.weight}
            </Type>
          </Input>
        )}

        {object?.response?.BMI !== undefined && (
          <Input
            name='BMI'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.BMI}
            </Type>
          </Input>
        )}

        {object?.response?.age !== undefined && (
          <Input
            name='Age'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.age} year{object.response.age === 1 ? '' : 's'}
            </Type>
          </Input>
        )}

        {object?.response?.gender !== undefined && (
          <Input
            name='Gender'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {cleanValue(object.response.gender, { capitalize: true })}
            </Type>
          </Input>
        )}

        {object?.response?.blood_pressure !== undefined && (
          <Input
            name='Blood pressure'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.blood_pressure}
            </Type>
          </Input>
        )}

        {object?.response?.body_fat !== undefined && (
          <Input
            name='Body fat'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.body_fat}
            </Type>
          </Input>
        )}

        {object?.response?.circumference !== undefined && (
          <Input
            name='Circumference'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.circumference}
            </Type>
          </Input>
        )}

        {object?.response?.skinfolds !== undefined && (
          <Input
            name='Skinfolds'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.response.skinfolds}
            </Type>
          </Input>
        )}

        {object?.note !== undefined && (
          <Input
            name='Note'

            {...inputPropsRead}
          >
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.note)
              }}
            />
          </Input>
        )}
      </Inputs>
    ),

    write: (
      <Inputs>
        <Input
          name='Date'

          {...inputProps}
        >
          <DatePicker
            name='Date'

            value={form.value.date ? new OnesyDate(form.value.date) : undefined}

            onChange={(valueNew: any) => form.onChange('date', valueNew.milliseconds)}

            error={!!form.values.date.error}

            helperText={form.values.date.error}

            fullWidth
          />
        </Input>

        <Input
          name='Height'

          {...inputProps}
        >
          <TextField
            name='Height'

            valueDefault={form.values.height.value}

            onChange={(valueNew: any) => form.onChange('height', valueNew)}

            error={!!form.values.height.error}

            helperText={form.values.height.error}

            fullWidth
          />
        </Input>

        <Input
          name='Weight'

          {...inputProps}
        >
          <TextField
            name='Weight'

            valueDefault={form.values.weight.value}

            onChange={(valueNew: any) => form.onChange('weight', valueNew)}

            error={!!form.values.weight.error}

            helperText={form.values.weight.error}

            fullWidth
          />
        </Input>

        <Input
          name='BMI'

          {...inputProps}
        >
          <TextField
            name='BMI'

            valueDefault={form.values.BMI.value}

            onChange={(valueNew: any) => form.onChange('BMI', valueNew)}

            error={!!form.values.BMI.error}

            helperText={form.values.BMI.error}

            fullWidth
          />
        </Input>

        <Input
          name='Age'

          {...inputProps}
        >
          <NumericTextField
            name='Age'

            value={form.values.age.value}

            onChange={(valueNew: string) => form.onChange('age', valueNew)}

            error={!!form.values.age.error}

            helperText={form.values.age.error}

            min={1}

            sufix={form.value.age === 1 ? 'year' : 'years'}

            fullWidth
          />
        </Input>

        <Input
          name='Gender'

          {...inputProps}
        >
          <Select
            name='Gender'

            valueDefault={form.values.gender.value}

            onChange={(valueNew: string) => form.onChange('gender', valueNew)}

            options={optionsGender}

            error={!!form.values.gender.error}

            helperText={form.values.gender.error}

            fullWidth
          />
        </Input>

        <Input
          name='Blood pressure'

          {...inputProps}
        >
          <TextField
            name='Blood pressure'

            valueDefault={form.values.blood_pressure.value}

            onChange={(valueNew: any) => form.onChange('blood_pressure', valueNew)}

            error={!!form.values.blood_pressure.error}

            helperText={form.values.blood_pressure.error}

            fullWidth
          />
        </Input>

        <Input
          name='Body fat'

          {...inputProps}
        >
          <TextField
            name='Body fat'

            valueDefault={form.values.body_fat.value}

            onChange={(valueNew: any) => form.onChange('body_fat', valueNew)}

            error={!!form.values.body_fat.error}

            helperText={form.values.body_fat.error}

            fullWidth
          />
        </Input>

        <Input
          name='Circumference'

          {...inputProps}
        >
          <TextField
            name='Circumference'

            valueDefault={form.values.circumference.value}

            onChange={(valueNew: any) => form.onChange('circumference', valueNew)}

            error={!!form.values.circumference.error}

            helperText={form.values.circumference.error}

            fullWidth
          />
        </Input>

        <Input
          name='Skinfolds'

          {...inputProps}
        >
          <TextField
            name='Skinfolds'

            valueDefault={form.values.skinfolds.value}

            onChange={(valueNew: any) => form.onChange('skinfolds', valueNew)}

            error={!!form.values.skinfolds.error}

            helperText={form.values.skinfolds.error}

            fullWidth
          />
        </Input>

        <Input
          name='Note'

          {...inputProps}
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.note.value)}

            onChange={(valueNew: string) => form.onChange('note', valueNew)}

            additional={{
              version: 'b1'
            }}

            edit

            multiline
          />
        </Input>
      </Inputs>
    )
  };

  const nameCustomer = textToInnerHTML(customer?.name);

  return (
    <ModalForm
      {...props}

      name={!object ? `Add new assessment for ${nameCustomer}` : `Assessment for ${nameCustomer}`}

      object={object}

      add={!object}

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
