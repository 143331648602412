import React from 'react';

import { classNames } from '@onesy/style-react';

import { Items, NoResults, Page } from 'ui';
import { FormService } from 'services';
import Item from './ItemResponse';

const Forms = React.forwardRef((props: any) => {
  const {
    form,

    className,

    ...other
  } = props;

  return (
    <Page
      name='Responses'

      singular='response'

      plural='responses'

      service={FormService}

      add={false}

      parent={form}

      formUpdateProps={{
        form
      }}

      queryDefault={{
        apps: ['personal-trainer']
      }}

      queryObjectsName='queryObjectsResponses'

      secondary

      className={classNames([
        className,
        'onesy-FormResponses'
      ])}

      {...other}
    >
      {({ response }) => {
        if (!response?.length) return <NoResults />;

        return (
          <Items
            service={FormService}

            queryObjectsName='queryObjectsResponses'
          >
            {response?.map((item: any) => (
              <Item
                key={item.id}

                object={item}

                color='secondary'
              />
            ))}
          </Items>
        );
      }}
    </Page>
  );
});

export default Forms;
