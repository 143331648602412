import React from 'react';

import { innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { Switch, useForm, useSnackbars } from '@onesy/ui-react';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialCardMembership from '@onesy/icons-material-rounded-react/IconMaterialCardMembershipW100';

import { AutoCompleteObjects, Color, DurationForm, Input, Inputs, MediaForm, ModalForm, PriceForm, QuantityForm, Repeat, SelectAColor, TextField } from 'ui';
import { AppService, CategoryService, NutritionPlanService, ServiceService, WorkoutPlanService } from 'services';
import { getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'price': {
        name: 'Price',
        value: object?.price || {
          value: 0
        },
        is: 'object'
      },
      'price_repeat': {
        name: 'Payment repeat',
        value: object?.price_repeat || {
          value: 1,
          unit: 'month',
          active: true
        },
        is: 'object'
      },
      'sessions': {
        name: 'Sessions',
        value: object?.sessions || {
          unlimited: true,
          unit: 'total'
        },
        is: 'object'
      },
      'classes': {
        name: 'Classes',
        value: object?.classes || {
          unlimited: true,
          unit: 'total'
        },
        is: 'object'
      },
      'limit_per_customer': {
        name: 'Limit per customer',
        value: object?.limit_per_customer || {
          unlimited: true
        },
        is: 'object'
      },
      'move_unused': {
        name: 'Move unused',
        value: object?.move_unused,
        is: 'boolean'
      },
      'nutrition_plans': {
        name: 'Nutrition plans',
        is: 'array',
        of: 'object',
        value: object?.nutrition_plans
      },
      'workout_plans': {
        name: 'Workout plans',
        is: 'array',
        of: 'object',
        value: object?.workout_plans
      },
      'payment_categories': {
        name: 'Payment categories',
        is: 'array',
        of: 'object',
        value: object?.payment_categories
      },
      'categories': {
        name: 'Membership categories',
        is: 'array',
        of: 'object',
        value: object?.categories
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'image': {
        name: 'Image',
        value: object?.image || {},
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio || {},
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      type: 'membership',
      apps: ['personal-trainer']
    };

    if (is('array', body.nutrition_plans)) body.nutrition_plans = body.nutrition_plans.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.workout_plans)) body.workout_plans = body.workout_plans.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.payment_categories)) body.payment_categories = body.payment_categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.categories)) body.categories = body.categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await ServiceService.add(body) : await ServiceService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Service ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const modal: any = {
    Info: <>
      <Inputs>
        <PriceForm
          description='Membership price. If membership price repeats every 1 month, this is the price per month for the duration of the membership.'

          form={form}
        />

        <Repeat
          name='Payment repeat'

          property='price_repeat'

          form={form}

          noActive

          noEnd
        />

        <QuantityForm
          name='Sessions'

          description={`Sessions included per ${form.value.price_repeat?.unit}`}

          property='sessions'

          form={form}
        />

        <QuantityForm
          name='Classes'

          description={`Classes included per ${form.value.price_repeat?.unit}`}

          property='classes'

          form={form}
        />

        <QuantityForm
          name='Limit per customer'

          description={`If there's a limit of how many customer can purchase`}

          property='limit_per_customer'

          sufix='uses'

          form={form}
        />

        <DurationForm
          description='How long does the membership last'

          form={form}
        />

        <Input
          startName={(
            <Switch
              value={form.values['move_unused'].value}

              onChange={(valueNew: any) => form.onChange('move_unused', valueNew)}
            />
          )}

          name='Move unused into next period'

          description='If all unused sessions, classes should be moved to the next period'
        />

        <Input
          name='Nutrition plans'

          description='Nutrition plans included in this membership, that clients will have access to'
        >
          <AutoCompleteObjects
            name='Nutrition plans'

            value={form.values.nutrition_plans.value}

            onChange={(valueNew: any) => form.onChange('nutrition_plans', valueNew)}

            service={NutritionPlanService}

            query={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Workout plans'

          description='Workout plans included in this membership, that clients will have access to'
        >
          <AutoCompleteObjects
            name='Workout plans'

            value={form.values.workout_plans.value}

            onChange={(valueNew: any) => form.onChange('workout_plans', valueNew)}

            service={WorkoutPlanService}

            query={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Payment categories'
        >
          <AutoCompleteObjects
            name='Payment category'

            value={form.values.payment_categories.value}

            onChange={(valueNew: any) => form.onChange('payment_categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('payment_categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Input
          name='Membership categories'
        >
          <AutoCompleteObjects
            name='Membership category'

            value={form.values.categories.value}

            onChange={(valueNew: any) => form.onChange('categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('categories', [...(form.value.categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'membership',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'membership',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Description'
      >
        <TextField
          placeholder='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={3}

          maxRows={4}

          multiline

          fullWidth
        />
      </Input>

      <MediaForm
        type='image'

        name='Image'

        form={form}
      />

      <MediaForm
        type='audio'

        name='Audio'

        form={form}
      />

      <MediaForm
        type='video'

        name='Video'

        form={form}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialCardMembership },
        { name: 'About', Icon: IconMaterialInfo }
      ]}

      onChangeTab={onChangeTab}

      startHeaderRight={mode === 'update' ? (
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      ) : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
