import { Template } from '@onesy/api';

import { BaseService } from './base';

class TemplateService extends BaseService<Template> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('templates');
  }

}

export default new TemplateService();
