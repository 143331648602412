import React from 'react';

import { is, textToInnerHTML } from '@onesy/utils';
import { Type } from '@onesy/ui-react';
import { Form, FormResponse } from '@onesy/api';

import { Input, Inputs, ModalForm } from 'ui';
import { AppService } from 'services';
import { getDate } from 'utils';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object
  } = props;

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const form = object.form as Form;

  const getInput = (item: any, index: number) => {
    let Element: any;

    const input = form.inputs.find(itemInput => itemInput.id === item.id);

    if (!input) return null;

    const type = input.type;

    const value = item.value;

    if ([undefined, ''].includes(value)) {
      Element = (
        <Type
          version='l1'
        >
          No answer
        </Type>
      );
    }
    else {
      if (['text'].includes(type)) Element = (
        <Type
          version='l1'

          whiteSpace='pre-wrap'

          dangerouslySetInnerHTML={{
            __html: textToInnerHTML(value)
          }}
        />
      );

      if (['date-time'].includes(type)) Element = (
        <Type
          version='l1'
        >
          {value ? getDate(value, 'entire') : 'No date time'}
        </Type>
      );

      if (['date'].includes(type)) Element = (
        <Type
          version='l1'
        >
          {value ? getDate(value, 'date') : 'No date'}
        </Type>
      );

      if (['time'].includes(type)) Element = (
        <Type
          version='l1'
        >
          {value ? getDate(value, 'time') : 'No time'}
        </Type>
      );

      if (['select', 'radios', 'checkboxes'].includes(type)) Element = (
        <Type
          version='l1'
        >
          {(is('array', value) ? value : [value]).join(', ')}
        </Type>
      );

      if (['slider'].includes(type)) Element = (
        <Type
          version='b1'
        >
          <b>{Math.round(value)}</b> within {input.min !== undefined ? input.min : 1} and {input.max !== undefined ? input.max : 10}
        </Type>
      );

      if (['rating'].includes(type)) Element = (
        <Type
          version='b1'
        >
          <b>{value}</b> out of {input.max !== undefined ? input.max : 5}
        </Type>
      );

      if (['confirm'].includes(type)) Element = (
        <Type
          version='l1'
        >
          {value ? 'Yes' : 'No'}
        </Type>
      );
    }

    if (Element === undefined) return null;

    return (
      <Input
        key={index}

        gap={1}

        name={input.name}

        description={textToInnerHTML(input.description)}
      >
        {Element}
      </Input>
    );
  };

  const modal: any = {
    read: (
      <Inputs>
        {(object as FormResponse).response.map((item, index) => getInput(item, index))}
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      name={`Response from ${object.customer.name} on ${getDate(object.added_at, 'entire')}`}

      add

      onClose={onClose}
    >
      {modal.read}
    </ModalForm>
  );
});

export default Element;