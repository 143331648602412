import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getFileName, is } from '@onesy/utils';
import { DropZone, Image, Line, useSnackbars } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import { MediaService } from 'services';
import { getErrorMessage } from 'utils';

const useStyle = style(theme => ({
  root: {

  },

  dropZone: {
    '&.onesy-DropZone-root': {
      display: 'flex',
      flex: '1 1 auto'
    }
  },

  image: {
    '&.onesy-Image-root': {
      width: '100%',
      maxWidth: 740
    }
  },

  ...theme.classes(theme)
}), { name: 'onesy-LibraryAdd' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onAdd,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<any>(false);

  const onDropZoneChange = React.useCallback(async (value: File[]) => {
    setLoading('add-drop-zone');

    try {
      const media = value[0];

      const name = getFileName(media);

      // validate
      // 140 mb maximum
      if (media.size > (140 * 1e6)) {
        snackbars.add({
          color: 'error',
          primary: `Maximum allowed file size is 140 mb`
        });

        return;
      }

      // meta
      const meta: any = {};

      const result = await MediaService.add({
        name,

        meta,

        apps: ['personal-trainer'],

        // media
        media
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
      else {
        snackbars.add({
          primary: 'Media added'
        });

        if (is('function', onAdd)) {
          onAdd();
        }
        else {
          navigate('/library');
        }
      }
    }
    catch (error) { }

    setLoading(false);
  }, [onAdd]);

  return (
    <Line
      ref={ref}

      gap={0}

      align='unset'

      justify='unset'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <DropZone
        color='inherit'

        onChange={onDropZoneChange as any}

        disabled={loading}

        className={classes.dropZone}
      >
        <Line
          align='center'

          justify='center'

          flex

          fullWidth
        >
          <Image
            src='/assets/images/upload.png'

            alt='Upload'

            className={classes.image}

            draggable={false}
          />
        </Line>
      </DropZone>
    </Line>
  );
});

export default Element;
