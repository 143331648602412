import React from 'react';

import { capitalize, is } from '@onesy/utils';
import { Buttons, Chip, Switch, useForm, useSnackbars } from '@onesy/ui-react';

import IconMaterialCircle from '@onesy/icons-material-rounded-react/IconMaterialCircleW100Filled';

import { AutoCompleteObjects, Input, Inputs, ModalForm, TextField } from 'ui';
import { AppService, CustomerService, DiscountService, ServiceService } from 'services';
import { getErrorMessage, optionsCustomerStatus, optionsPurchaseType } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'status': {
        name: 'Status',
        value: 'active',
        is: 'string'
      },
      'email': {
        name: 'Email',
        isValid: 'email'
      },
      'mobile': {
        name: 'Mobile',
        is: 'string'
      },
      'purchase': {
        name: 'Purchase',
        value: {
          type: 'package',
          auto_payment: true,
          auto_charge: false,
          without_payment: false
        },
        is: 'object'
      },
      'email_receipt': {
        name: 'Email receipt',
        value: false,
        is: 'boolean'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      email_receipt,

      ...valueForm
    } = refs.form.current.value;

    const body = {
      ...valueForm,

      apps: ['personal-trainer']
    };

    if (body.purchase.object?.id) {
      body.purchase.object = {
        id: body.purchase.object.id,
        name: body.purchase.object.name
      };

      if (is('array', body.purchase.discounts)) body.purchase.discounts = body.purchase.discounts.map((item: any) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        value: item.value_onesy_original
      }));
    }
    else {
      delete body.purchase;
    }

    const result = await CustomerService.add(body, { query: { email_receipt } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Client added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [form, onConfirm, onClose]);

  const modal: any = {
    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Status'
        >
          <Buttons
            value={form.value.status}

            orientation={{
              default: 'horizontal',
              700: 'vertical'
            }}
          >
            {optionsCustomerStatus.map(item => {
              const color = item.value === 'active' ? 'success' : item.value === 'inactive' ? 'inherit' : 'info';

              return (
                <Chip
                  value={item.value}

                  onClick={() => form.onChange('status', item.value)}

                  color={color}

                  start={<IconMaterialCircle color={color} size='very small' />}
                >
                  {item.name}
                </Chip>
              );
            })}
          </Buttons>
        </Input>

        <Input
          name='Email'
        >
          <TextField
            placeholder='Email'

            valueDefault={form.values['email'].value}

            error={form.values['email'].error}

            helperText={form.values['email'].error}

            onChange={(valueNew: any) => form.onChange('email', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Mobile'
        >
          <TextField
            placeholder='Mobile'

            valueDefault={form.values['mobile'].value}

            error={form.values['mobile'].error}

            helperText={form.values['mobile'].error}

            onChange={(valueNew: any) => form.onChange('mobile', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Purchase a service'

          endName={(
            <Buttons
              value={form.value.purchase?.type}

              orientation={{
                default: 'horizontal',
                700: 'vertical'
              }}

              size='small'
            >
              {optionsPurchaseType.map(item => (
                <Chip
                  value={item.value}

                  onClick={() => form.onChange([
                    ['purchase', item.value, 'type'],
                    ['purchase', null, 'object']
                  ])}
                >
                  {item.name}
                </Chip>
              ))}
            </Buttons>
          )}
        >
          <Input
            name={capitalize(form.value.purchase?.type)}

            size='small'
          >
            <AutoCompleteObjects
              name={capitalize(form.value.purchase?.type)}

              value={form.value.purchase?.object}

              query={{
                type: form.value.purchase?.type,
                apps: ['personal-trainer']
              }}

              onChange={(valueNew: any) => form.onChange('purchase', valueNew, 'object')}

              service={ServiceService}

              fullWidth
            />
          </Input>

          <Input
            name='Discounts'

            description='Discounts to be applied for the payment/charge (optional)'

            size='small'
          >
            <AutoCompleteObjects
              name='Discount'

              value={form.value.purchase?.discounts}

              query={{
                remaining: { $gt: 0 },
                apps: ['personal-trainer']
              }}

              onChange={(valueNew: any) => form.onChange('purchase', valueNew, 'discounts')}

              service={DiscountService}

              multiple

              fullWidth
            />
          </Input>

          <Buttons
            value={form.value.purchase?.auto_payment ? 'auto-payment' : form.value.purchase?.auto_charge ? 'auto-charge' : 'without-payment'}

            orientation={{
              default: 'horizontal',
              700: 'vertical'
            }}
          >
            <Chip
              value='auto-payment'

              onClick={() => form.onChange([
                ['purchase', true, 'auto_payment'],
                ['purchase', false, 'auto_charge'],
                ['purchase', false, 'without_payment']
              ])}
            >
              Auto payment
            </Chip>

            <Chip
              value='auto-charge'

              onClick={() => form.onChange([
                ['purchase', false, 'auto_payment'],
                ['purchase', true, 'auto_charge'],
                ['purchase', false, 'without_payment']
              ])}
            >
              Auto charge
            </Chip>

            <Chip
              value='without-payment'

              onClick={() => form.onChange([
                ['purchase', false, 'auto_payment'],
                ['purchase', false, 'auto_charge'],
                ['purchase', true, 'without_payment']
              ])}
            >
              Without payment
            </Chip>
          </Buttons>

          <Input
            startName={(
              <Switch
                value={form.values['email_receipt'].value}

                onChange={(valueNew: any) => form.onChange('email_receipt', valueNew)}

                size='small'
              />
            )}

            name='Email receipt'

            size='small'
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
