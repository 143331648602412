import React from 'react';

import { cleanValue, copy, getID, is, textToInnerHTML, wait } from '@onesy/utils';
import { DateTimePicker, IconButton, Line, LineChart, Medias, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { add, OnesyDate } from '@onesy/date';

import IconMaterialContract from '@onesy/icons-material-rounded-react/IconMaterialContractW100';
import IconMaterialFlagCircle from '@onesy/icons-material-rounded-react/IconMaterialFlagCircleW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialContentCopy from '@onesy/icons-material-rounded-react/IconMaterialContentCopyW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { Input, Inputs, ModalForm, NumericTextField, Select, SelectMedia, SmartTextField, TextField } from 'ui';
import { AppService, AuthService, CustomerService, GoalService } from 'services';
import { getDate, getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    customer,

    object: object_,

    onConfirm
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Goal');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'status': {
        name: 'Status',
        value: object?.status || 'active',
        is: 'string'
      },
      'value': {
        name: 'Goal',
        value: object?.value || {}
      },
      starts_at: {
        name: 'Starts at',
        value: object?.starts_at || OnesyDate.milliseconds
      },
      ends_at: {
        name: 'Ends at',
        value: object?.ends_at || add(1, 'month').milliseconds
      },
      'logs': {
        name: 'Logs',
        value: object?.logs
      },
      'color': {
        name: 'Color',
        value: object?.color || null,
      },
      'media': {
        name: 'Media',
        value: [...object?.media || []],
        is: 'array',
        of: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeMode = React.useCallback(async (valueNew: string) => {
    setTab('Goal')

    if (tab !== 'Goal') await wait(340);

    setMode(valueNew);
  }, [tab]);

  const onChangeTab = React.useCallback(async (valueNew: string) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    const employee = signedIn.user;

    if (!object) {
      body.employees = [
        {
          id: employee.id,
          name: employee.name
        }
      ];

      body.customers = [
        {
          id: customer.id,
          name: customer.name
        }
      ];
    }

    const result = !object?.id ? await GoalService.add(body) : await GoalService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Goal ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [customer, object, form, onConfirm, onClose]);

  const onAddLog = React.useCallback(() => {
    const logs = [...(refs.form.current.values.logs.value || [])];

    logs.push({
      id: getID(),

      name: 'New log',

      value: {},

      added_at: OnesyDate.milliseconds,
      updated_at: OnesyDate.milliseconds
    });

    refs.form.current.onChange('logs', logs);
  }, []);

  const onMakeCopyStep = React.useCallback((index: number, event: MouseEvent) => {
    event.stopPropagation();

    const logs = [...(refs.form.current.values.logs.value || [])];

    const log = logs[index];

    if (log) {
      logs.push({
        ...copy(log),

        id: getID(),

        name: `${log.name} copy`
      });
    }

    refs.form.current.onChange('logs', logs);
  }, []);

  const onRemoveLog = React.useCallback((index: number, event: MouseEvent) => {
    event.preventDefault();

    const logs = [...(refs.form.current.values.logs.value || [])];

    logs.splice(index, 1);

    refs.form.current.onChange('logs', logs);
  }, []);

  const inputPropsRead: any = {
    gap: 0.5,
    size: 'small'
  };

  const inputProps: any = {
    size: 'small'
  };

  const modal: any = {
    Goal: <>
      <Inputs>
        <Input
          name='Name'

          {...inputProps}
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values['name'].error}

            helperText={form.values['name'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Description'

          {...inputProps}
        >
          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values['description'].error}

            helperText={form.values['description'].error}

            minRows={3}

            maxRows={4}

            multiline

            edit

            fullWidth
          />
        </Input>

        <Input
          name='Status'

          {...inputProps}
        >
          <Select
            name='Status'

            value={form.values.status.value || ''}

            onChange={(valueNew: any) => form.onChange('status', valueNew)}

            options={[
              { name: 'Active', value: 'active' },
              { name: 'Completed', value: 'completed' },
              { name: 'Paused', value: 'paused' },
              { name: 'Pending', value: 'pending' },
              { name: 'Postponed', value: 'postponed' },
              { name: 'Cancelled', value: 'cancelled' },
              { name: 'Other', value: 'other' }
            ]}

            error={!!form.values['status'].error}

            helperText={form.values['status'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Goal'

          description='Value, unit describes a measurable goal ie. to lose weight by 40 kg'

          MainProps={{
            gap: 1,
            direction: {
              default: 'row',
              700: 'column'
            },
            align: 'center',
            flexNo: true
          }}
        >
          <NumericTextField
            name='Value'

            value={form.values.value.value?.value}

            onChange={(valueNew: any) => form.onChange('value', valueNew, 'value')}

            fullWidth
          />

          <TextField
            name='Unit'

            value={form.values.value.value?.unit}

            onChange={(valueNew: any) => form.onChange('value', valueNew, 'unit')}

            fullWidth
          />
        </Input>

        <Input
          name='Starts at'

          {...inputProps}
        >
          <DateTimePicker
            name='Starts at'

            value={form.values.starts_at.value ? new OnesyDate(form.values.starts_at.value) : null}

            onChange={(valueNew: any) => form.onChange('starts_at', valueNew.milliseconds)}

            service={CustomerService}

            error={!!form.values['starts_at'].error}

            helperText={form.values['starts_at'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Ends at'

          {...inputProps}
        >
          <DateTimePicker
            name='Ends at'

            value={form.values.ends_at.value ? new OnesyDate(form.values.ends_at.value) : null}

            onChange={(valueNew: any) => form.onChange('ends_at', valueNew.milliseconds)}

            service={CustomerService}

            error={!!form.values['ends_at'].error}

            helperText={form.values['ends_at'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Media'

          {...inputProps}
        >
          <SelectMedia
            name='Media'

            value={form.values.media.value}

            mediaSelected={form.values.media.value}

            mime='all'

            onChange={(valueNew: any) => form.onChange('media', valueNew)}

            multiple
          />

          <Medias
            values={form.values.media.value?.map((item: any) => ({
              value: item
            }))}
          />
        </Input>
      </Inputs>
    </>,

    'Logs': <>
      <Inputs>
        <Input
          gap={3}

          name='Progress logs'

          description={`Anytime there's a change in the goal progress, you can make a new log describing it. Latest log represents the current goal state.`}

          endName={(
            <Tooltip
              name='Add log'
            >
              <IconButton
                onClick={onAddLog}
              >
                <IconMaterialAdd size='large' />
              </IconButton>
            </Tooltip>
          )}

          size='large'
        >
          {refs.form.current.value.logs?.map((item: any, index: number) => (
            <Input
              key={item.id}

              name={`Log ${index + 1}${item?.value?.value !== undefined ? `: ${item.value.value} ${item.value.unit}` : ''}`}

              description={`Added at ${getDate(item.added_at, 'entire')}`}

              endName={<>
                <Tooltip
                  name='Make a copy'
                >
                  <IconButton
                    onClick={(event: MouseEvent) => onMakeCopyStep(index, event)}
                  >
                    <IconMaterialContentCopy size='regular' />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  name='Remove log'
                >
                  <IconButton
                    onClick={(event: MouseEvent) => onRemoveLog(index, event)}
                  >
                    <IconMaterialRemove size='regular' />
                  </IconButton>
                </Tooltip>
              </>}

              size='regular'

              expand
            >
              <Input
                name='Log name'

                {...inputProps}
              >
                <TextField
                  name='Name'

                  valueDefault={textToInnerHTML(item.name)}

                  onChange={(valueNew: any) => form.onChange([
                    ['logs', valueNew, `${index}.name`, { rerenderOnUpdate: false }],
                    ['logs', OnesyDate.milliseconds, `${index}.updated_at`]
                  ])}

                  fullWidth
                />
              </Input>

              <Input
                name='Log description'

                {...inputProps}
              >
                <SmartTextField
                  placeholder='Description'

                  valueDefault={textToInnerHTML(item.description || '')}

                  onChange={(valueNew: any) => form.onChange([
                    ['logs', valueNew, `${index}.description`, { rerenderOnUpdate: false }],
                    ['logs', OnesyDate.milliseconds, `${index}.updated_at`]
                  ])}

                  minRows={3}

                  maxRows={4}

                  multiline

                  edit

                  fullWidth
                />
              </Input>

              <Input
                name='Progress value'

                description='Value, unit describes a measurable goal ie. to lose weight by 40 kg'

                MainProps={{
                  gap: 1,
                  direction: {
                    default: 'row',
                    700: 'column'
                  },
                  align: 'center',
                  flexNo: true
                }}
              >
                <NumericTextField
                  name='Value'

                  value={item.value?.value}

                  onChange={(valueNew: any) => form.onChange([
                    ['logs', valueNew, `${index}.value.value`],
                    ['logs', OnesyDate.milliseconds, `${index}.updated_at`]
                  ])}

                  max={form.values.value.value.value || 1e14}

                  fullWidth
                />

                <TextField
                  name='Unit'

                  value={item.value?.unit}

                  onChange={(valueNew: any) => form.onChange([
                    ['logs', valueNew, `${index}.value.unit`],
                    ['logs', OnesyDate.milliseconds, `${index}.updated_at`]
                  ])}

                  fullWidth
                />
              </Input>

              <Input
                name='Media'

                {...inputProps}
              >
                <SelectMedia
                  name='Media'

                  value={item.media}

                  mediaSelected={item.media}

                  mime='all'

                  onChange={(valueNew: any) => form.onChange([
                    ['logs', valueNew, `${index}.media`],
                    ['logs', OnesyDate.milliseconds, `${index}.updated_at`]
                  ])}

                  multiple
                />

                <Medias
                  values={item.media?.map((item: any) => ({
                    value: item
                  }))}
                />
              </Input>
            </Input>
          ))}
        </Input>
      </Inputs>
    </>,

    read: (
      <Inputs
        gapMain={2}
      >
        {object?.logs?.length > 1 && (
          <Line
            fullWidth

            style={{
              padding: '12px 74px 0px 40px'
            }}
          >
            <LineChart
              names={{
                x: 'Updated at',
                y: 'Progress'
              }}

              nameX

              nameY

              maxY={object?.value?.value}

              guidelines='both'

              smooth={false}

              values={[
                {
                  color: 'primary',

                  name: 'Progress',

                  values: object.logs.map((item: any) => [item.updated_at, item.value?.value])
                }
              ]}

              labelResolve={(itemValue, axis, item, version) => {
                if (axis === 'x') return getDate(itemValue, version ? 'entire' : 'DD/MM/YYYY HH:mm[h]');

                return `${itemValue} ${object?.value?.unit || ''}`;
              }}

              marksYAutoNumber={7}

              labelsYAutoNumber={7}

              marksXAutoNumber={5}

              labelsXAutoNumber={5}

              points

              pointsVisibility='visible'
            />
          </Line>
        )}

        <Input
          name='Goal'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {`${object?.value?.value || 0} ${object?.value?.unit || ''}`.trim()}
          </Type>
        </Input>

        {object?.starts_at && (
          <Input
            name='Starts at'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {getDate(object.starts_at)}
            </Type>
          </Input>
        )}

        {object?.ends_at && (
          <Input
            name='Ends at'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {getDate(object.ends_at)}
            </Type>
          </Input>
        )}

        {object?.description !== undefined && (
          <Input
            name='Description'

            {...inputPropsRead}
          >
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          </Input>
        )}

        {object?.status !== undefined && (
          <Input
            name='Status'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {cleanValue(object.status, { capitalize: true })}
            </Type>
          </Input>
        )}

        {object?.employees !== undefined && (
          <Input
            name='Trainers'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.employees?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        {object?.employee_groups !== undefined && (
          <Input
            name='Trainer groups'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.employee_groups?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        <Input
          name='Logs'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {!object?.logs?.length ? 'No logs' : `${object?.logs?.length} logs`}
          </Type>
        </Input>

        <Input
          name='Latest log'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {object?.logs?.length ? `${object?.logs?.[object?.logs?.length - 1]?.value?.value || 0} ${object?.logs?.[object?.logs.length - 1]?.value?.unit || ''}`.trim() : ''}
          </Type>
        </Input>

        {!!object?.media?.length && (
          <Input
            name='Media'

            {...inputPropsRead}
          >
            <Medias
              values={object.media.map(item => ({
                value: item
              }))}
            />
          </Input>
        )}
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Goal', Icon: IconMaterialFlagCircle },
        { name: 'Logs', Icon: IconMaterialContract }
      ] : undefined}

      onChangeTab={onChangeTab}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;