import React from 'react';

import { Form } from '@onesy/ui-react'
import { IForm } from '@onesy/ui-react/Form/Form';

const Element: React.FC<IForm> = props => {

  return (
    <Form
      {...props}
    />
  );
};

export default Element;
