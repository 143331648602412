import React from 'react';

import { AutoCompleteGooglePlaces } from '@onesy/ui-react'
import { IAutoCompleteGooglePlaces } from '@onesy/ui-react/AutoCompleteGooglePlaces/AutoCompleteGooglePlaces';

const Element: React.FC<IAutoCompleteGooglePlaces> = props => {

  return (
    <AutoCompleteGooglePlaces
      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
