import React from 'react';

import { arrayMoveItem, clamp, copy, getID, innerHTMLToText, is, stringToColor, textToInnerHTML } from '@onesy/utils';
import { IconButton, Label, Line, ListItem, Switch, Tooltip, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { INutritionPlan } from '@onesy/api';

import IconMaterialInfo from '@onesy/icons-material-rounded-react/IconMaterialInfoW100';
import IconMaterialGrocery from '@onesy/icons-material-rounded-react/IconMaterialGroceryW100';
import IconMaterialKeyboardArrowUp from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialContentCopy from '@onesy/icons-material-rounded-react/IconMaterialContentCopyW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, Color, Divider, Input, Inputs, MediaForm, ModalForm, NoResults, NumericTextField, SelectAColor, SmartTextField, TextField } from 'ui';
import { AppService, IngredientService, NutritionPlanService, RecipeService } from 'services';
import { getErrorMessage, propertyCalories } from 'utils';
import NutritionPlan from 'pages/main/NutritionPlans/NutritionPlans/NutritionPlan';

const useStyle = style(theme => ({
  root: {

  },

  week: {
    flex: '0 0 auto',
    padding: 1,
    overflow: 'auto hidden'
  },

  day: {
    border: `1px solid ${theme.palette.light ? '#dadada' : '#575757'}`,
    height: 'unset',
    marginLeft: -1,
    marginTop: -1,
    minHeight: 300,
    minWidth: 400,
    padding: 12
  },

  recipes: {
    maxHeight: 145,
    overflow: 'hidden auto'
  }
}), { name: 'onesy-RecipePlan' });

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState(object ? 'read' : 'update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'weeks': {
        name: 'Weeks',
        value: object?.weeks || [],
        is: 'array',
        of: 'object'
      },
      'repeat': {
        name: 'Repeat',
        value: object?.repeat,
        is: 'number'
      },
      'color': {
        name: 'Color',
        value: object?.color,
        is: 'string'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      },
      quantities: {
        name: 'Quantities',
        value: object?.quantities || {},
        is: 'object'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body: INutritionPlan = {
      ...refs.form.current.value,

      total_calories: 0,
      average_calories_month: 0,
      average_calories_week: 0,

      apps: ['personal-trainer']
    };

    body.weeks = body.weeks.map((item, index) => {
      item.total_calories = getTotalWeek(index);

      body.total_calories += item.total_calories;

      if (item.days) {
        item.days.forEach((day, indexDay) => {
          day.total_calories = getTotalDay(index, indexDay);

          if (is('array', day.ingredients)) day.ingredients = day.ingredients.map(itemIngredient => ({
            id: itemIngredient.id,
            name: textToInnerHTML(itemIngredient.name),
            nutrition: {
              [propertyCalories]: +itemIngredient.nutrition[propertyCalories]
            },
            image: itemIngredient.image
          }));

          if (is('array', day.recipes)) day.recipes = day.recipes.map(itemRecipe => ({
            id: itemRecipe.id,
            name: textToInnerHTML(itemRecipe.name)
          }));
        });
      }

      return item;
    });

    body.average_calories_month = body.total_calories / clamp(Math.floor(body.weeks.length / 4), 1);

    body.average_calories_week = body.total_calories / body.weeks.length;

    const result = !object?.id ? await NutritionPlanService.add(body) : await NutritionPlanService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Nutrition plan ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onAddWeek = React.useCallback(() => {
    const weeks = [...refs.form.current.values.weeks.value];

    weeks.push({
      id: getID(),
      rest: false,
      days: new Array(7).fill(1).map(() => ({ id: getID(), rest: false, recipes: [] }))
    });

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onRemoveWeek = React.useCallback((index: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    weeks.splice(index, 1);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onRemoveRecipe = React.useCallback((index: number, indexDay: number, recipe: any) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const day = weeks[index].days[indexDay];

    const indexRecipe = day.recipes.findIndex(itemRecipe => itemRecipe.id === recipe.id);

    if (indexRecipe > -1) day.recipes.splice(indexRecipe, 1);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onRemoveIngredient = React.useCallback((index: number, indexDay: number, indexIngredient: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const ingredients = weeks[index].days[indexDay].ingredients;

    ingredients.splice(indexIngredient, 1);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onCopyWeek = React.useCallback((index: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const week = copy(weeks[index]);

    week.id = getID();

    week.days.forEach(day => {
      day.id = getID();
    });

    weeks.push(week);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const onMoveWeek = React.useCallback((index: number, indexNew: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    arrayMoveItem(weeks, index, indexNew);

    refs.form.current.onChange('weeks', weeks);
  }, []);

  const getTotalDay = React.useCallback((index: number, indexDay: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const ingredients = weeks[index].days[indexDay].ingredients || [];

    let value = 0;

    ingredients.forEach(item => {
      const quantityID = `${weeks[index].id}-${weeks[index].days[indexDay].id}-${item.id}`;

      value += +((item.nutrition[propertyCalories] / 100) * (refs.form.current.value.quantities?.[quantityID] || 0)).toFixed(2);
    });

    return +(value).toFixed(2);
  }, []);

  const getTotalWeek = React.useCallback((index: number) => {
    const weeks = [...refs.form.current.values.weeks.value];

    const week = weeks[index];

    let value = 0;

    week.days.forEach((day, indexDay) => {
      value += getTotalDay(index, indexDay);
    });

    return +(value).toFixed(2);
  }, []);

  const getTotal = React.useCallback(() => {
    const weeks = [...refs.form.current.values.weeks.value];

    let value = 0;

    weeks.forEach((week, index) => {
      value += getTotalWeek(index);
    });

    return +(value).toFixed(2);
  }, []);

  const modal: any = {
    read: <>
      <NutritionPlan
        object={{
          ...object,

          ...form.value
        }}
      />
    </>,

    Info: <>
      <Inputs>
        <Input
          name='Repeat'

          description='How many times to repeat it'
        >
          <NumericTextField
            placeholder='Repeat'

            valueDefault={form.values.repeat.value}

            onChange={(valueNew: any) => form.onChange('repeat', valueNew)}

            min={0}

            fullWidth
          />
        </Input>

        <Inputs
          gap={3}

          gapMain={2.5}

          name='Weeks'

          description={(
            <Type
              version='t1'

              weight={400}
            >
              Total calories <b>{getTotal()}</b> kcal
            </Type>
          )}

          size='large'

          endName={(
            <Tooltip
              name='Add week'
            >
              <IconButton
                onClick={onAddWeek}
              >
                <IconMaterialAdd
                  size='large'
                />
              </IconButton>
            </Tooltip>
          )}
        >
          {form.values.weeks.value?.map((week, index) => (
            <Input
              key={week.id}

              gap={2}

              name={`Week ${index + 1}`}

              description={(
                <Type
                  version='b1'
                >
                  Total weekly calories <b>{getTotalWeek(index)}</b> kcal
                </Type>
              )}

              endName={[
                <Label
                  value={week.rest}

                  onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.rest`)}
                >
                  <Switch />

                  <Type
                    version='b1'
                  >
                    Rest week
                  </Type>
                </Label>,

                <Tooltip
                  name='Move up'
                >
                  <IconButton
                    onClick={() => onMoveWeek(index, index - 1)}

                    disabled={!index}
                  >
                    <IconMaterialKeyboardArrowUp
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Move down'
                >
                  <IconButton
                    onClick={() => onMoveWeek(index, index + 1)}

                    disabled={index >= (form.value.weeks?.length - 1)}
                  >
                    <IconMaterialKeyboardArrowDown
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Copy week'
                >
                  <IconButton
                    onClick={() => onCopyWeek(index)}
                  >
                    <IconMaterialContentCopy
                      size='large'
                    />
                  </IconButton>
                </Tooltip>,

                <Tooltip
                  name='Remove week'
                >
                  <IconButton
                    onClick={() => onRemoveWeek(index)}
                  >
                    <IconMaterialRemove
                      size='large'
                    />
                  </IconButton>
                </Tooltip>
              ]}

              size='large'
            >
              {week.rest && (
                <NoResults
                  padding={0}

                  maxWidth={340}
                >
                  Rest week
                </NoResults>
              )}

              {!week.rest && (
                <Line
                  gap={0}

                  direction='row'

                  align='stretch'

                  fullWidth

                  className={classes.week}
                >
                  {week.days?.map((day, indexDay) => {
                    const withValues = !!day.ingredients?.length;

                    return (
                      <Input
                        name={`Day ${indexDay + 1}`}

                        endName={(
                          <Label
                            value={day.rest}

                            onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.days.${indexDay}.rest`)}

                            position='top'

                            size='small'
                          >
                            <Switch />

                            <Type
                              version='b3'
                            >
                              Rest day
                            </Type>
                          </Label>
                        )}

                        NameProps={{
                          align: 'flex-start'
                        }}

                        className={classNames([
                          classes.day
                        ])}

                        size='small'
                      >
                        <Line
                          gap={3}

                          fullWidth
                        >
                          {!day.rest ? <>
                            <Input
                              name='Ingredients'

                              weight={600}

                              size='small'
                            >
                              <AutoCompleteObjects
                                placeholder='Ingredient'

                                value={day.ingredients}

                                onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.days.${indexDay}.ingredients`)}

                                service={IngredientService}

                                multiple

                                noInputValue

                                fullWidth
                              />

                              <Line
                                gap={2}

                                fullWidth
                              >
                                {!withValues && (
                                  <NoResults
                                    padding={40}

                                    maxWidth={240}
                                  >
                                    Select ingredients
                                  </NoResults>
                                )}

                                {withValues && <>
                                  {day.ingredients?.map((item, indexIngredient) => {
                                    const quantityID = `${week.id}-${day.id}-${item.id}`;

                                    return (
                                      <ListItem
                                        key={item.id}

                                        color='inherit'

                                        primary={(
                                          <Type
                                            version='t2'

                                            weight={400}
                                          >
                                            {item.name}
                                          </Type>
                                        )}

                                        secondary={(
                                          <Line
                                            gap={1}

                                            direction='row'

                                            wrap='wrap'

                                            align='center'
                                          >
                                            <Type
                                              version='b1'

                                              weight={300}

                                              whiteSpace='nowrap'
                                            >
                                              {item.nutrition[propertyCalories] / 100}
                                            </Type>

                                            <Type
                                              version='b1'

                                              weight={100}

                                              whiteSpace='nowrap'
                                            >
                                              kcal / g x
                                            </Type>

                                            <SmartTextField
                                              placeholder='quantity'

                                              value={form.value.quantities?.[quantityID]}

                                              onChange={(valueNew: any) => form.onChange('quantities', valueNew, quantityID)}

                                              additional={{
                                                version: 'b1',
                                                weight: 300
                                              }}

                                              style={{
                                                width: 'auto'
                                              }}
                                            />

                                            <Type
                                              version='b1'

                                              weight={100}

                                              whiteSpace='nowrap'
                                            >
                                              g =
                                            </Type>

                                            <Type
                                              version='b1'

                                              weight={300}

                                              whiteSpace='nowrap'
                                            >
                                              {+((item.nutrition[propertyCalories] / 100) * (form.value.quantities?.[quantityID] || 0)).toFixed(2)} kcal
                                            </Type>
                                          </Line>
                                        )}

                                        end={<>
                                          <Tooltip
                                            name='Remove ingredient'
                                          >
                                            <IconButton
                                              onClick={() => onRemoveIngredient(index, indexDay, indexIngredient)}

                                              className={classes.hidePrint}
                                            >
                                              <IconMaterialRemove
                                                size='regular'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </>}

                                        size='small'

                                        Component='div'

                                        noBackground

                                        noPadding
                                      />
                                    );
                                  })}

                                  <Divider size='small' />

                                  <Input
                                    name='Total calories'

                                    endName={(
                                      <Type
                                        version='t2'

                                        weight={500}
                                      >
                                        {getTotalDay(index, indexDay)} kcal
                                      </Type>
                                    )}

                                    size='small'
                                  />
                                </>}
                              </Line>
                            </Input>

                            <Input
                              name='Recipes'

                              weight={600}

                              size='small'
                            >
                              <AutoCompleteObjects
                                name='Recipes'

                                placeholder='Recipe'

                                value={day.recipes}

                                onChange={(valueNew: any) => form.onChange('weeks', valueNew, `${index}.days.${indexDay}.recipes`)}

                                service={RecipeService}

                                query={{
                                  onesy: true
                                }}

                                multiple

                                fullWidth

                                noInputValue
                              />

                              {!!day.recipes?.length && (
                                <Line
                                  gap={1}

                                  fullWidth

                                  className={classes.recipes}
                                >
                                  {day.recipes.map(recipe => {
                                    const palette = theme.methods.color(stringToColor(recipe?.name || 'recipe'));

                                    return (
                                      <Input
                                        name={recipe.name}

                                        endName={(
                                          <Tooltip
                                            name='Remove recipe'
                                          >
                                            <IconButton
                                              onClick={() => onRemoveRecipe(index, indexDay, recipe)}
                                            >
                                              <IconMaterialRemove
                                                size='regular'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        size='small'

                                        style={{
                                          padding: '4px 12px',
                                          background: palette[theme.palette.light ? 90 : 20]
                                        }}
                                      />
                                    );
                                  })}
                                </Line>
                              )}
                            </Input>
                          </> : <>
                            <Line
                              align='center'

                              fullWidth
                            >
                              <NoResults
                                padding={0}

                                maxWidth={240}

                                size='small'
                              >
                                Rest day
                              </NoResults>
                            </Line>
                          </>}
                        </Line>
                      </Input>
                    );
                  })}
                </Line>
              )}
            </Input>
          ))}
        </Inputs>
      </Inputs>
    </>,

    About: <>
      <Input
        name='Name'
      >
        <TextField
          placeholder='Name'

          valueDefault={form.values['name'].value}

          error={form.values['name'].error}

          helperText={form.values['name'].error}

          onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

          fullWidth
        />
      </Input>

      <Input
        name='Description'
      >
        <TextField
          placeholder='Description'

          valueDefault={textToInnerHTML(form.values['description'].value || '')}

          error={form.values['description'].error}

          helperText={form.values['description'].error}

          onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

          minRows={3}

          maxRows={4}

          multiline

          fullWidth
        />
      </Input>

      <MediaForm
        type='image'

        name='Image'

        form={form}
      />

      <MediaForm
        type='audio'

        name='Audio'

        form={form}
      />

      <MediaForm
        type='video'

        name='Video'

        form={form}
      />
    </>
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={mode === 'update' ? [
        { name: 'Info', Icon: IconMaterialGrocery },
        { name: 'About', Icon: IconMaterialInfo }
      ] : undefined}

      onChangeTab={onChangeTab}

      startHeaderRight={mode === 'update' ? (
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      ) : (
        <Color
          color={form.value.color}
        />
      )}

      object={object}

      add={!object}

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {mode === 'read' ? modal.read : modal[tab]}
    </ModalForm>
  );
});

export default Element;
