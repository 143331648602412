import React from 'react';

import { cleanValue } from '@onesy/utils';
import { IBaseElement } from '@onesy/ui-react/types';

import IconMaterialReceiptLong from '@onesy/icons-material-rounded-react/IconMaterialReceiptLongW100';

import { ItemCard, ItemMenu } from 'ui';
import { getDate } from 'utils';

export interface IInvoiceItem extends IBaseElement {
  object: any;
}

const Element: React.FC<IInvoiceItem> = React.forwardRef((props, ref: any) => {
  const {
    object,

    ...other
  } = props;

  const onOpen = React.useCallback(() => {
    window.open(object.hosted_invoice_url, '_blank');
  }, [object]);

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          icon: IconMaterialReceiptLong,
          onClick: onOpen
        },
        {
          name: 'Number',
          value: object.number,
          onClick: onOpen
        },
        {
          name: 'Period',
          value: `${getDate(object.period_start * 1e3)} — ${getDate(object.period_end * 1e3)}`
        },
        {
          name: 'Status',
          value: cleanValue(object.status, { capitalize: true }),
          propsValue: {
            color: object.status === 'paid' ? 'success' : 'warning'
          }
        }
      ]}

      items_end={[
        {
          name: 'Created at',
          value: getDate(object?.created * 1e3)
        },
        {
          value: (
            <ItemMenu
              items={[
                { name: 'Open', onClick: onOpen }
              ]}
            />
          )
        }
      ]}

      {...other}
    />
  )
});

export default Element; 