import React from 'react';

import { is } from '@onesy/utils';
import { useForm, useSnackbars } from '@onesy/ui-react';

import { Input, Inputs, LocationForm, ModalForm, TextField } from 'ui';
import { AppService, LocationService } from 'services';
import { getErrorMessage } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'location': {
        name: 'Location',
        is: 'object',
        value: object?.location || {}
      },
      'coordinates': {
        name: 'Coordinates',
        is: 'string',
        value: object?.location?.coordinates?.join(', ')
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (body.coordinates) {
      body.location.coordinates = body.coordinates.split(',').filter(Boolean).map((item: any) => Number(item.trim()));
    }

    delete body.coordinates;

    const result = !object?.id ? await LocationService.add(body) : await LocationService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Location ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const modal: any = {
    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <LocationForm
          form={form}

          fullWidth
        />
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
