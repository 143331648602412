import React from 'react';

import { is } from '@onesy/utils';
import { Image, Line, Medias } from '@onesy/ui-react';

import { Button, useLibrary } from 'ui';
import { mediaToValue } from 'utils';

const SelectMedia = React.forwardRef((props: any, ref: any) => {
  const {
    value,

    onChange,

    search = {
      mime: ['image']
    },

    multiple = false,

    preview,

    className,

    children,

    ...other
  } = props;

  const media = useLibrary();

  const selected = (is('array', value) ? value : [value]).filter(Boolean);

  const onOpenMedia = React.useCallback(() => {
    media.open({
      search,

      multiple,

      selected,

      onConfirm: (valueNew: any) => {
        const response = is('array', valueNew) ? valueNew.map((item: any) => mediaToValue(item)) : mediaToValue(valueNew);

        onChange(is('array', response) && !multiple ? response[0] : response);
      }
    });
  }, [value, media, search, selected, multiple, onChange]);

  return (
    <Line
      gap={2}
    >
      {preview && value && !multiple && (
        <Image
          alt=''

          src={value?.url || value?.src || value}

          style={{
            maxWidth: 240,
            maxHeight: 240
          }}
        />
      )}

      {preview && value && multiple && (
        <Medias
          values={value?.map(item => ({
            value: item
          }))}

          size='large'
        />
      )}

      <Button
        version='outlined'

        color='inherit'

        size='regular'

        onClick={onOpenMedia}

        {...other}
      >
        {children || 'Select'}
      </Button>
    </Line>
  );
});

export default SelectMedia;
