import React from 'react';

import { innerHTMLToText, is } from '@onesy/utils';
import { IconButton, List, ListItem, Tooltip, Type, useForm, useSnackbars, useSubscription } from '@onesy/ui-react';
import { OnesyDate } from '@onesy/date';

import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, DatePicker, Input, Inputs, ModalForm, NumericTextField, TextField } from 'ui';
import { AppService, AuthService, CustomerService, InvoiceService, ServiceService } from 'services';
import { getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'customer': {
        name: 'Customer',
        is: 'object',
        required: true
      },
      'invoice_id': {
        name: 'Invoice number',
        required: true,
        is: 'string'
      },
      'issued_at': {
        name: 'Issued at',
        value: OnesyDate.milliseconds,
        is: 'number',
        required: true
      },
      'due_at': {
        name: 'Due at',
        value: OnesyDate.milliseconds,
        is: 'number',
        required: true
      },
      'items': {
        name: 'Items',
        value: [],
        is: 'array',
        of: 'object'
      },
      'tax': {
        name: 'Tax',
        is: 'number'
      },
      'note': {
        name: 'Note',
        is: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (body.customer) body.customer = {
      id: body.customer.id,
      name: body.customer.name
    };

    const result = !object?.id ? await InvoiceService.add(body) : await InvoiceService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Invoice ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeServices = React.useCallback((valueNew: any) => {
    const items = [...refs.form.current.value.items];

    const itemsNew = valueNew?.map(item => {
      const itemExists = items.find(itemPrevious => itemPrevious.service.id === item.id);

      return {
        service: {
          id: item.id,
          name: item.name,
          price: item.price
        },
        quantity: itemExists?.quantity || 0
      };
    }) || [];

    form.onChange('items', itemsNew);
  }, []);

  const onRemoveItem = React.useCallback((index: number) => {
    const items = [...refs.form.current.value.items];

    items.splice(index, 1);

    form.onChange('items', items);
  }, []);

  const currency = signedIn.organization.settings.currency || 'EUR';

  const modal: any = {
    write: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Client'

          description='Invoice for what client'
        >
          <AutoCompleteObjects
            name='Client'

            value={form.values.customer.value}

            onChange={(valueNew: any) => form.onChange('customer', valueNew)}

            service={CustomerService}

            query={{
              apps: ['personal-trainer']
            }}

            error={form.values['customer'].error}

            helperText={form.values['customer'].error}

            fullWidth
          />
        </Input>

        <Input
          name='Invoice number'
        >
          <TextField
            placeholder='01/2024'

            valueDefault={form.values['invoice_id'].value}

            error={form.values['invoice_id'].error}

            helperText={form.values['invoice_id'].error}

            onChange={(valueNew: any) => form.onChange('invoice_id', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Issued date'

          description='Date invoice was issued'
        >
          <DatePicker
            value={form.values['issued_at'].value ? new OnesyDate(form.values['issued_at'].value) : null}

            error={form.values['issued_at'].error}

            helperText={form.values['issued_at'].error}

            onChange={(valueNew: any) => form.onChange('issued_at', valueNew?.milliseconds || null)}

            fullWidth
          />
        </Input>

        <Input
          name='Due date'

          description='When is the final date for payment to be made'
        >
          <DatePicker
            value={form.values['due_at'].value ? new OnesyDate(form.values['due_at'].value) : null}

            error={form.values['due_at'].error}

            helperText={form.values['due_at'].error}

            onChange={(valueNew: any) => form.onChange('due_at', valueNew?.milliseconds || null)}

            fullWidth
          />
        </Input>

        <Input
          name='Items'

          description='Invoice items'
        >
          <AutoCompleteObjects
            name='Service'

            value={form.values.items.value?.map(item => item.service)}

            query={{
              apps: ['personal-trainer']
            }}

            onChange={onChangeServices}

            service={ServiceService}

            multiple

            fullWidth
          />

          {!!form.value.items?.length && (
            <List
              paddingVertical='none'

              paddingHorizontal='none'

              noBackground

              style={{
                gap: 12
              }}
            >
              {form.value.items.map((item: any, index: number) => (
                <ListItem
                  key={index}

                  noPadding

                  primary={(
                    <Type
                      version='l1'
                    >
                      {item.service?.name}
                    </Type>
                  )}

                  secondary={(
                    <Type
                      version='b2'
                    >
                      {item.service.price?.value || 0} {currency}
                    </Type>
                  )}

                  tertiary={(
                    <NumericTextField
                      key={index}

                      name='Quantity'

                      value={item.quantity}

                      onChange={(valueNew: any) => form.onChange('items', +valueNew, `${index}.quantity`)}

                      min={0}

                      style={{
                        marginTop: 8,
                        maxWidth: 200
                      }}
                    />
                  )}

                  end={(
                    <Tooltip
                      name={`Remove ${item.service?.name}`}
                    >
                      <IconButton
                        onClick={() => onRemoveItem(index)}
                      >
                        <IconMaterialRemove
                          size='large'
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  noBackground
                />
              ))}
            </List>
          )}
        </Input>

        <Input
          name='Tax'

          description='If tax is applied, from 0% to 100%'
        >
          <NumericTextField
            placeholder='Tax'

            valueDefault={form.values['tax'].value}

            error={form.values['tax'].error}

            helperText={form.values['tax'].error}

            onChange={(valueNew: any) => form.onChange('tax', +valueNew)}

            sufix='%'

            min={0}

            max={100}

            fullWidth
          />
        </Input>

        <Input
          name='Note'

          description='Any additional information'
        >
          <TextField
            placeholder='Note'

            error={form.values['note'].error}

            helperText={form.values['note'].error}

            onChange={(valueNew: any) => form.onChange('note', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            multiline

            minRows={3}

            maxRows={4}

            fullWidth
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}
    />
  );
});

export default Element;
