import React from 'react';
import { useNavigate } from 'react-router-dom';

import { classNames } from '@onesy/style-react';

import { Items, NoResults, Page } from 'ui';
import { IPage } from 'ui/layout/Page';
import { MediaService } from 'services';
import Item from './Item';

export interface ILibrary extends IPage {
  searches?: any;

  multiple?: boolean;

  select?: boolean;

  open?: boolean;

  modal?: boolean;

  style?: any;
}

const Element: React.FC<ILibrary> = React.forwardRef((props, ref: any) => {
  const {
    searches = {
      name: true,
      type: true,
      date: true
    },

    multiple,

    select,

    open,

    modal,

    ...other
  } = props;

  const navigate = useNavigate();

  const onAdd = React.useCallback(() => {
    navigate('/library/upload');
  }, []);

  const search: any = [];

  if (searches.name) search.push({
    name: 'Name',
    type: 'text',
    property: 'name'
  });

  if (searches.type) search.push({
    name: 'Type',
    type: 'select',
    default: [],
    options: [
      { name: 'Image', value: 'image' },
      { name: 'Audio', value: 'audio' },
      { name: 'Video', value: 'video' },
      { name: 'PDF', value: 'pdf' }
    ],
    property: 'mime',
    multiple: true
  });

  if (searches.date) search.push({
    name: 'Added at',
    type: 'date',
    property: 'added_at'
  });

  return (
    <Page
      ref={ref}

      name='Library'

      singular='media'

      plural='medias'

      service={MediaService}

      onAdd={onAdd}

      search={search}

      open={open}

      queryDefault={{
        // apps: ['personal-trainer']
      }}

      className={classNames([
        'onesy-Library'
      ])}

      {...other}
    >
      {({ response }) => {
        if (!response?.length) return <NoResults />;

        return (
          <Items
            service={MediaService}

            queryObjectsName={other.queryObjectsName}
          >
            {response?.map((item: any, index: number) => (
              <Item
                key={index}

                object={item}

                multiple={multiple}

                select={select}

                selectObjectsName={other.selectObjectsName}

                modal={modal}
              />
            ))}
          </Items>
        );
      }}
    </Page>
  );
});

export default Element;
