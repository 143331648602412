import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { copy, equalDeep, getID } from '@onesy/utils';
import { Line, Reset, useMediaQuery, useQuery, useSubscription } from '@onesy/ui-react';
import { IOnesyTheme, classNames, style, useOnesyTheme } from '@onesy/style-react';
import { User } from '@onesy/api';

import { Meta, useMainLoader } from 'ui';
import { AuthService, MainService, StorageService, UserService, SocketService } from 'services';
import { addStyle, getSettings } from 'utils';
import { ISignedIn } from 'types';
import Routes from './Routes';
import config from 'config';

// onesy 
(window as any).onesy_app = true;

const useStyle = style(theme => ({
  '@p': {
    '*': {
      fontOpticalSizing: 'auto',

      '&::selection': {
        color: theme.methods.palette.color.value('primary', 10),
        backgroundColor: theme.methods.palette.color.value('primary', 80)
      },

      '&[contenteditable]:empty:before': {
        color: 'inherit',
        content: 'attr(data-placeholder)',
        display: 'block',
        fontFamily: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        opacity: 0.5
      }
    },

    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.methods.palette.color.value('primary', 10),
      backgroundColor: theme.palette.light ? theme.palette.color.primary[98] : theme.palette.color.primary[10],
      height: '100vh',
      overflow: 'hidden',

      '& svg[data-logo-onesy]': {
        color: theme.palette.color.primary[theme.palette.light ? 14 : 95]
      },

      '& .onesy-time': {
        width: 'unset !important',

        '& .onesy-TimePicker-main': {
          width: 'clamp(250px, 100vw, 300px)'
        },

        '& .onesy-TimePicker-input-separator': {
          marginTop: '0 !important'
        },

        '& .onesy-TextField-wrapper, & .onesy-TextField-root': {
          width: '80px !important',
          height: 75,

          '& .onesy-TextField-input-wrapper': {
            paddingBlock: '0 !important'
          },

          '& input': {
            fontSize: '2rem',
            fontWeight: 500
          }
        }
      },

      '& b': {
        fontWeight: 600
      },

      '& .onesy-items-inline': {
        height: 480,
        padding: 40,
        borderRadius: 8,
        background: theme.palette.background.default.primary,
        boxShadow: theme.shadows.values.default[1],
        overflow: 'hidden auto'
      },

      '& .onesy-themes-App-test': {
        '& .onesy-Button-root, & .onesy-Icon-root': {
          pointerEvents: 'none'
        }
      },

      '& .onesy-overflow-y': {
        flex: '1 1 auto',
        height: 0,
        overflow: 'hidden auto'
      },

      '& .onesy-overflow-x': {
        paddingBlock: 6,
        overflow: 'auto hidden'
      },

      '& .onesy-Label-helper-text, & .onesy-TextField-helper-text': {
        ...theme.typography.values.b2
      },

      '& .onesy-Snackbar-root': {
        padding: '10px 10px 10px 32px',
        borderRadius: 0,

        '& .onesy-Snackbar-primary': {
          '& > .onesy-Type-root': {
            fontSize: '1rem',
            fontWeight: 300
          }
        }
      },

      '& .onesy-DateRangePicker-root': {
        '& .onesy-TextField-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .onesy-TextField-root': {
        '& .onesy-TextField-input, & .onesy-TextField-label, .onesy-Type-root.onesy-TextField-addition': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .onesy-Select-root': {
        '& .onesy-Select-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        },

        '& .onesy-ListItem-text-primary': {
          whiteSpace: 'nowrap'
        }
      },

      '& .onesy-AutoComplete-root': {
        '& .onesy-AutoComplete-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        },

        '& .onesy-ListItem-text-primary': {
          whiteSpace: 'nowrap'
        }
      },

      '& .onesy-DatePicker-root:not(.onesy-full-width), & .onesy-TextField-wrapper:not(.onesy-full-width), & .onesy-TextField-root:not(.onesy-full-width), & .onesy-Select-wrapper:not(.onesy-full-width), & .onesy-Select-root:not(.onesy-full-width), & .onesy-TextField-root:not(.onesy-full-width), & .onesy-AutoCompleteGooglePlaces-root:not(.onesy-full-width), & .onesy-AutoCompleteCurrency-root:not(.onesy-full-width), & .onesy-AutoComplete-wrapper:not(.onesy-full-width)': {
        width: theme.input,
        height: 50,
        flex: '0 0 auto'
      },

      '& .onesy-Type-root': {
        '&.onesy-Type-version-b1, &.onesy-Type-version-b2, &.onesy-Type-version-b3': {
          fontWeight: 200
        }
      },

      '& .onesy-Modal-surface': {
        '&.onesy-Surface-root': {
          borderRadius: 0,
          background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
        }
      },

      '& .onesy-Modal-background:not(.onesy-Modal-background-invisible)': {
        background: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('default', 10), theme.palette.light ? 20 : 40)
      },

      '& .onesy-Tabs-root.onesy-Surface-root': {
        background: 'transparent',
        minHeight: 'unset'
      },

      '& .onesy-Page-root': {
        maxWidth: 'unset'
      },

      '& .onesy-mention': {
        ...theme.typography.values.l2,

        fontSize: 'inherit',
        fontWeight: 400,
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.methods.palette.color.value('primary', 30)
      },

      '& .onesy-Tooltip-name-root': {
        '& > .onesy-DateTimePicker-main, & > .onesy-DatePicker-main, & > .onesy-TimePicker-main, & > .onesy-Calendar-root': {
          boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)'
        },

        '& > .onesy-List-root': {
          // maxWidth: 'unset',
          // width: '100%'
        }
      },

      '& .onesy-SmartTextField-root[data-placeholder="Description"]': {
        margin: '0 0 20px',
        overflow: 'hidden auto',

        ...theme.typography.values.b1
      }
    },

    a: {
      color: 'var(--onesy-palette-color-primary-30)'
    },

    '.onesy-logo-onesy': {
      color: (theme.palette.color as any).onesy.main,
      fill: (theme.palette.color as any).onesy.main
    },

    '.onesy-logo-personal-trainer': {
      color: (theme.palette.color as any)['personal-trainer'].main,
      fill: (theme.palette.color as any)['personal-trainer'].main
    },

    '.onesy-logo-website': {
      color: (theme.palette.color as any).website.main,
      fill: (theme.palette.color as any).website.main
    },

    '.onesy-logo-task': {
      color: (theme.palette.color as any).task.main,
      fill: (theme.palette.color as any).task.main
    },

    '.onesy-logo-note': {
      color: (theme.palette.color as any).note.main,
      fill: (theme.palette.color as any).note.main
    },

    '.onesy-logo-url-shortener': {
      color: (theme.palette.color as any)['url-shortener'].main,
      fill: (theme.palette.color as any)['url-shortener'].main
    },

    '.onesy-logo-chat': {
      color: (theme.palette.color as any).chat.main,
      fill: (theme.palette.color as any).chat.main
    },

    '.onesy-logo-contact': {
      color: (theme.palette.color as any).contact.main,
      fill: (theme.palette.color as any).contact.main
    }
  },

  '@media only screen and (max-width: 479px)': {
    '.onesy-Select-wrapper, .onesy-Select-root': {
      width: '100%'
    }
  },

  root: {
    height: '100vh',
    position: 'relative',
    zIndex: 14
  }
}), { name: 'Root' });

const Main = () => {
  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const light = useMediaQuery('(prefers-color-scheme: light)');

  const { close } = useMainLoader();

  const query = useQuery();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const refs = {
    theme: React.useRef(theme),
    light: React.useRef(light),
    signedIn: React.useRef(signedIn)
  };

  refs.theme.current = theme;

  refs.light.current = light;

  refs.signedIn.current = signedIn;

  const updateTheme = React.useCallback((initial = false) => {
    const valueThemeInitial = {
      options: {},

      preference: {},

      palette: {},

      typography: {}
    };

    const valueTheme: IOnesyTheme = copy(valueThemeInitial);

    if (refs.signedIn.current?.user) {
      if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('auto' as any)) {
        if (refs.theme.current.palette.light !== refs.light.current) valueTheme.palette!.light = refs.light.current;
      }
      else if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('light' as any)) {
        if (!refs.theme.current.palette.light) valueTheme.palette!.light = true;
      }
      else if (getSettings(refs.signedIn.current, 'settings.theme.palette.light') === ('dark' as any)) {
        if (refs.theme.current.palette.light) valueTheme.palette!.light = false;
      }
    }
    else {
      if (refs.theme.current.palette.light !== refs.light.current) valueTheme.palette!.light = refs.light.current;
    }

    // options
    const optionsMotion = getSettings(refs.signedIn.current, 'settings.theme.options.motion');

    if (optionsMotion !== undefined && refs.theme.current.options.motion !== optionsMotion) {
      valueTheme.options.motion = optionsMotion;
    }

    // preferences
    const visualContrastDefault = getSettings(refs.signedIn.current, 'settings.theme.preference.visual_contrast.default');

    if (visualContrastDefault !== undefined && refs.theme.current.preference.visual_contrast.default !== visualContrastDefault) {
      valueTheme.preference!.visual_contrast = {
        default: visualContrastDefault
      };
    }

    // accessibility
    const accessibility = getSettings(refs.signedIn.current, 'settings.theme.palette.accessibility');

    if (accessibility !== undefined && refs.theme.current.palette.accessibility !== accessibility) {
      valueTheme.palette!.accessibility = accessibility;
    }

    // font size
    const fontSizeHTML = getSettings(refs.signedIn.current, 'settings.theme.typography.font_size.html');

    if (fontSizeHTML !== undefined && refs.theme.current.typography.font_size.html !== fontSizeHTML) {
      valueTheme.typography!.font_size = {
        html: fontSizeHTML
      };
    }

    if (initial) {
      const direction = StorageService.get('direction');

      if (direction !== null) valueTheme.direction = direction ? 'ltr' : 'rtl';
    }

    if (!equalDeep(valueTheme, valueThemeInitial)) refs.theme.current.updateWithRerender(valueTheme);
  }, []);

  const init = React.useCallback(async () => {
    try {
      // update theme
      updateTheme(true);

      // device ID
      let deviceID = StorageService.get('device-id');

      if (!deviceID) {
        deviceID = getID();

        StorageService.add('device-id', deviceID);
      }

      // onesy_token  
      const onesy_token = query.get('onesy_token');

      if (onesy_token) {
        StorageService.add('token', onesy_token);

        window.history.pushState(undefined, undefined, window.location.pathname);
      }

      // init 
      await AuthService.init();

      MainService.initial.emit(true);

      // Sockets
      if (signedIn?.user) {
        SocketService.init();
      }

      // invite
      // if valid, sign out, and redirect to proper page
      const invited_id = query.get('invite');

      if (invited_id) {
        const inviteResponse = await UserService.getInvite(invited_id);

        if (inviteResponse.status === 200) {
          const invite = inviteResponse.response.response as User;

          const isNew = (invite as any).new;

          UserService.invite = invite;

          await AuthService.signOut(false);

          navigate({
            pathname: isNew ? '/sign-up' : '/sign-in',
            search: `?invite=${invite.id}`
          });
        }
      }
    }
    catch (error) {
      console.log(error);
    }

    if (config.value.production) {
      Sentry.init({
        dsn: config.value.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    close();
  }, [signedIn, close]);

  React.useEffect(() => {
    // init
    init();

    const methodError = (error: any) => {
      if (error.message.includes('ResizeObserver')) {
        console.error('ResizeObserver', error);

        const webPackOverlay = window.document.getElementById('webpack-dev-server-client-overlay');

        if (webPackOverlay) webPackOverlay.remove();
      }
    };

    window.addEventListener('error', methodError);

    return () => {
      window.removeEventListener('error', methodError);
    };
  }, []);

  // theme
  React.useEffect(() => {
    // update theme
    updateTheme();

    (window as any).onesyTheme = theme;
  }, [signedIn, light, theme]);

  // style
  React.useEffect(() => {
    const id = signedIn?.user?.id;

    if (id) {
      addStyle(`
        body *[data-onesy-id="${id}"] {
          color: inherit;
        }
      `, `onesy-mention`);
    }
  }, [signedIn?.user?.id, theme.palette.light]);

  return <>
    <Reset />

    <Meta />

    <Line
      gap={0}

      direction='column'

      align='unset'

      justify='unset'

      className={classNames([
        'onesy-App',

        classes.root
      ])}
    >
      <Routes />
    </Line>
  </>;
};

export default Main;