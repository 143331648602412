import React from 'react';

import { capitalize, innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, Switch, Tooltip, Type, useConfirm, useForm, useSnackbars } from '@onesy/ui-react';
import { add, OnesyDate } from '@onesy/date';
import { Booking } from '@onesy/api';

import IconMaterialNoteStackW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialNoteStackW100';
import IconMaterialWallet from '@onesy/icons-material-rounded-react/IconMaterialWalletW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialEventNote from '@onesy/icons-material-rounded-react/IconMaterialEventNoteW100';
import IconMaterialDeleteW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialDeleteW100';

import { AutoCompleteObjects, CalendarMenu, Color, DurationForm, Input, Inputs, MediaForm, ModalForm, PayForm, QuantityForm, Repeat, Select, SelectAColor, SmartTextField, TextField } from 'ui';
import { AppService, BookingService, LocationService, RoomService } from 'services';
import { getDate, getErrorMessage, optionsBookingIntegrations, optionsBookingStatusUpdate } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState('update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'status': {
        name: 'Status',
        value: object?.status,
        is: 'string'
      },
      'starts_at': {
        name: 'Starts at',
        value: object?.starts_at || OnesyDate.milliseconds,
        is: 'number',
        required: true
      },
      'ends_at': {
        name: 'Ends at',
        value: object?.ends_at,
        is: 'number'
      },
      'repeat': {
        name: 'Repeat',
        is: 'object',
        value: object?.repeat || {}
      },
      'reminders': {
        name: 'Reminders',
        value: object?.reminders || [],
        is: 'array',
        of: 'object'
      },
      'color': {
        name: 'Color',
        value: object?.color || 'lightblue',
        is: 'string'
      },
      'spaces': {
        name: 'Spaces',
        value: object?.spaces || {
          value: 0,
          unlimited: false
        },
        is: 'object'
      },
      'locations': {
        name: 'Locations',
        is: 'array',
        of: 'object',
        value: object?.locations || []
      },
      'rooms': {
        name: 'Rooms',
        is: 'array',
        of: 'object',
        value: object?.rooms || []
      },
      'pay_override': {
        name: 'Pay override',
        value: object?.pay_override,
        is: 'boolean'
      },
      'pay': {
        name: 'Pay',
        value: object?.pay || {},
        is: 'object'
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'cancellation_policy': {
        name: 'Cancellation policy',
        value: object?.cancellation_policy || {
          value: 1,
          unit: 'day'
        },
        is: 'object'
      },
      'online_booking': {
        name: 'Online booking',
        value: object?.online_booking,
        is: 'boolean'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      },
      'room_shared': {
        name: 'Room shared',
        value: object?.room_shared,
        is: 'boolean'
      },
      'notes_public': {
        name: 'Notes public',
        value: object?.notes_public,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'notes_private': {
        name: 'Notes private',
        value: object?.notes_private,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'integrations': {
        name: 'Integrations',
        value: object?.integrations,
        is: 'array',
        of: 'string'
      }
    },

    valueDefault: {
      ...object
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    } as Booking;

    if (is('array', body.locations)) body.locations = body.locations.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.rooms)) body.rooms = body.rooms.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (!body.status || body.status === 'booked') delete body.status;

    // ends_at 
    // starts_at + duration 
    if (body.starts_at && body.duration) body.ends_at = add(body.duration.value, body.duration.unit as any, new OnesyDate(body.starts_at)).milliseconds;

    const result = await BookingService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking updated`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const onRemove = React.useCallback(async (object: any) => {
    const confirmed = await confirm.open({
      name: `Removing a booking will remove all related information to this booking, payments, expenses etc. It can't be undone.`
    });

    if (!confirmed) return;

    const result = await BookingService.remove(object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking removed`
      });
    }

    // close 
    onClose();

    // refresh 
    BookingService.refetch();
  }, []);

  const errors = Object.keys(form.values).map(item => form.values[item].error).filter(Boolean).join(', ');

  const modal: any = {
    Info: <>
      <Inputs>
        {object.status === 'booked' && (
          <Input
            name='Booking status'

            description={`You can complete booking if it's done, or postpone, cancel it.`}
          >
            <Select
              value={form.value.status}

              onChange={(valueNew: any) => form.onChange('status', valueNew)}

              options={optionsBookingStatusUpdate}

              fullWidth
            />
          </Input>
        )}

        {!!object?.customers?.length && (
          <Input
            gap={1}

            name='Clients'
          >
            <Type
              version='b1'
            >
              {object.customers.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {!!object?.customer_groups?.length && (
          <Input
            gap={1}

            name='Client groups'
          >
            <Type
              version='b1'
            >
              {object.customer_groups.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {!!object?.employees?.length && (
          <Input
            gap={1}

            name='Trainers'
          >
            <Type
              version='b1'
            >
              {object.employees.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {!!object?.employee_groups?.length && (
          <Input
            gap={1}

            name='Trainer groups'
          >
            <Type
              version='b1'
            >
              {object.employee_groups.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {object?.type === 'class' && (
          <QuantityForm
            description='How many spaces does the class have'

            form={form}

            noUnit
          />
        )}

        <Input
          name='Locations'
        >
          <AutoCompleteObjects
            name='Locations'

            value={form.values.locations.value}

            onChange={(valueNew: any) => form.onChange('locations', valueNew)}

            onAdd={(valueNew: any) => form.onChange('locations', [...(form.value.locations || []), valueNew])}

            service={LocationService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />
        </Input>

        <Input
          name='Rooms'
        >
          <AutoCompleteObjects
            name='Rooms'

            value={form.values.rooms.value}

            onChange={(valueNew: any) => form.onChange('rooms', valueNew)}

            onAdd={(valueNew: any) => form.onChange('rooms', [...(form.value.rooms || []), valueNew])}

            service={RoomService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />

          <Input
            startName={(
              <Switch
                value={form.values['room_shared'].value}

                onChange={(valueNew: any) => form.onChange('room_shared', valueNew)}

                size='small'
              />
            )}

            name='Shared room'

            description={`Check if room ${object?.type} is in, is a shared room.`}

            size='small'
          />
        </Input>

        <Input
          name='Cancellation policy'

          description='How long until it can be canceled'

          endHeader={(
            <Line
              gap={1}

              direction='row'

              wrap='wrap'

              align='center'

              justify={{
                default: 'flex-start',
                700: 'flex-end'
              }}

              fullWidth={{
                default: false,
                700: true
              }}

              flexNo
            >
              <DurationForm
                name=''

                form={form}

                property='cancellation_policy'

                fullWidth={false}
              />
            </Line>
          )}
        />

        <Repeat
          form={form}

          description='Repeat the booking'

          fullWidth
        />

        <Input
          name='Integrations'

          description='Note: At the moment integration is one way only. That means we will create for example a Google Calendar event on booking add, but if you update booking date/time afterwards, you will have to manually update your Google Calendar event for it.  You have to have integration connected in organization settings for this to work. This update only applies for repeating bookings.'
        >
          <Select
            name='Integrations'

            value={form.value.integrations}

            onChange={(valueNew: any) => form.onChange('integrations', valueNew)}

            options={optionsBookingIntegrations}

            multiple

            clear

            fullWidth
          />
        </Input>

        <Input
          startName={(
            <Switch
              value={form.values['online_booking'].value}

              onChange={(valueNew: any) => form.onChange('online_booking', valueNew)}
            />
          )}

          name='Online booking'

          description='Check if you want this class to show on the website as available for users to choose for booking online'
        />
      </Inputs>
    </>,

    Finances: <>
      <Inputs>
        <Input
          startName={(
            <Switch
              value={form.values['pay_override'].value}

              onChange={(valueNew: any) => form.onChange('pay_override', valueNew)}
            />
          )}

          name='Pay override'

          description='If selected this will be used as the payment details for the trainers, for this booking, instead their individual payment details are used.'
        />

        {form.value.pay_override && (
          <PayForm
            name='Booking trainer payment override'

            description={`If you want to set custom payment for this ${object?.type} specifically, for all trainers. It will override each trainer's individual payment details.`}

            form={form}
          />
        )}
      </Inputs>
    </>,

    About: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={3}

            maxRows={4}

            multiline

            fullWidth
          />
        </Input>

        <MediaForm
          type='image'

          name='Image'

          form={form}
        />

        <MediaForm
          type='audio'

          name='Audio'

          form={form}
        />

        <MediaForm
          type='video'

          name='Video'

          form={form}
        />
      </Inputs>
    </>,

    Notes: <>
      <Inputs>
        <Input
          name='Notes private'

          description='Only visible to employees'
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_private.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_private', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>

        <Input
          name='Notes public'

          description='Visible to everyone '
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_public.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_public', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Booking'

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialEventNote },
        { name: 'Finances', Icon: IconMaterialWallet },
        { name: 'About', Icon: IconMaterialSmartDisplay },
        { name: 'Notes', Icon: IconMaterialNoteStackW100Rounded }
      ]}

      onChangeTab={onChangeTab}

      endHeaderLeft={mode === 'update' ? <></> : capitalize(object?.type || 'session')}

      startHeaderRight={mode === 'update' ? <>
        <Input
          name='Starts at'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <CalendarMenu
            value={form.value}

            onChange={form.onChange}

            dateProperty='starts_at'

            closeOnChange={false}

            time

            noRepeat

            noRemove

            style={{
              marginLeft: -8
            }}
          />
        </Input>

        <DurationForm
          form={form}
        />
      </> : <>
        <Type
          version='b2'
        >
          {getDate(form.value.starts_at, 'entire')}
        </Type>

        <Input
          name='Duration'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <Type
            version='b2'
          >
            {!!Object.keys(form.value.duration || {}).length ? `${form.value.duration?.value} ${form.value.duration?.unit}` : 'No duration'}
          </Type>
        </Input>
      </>}

      footerLeftEnd={mode === 'update' ? <>
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </> : (
        <Color
          color={form.value.color}
        />
      )}

      footerRightStart={<>
        {errors && (
          <Type
            color='error'
          >
            {errors}
          </Type>
        )}

        <Tooltip
          name='Remove'
        >
          <IconButton
            onClick={() => onRemove(object)}
          >
            <IconMaterialDeleteW100Rounded />
          </IconButton>
        </Tooltip>
      </>}

      object={object}

      add

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
