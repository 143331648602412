import React from 'react';

import { capitalize, innerHTMLToText, is, setObjectValue, textToInnerHTML } from '@onesy/utils';
import { Buttons, Chip, Line, Switch, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { add, OnesyDate } from '@onesy/date';
import { Booking, Service, Template } from '@onesy/api';

import IconMaterialNoteStackW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialNoteStackW100';
import IconMaterialWallet from '@onesy/icons-material-rounded-react/IconMaterialWalletW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialEventNote from '@onesy/icons-material-rounded-react/IconMaterialEventNoteW100';

import { AutoCompleteObjects, CalendarMenu, Color, DurationForm, Input, Inputs, MediaForm, ModalForm, PayForm, PriceForm, QuantityForm, Repeat, Select, SelectAColor, SmartTextField, Templates, TextField } from 'ui';
import { AppService, BookingService, CategoryService, CustomerGroupService, CustomerService, DiscountService, LocationService, OrganizationService, RoomService, ServiceService, UserGroupService } from 'services';
import { getDate, getErrorMessage, optionsBookingIntegrations, optionsBookingType } from 'utils';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [mode, setMode] = React.useState('update');
  const [tab, setTab] = React.useState('Info');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'type': {
        name: 'Type',
        value: object?.type || 'session',
        is: 'string'
      },
      'starts_at': {
        name: 'Starts at',
        value: object?.starts_at || OnesyDate.milliseconds,
        is: 'number',
        required: true
      },
      'ends_at': {
        name: 'Ends at',
        value: object?.ends_at,
        is: 'number'
      },
      'repeat': {
        name: 'Repeat',
        is: 'object',
        value: {}
      },
      'reminders': {
        name: 'Reminders',
        is: 'array',
        of: 'object'
      },
      'color': {
        name: 'Color',
        value: object?.color || 'lightblue',
        is: 'string'
      },
      'spaces': {
        name: 'Spaces',
        value: object?.spaces || {
          value: 0,
          unlimited: false
        },
        is: 'object'
      },
      'price': {
        name: 'Price',
        value: object?.price || {
          value: 0
        },
        is: 'object'
      },
      'service': {
        name: 'Service',
        value: object?.service,
        is: 'object'
      },
      'payment_categories': {
        name: 'Payment categories',
        is: 'array',
        of: 'object',
        value: object?.payment_categories
      },
      'session_categories': {
        name: 'Session categories',
        is: 'array',
        of: 'object',
        value: object?.session_categories || []
      },
      'class_categories': {
        name: 'Class categories',
        is: 'array',
        of: 'object',
        value: object?.class_categories || []
      },
      'categories': {
        name: 'Class categories',
        is: 'array',
        of: 'object',
        value: object?.categories || []
      },
      'customers': {
        name: 'Clients',
        is: 'array',
        of: 'object',
        value: object?.customers || []
      },
      'customer_groups': {
        name: 'Client groups',
        is: 'array',
        of: 'object',
        value: object?.customer_groups || []
      },
      'employees': {
        name: 'Trainers',
        is: 'array',
        of: 'object',
        value: object?.employees || []
      },
      'employee_groups': {
        name: 'Trainer groups',
        is: 'array',
        of: 'object',
        value: object?.employee_groups || []
      },
      'locations': {
        name: 'Locations',
        is: 'array',
        of: 'object',
        value: object?.locations || []
      },
      'rooms': {
        name: 'Rooms',
        is: 'array',
        of: 'object',
        value: object?.rooms || []
      },
      'pay_override': {
        name: 'Pay override',
        value: object?.pay_override,
        is: 'boolean'
      },
      'pay': {
        name: 'Pay',
        value: object?.pay || {},
        is: 'object'
      },
      'duration': {
        name: 'Duration',
        value: object?.duration || {
          value: 1,
          unit: 'hour'
        },
        is: 'object'
      },
      'cancellation_policy': {
        name: 'Cancellation policy',
        value: object?.cancellation_policy || {
          value: 1,
          unit: 'day'
        },
        is: 'object'
      },
      'online_booking': {
        name: 'Online booking',
        value: object?.online_booking,
        is: 'boolean'
      },
      'image': {
        name: 'Image',
        value: object?.image,
        is: 'object'
      },
      'audio': {
        name: 'Audio',
        value: object?.audio,
        is: 'object'
      },
      'video': {
        name: 'Video',
        value: object?.video || {},
        is: 'object'
      },
      'discounts': {
        name: 'Discounts',
        value: object?.discounts,
        is: 'array',
        of: 'object'
      },
      'auto_payment': {
        name: 'Auto payment',
        value: object?.auto_payment !== undefined ? object.auto_payment : true,
        is: 'boolean'
      },
      'auto_charge': {
        name: 'Auto charge',
        value: object?.auto_charge !== undefined ? object.auto_charge : false,
        is: 'boolean'
      },
      'without_payment': {
        name: 'Without payment',
        value: object?.without_payment !== undefined ? object.without_payment : false,
        is: 'boolean'
      },
      'email_receipt': {
        name: 'Email receipt',
        value: false,
        is: 'boolean'
      },
      'use_service': {
        name: 'User service',
        value: object?.use_service !== undefined ? object.use_service : true,
        is: 'boolean'
      },
      'room_shared': {
        name: 'Room shared',
        value: object?.room_shared,
        is: 'boolean'
      },
      'notes_public': {
        name: 'Notes public',
        value: object?.notes_public,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'notes_private': {
        name: 'Notes private',
        value: object?.notes_private,
        max: 14e4,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 140.000 characters'
        }
      },
      'integrations': {
        name: 'Integrations',
        value: object?.integrations,
        is: 'array',
        of: 'string'
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const {
      email_receipt,

      ...valueForm
    } = refs.form.current.value;

    const body = {
      ...valueForm,

      apps: ['personal-trainer']
    } as Booking;

    if (body.service) body.service = {
      id: body.service.id,
      name: body.service.name
    };

    if (is('array', body.payment_categories)) body.payment_categories = body.payment_categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.session_categories)) body.session_categories = body.session_categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.class_categories)) body.class_categories = body.class_categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.categories)) body.categories = body.categories.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.customers)) body.customers = body.customers.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.customer_groups)) body.customer_groups = body.customer_groups.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employees)) body.employees = body.employees.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.employee_groups)) body.employee_groups = body.employee_groups.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.locations)) body.locations = body.locations.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    if (is('array', body.rooms)) body.rooms = body.rooms.map((item: any) => ({
      id: item.id,
      name: item.name
    }));

    // ends_at 
    // starts_at + duration 
    if (body.starts_at && body.duration) body.ends_at = add(body.duration.value, body.duration.unit as any, new OnesyDate(body.starts_at)).milliseconds;

    if (is('array', body.discounts)) body.discounts = body.discounts.map((item: any) => ({
      id: item.id,
      name: item.name,
      type: item.type,
      value: item.value_onesy_original
    }));

    const result = await BookingService.add(body, { query: { email_receipt } });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Booking added`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onChangeTemplate = React.useCallback((template: Template) => {
    refs.form.current.clear();

    setTimeout(() => {
      refs.form.current.updateForm((previous: any) => {
        const valueNew = { ...previous };

        const value = template.value || {};

        Object.keys(value).forEach(item => {
          if (valueNew.values[item]) {
            valueNew.values[item].value = value[item];

            setObjectValue(valueNew.value, item, value[item]);
          }
        });

        return valueNew;
      })
    }, 14);
  }, []);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  // updated service 
  React.useEffect(() => {
    const service = form.value.service as Service;

    if (service) {
      // update the form 
      const valuesNew: any = [];

      if (service.name) valuesNew.push(['name', service.name]);

      if (service.description) valuesNew.push(['description', service.description]);

      if (service.color) valuesNew.push(['color', service.color || form.value.color || 'blue']);

      if (service.spaces) valuesNew.push(['spaces', service.spaces]);

      if (service.price) valuesNew.push(['price', service.price]);

      if (service.payment_categories) valuesNew.push(['payment_categories', service.payment_categories]);

      if (service.categories) valuesNew.push([`${form.value.type}_categories`, service.categories]);

      if (service.employees) valuesNew.push(['employees', service.employees]);

      if (service.employee_groups) valuesNew.push(['employee_groups', service.employee_groups]);

      if (service.locations) valuesNew.push(['locations', service.locations]);

      if (service.rooms) valuesNew.push(['rooms', service.rooms]);

      if (service.duration) valuesNew.push(['duration', service.duration]);

      if (service.cancellation_policy) valuesNew.push(['cancellation_policy', service.cancellation_policy]);

      if (service.online_booking) valuesNew.push(['online_booking', service.online_booking]);

      if (service.image) valuesNew.push(['image', service.image]);

      if (service.audio) valuesNew.push(['audio', service.audio]);

      if (service.video) valuesNew.push(['video', service.video]);

      form.onChange(valuesNew);
    }
  }, [form.value.service?.id]);

  const errors = Object.keys(form.values).map(item => form.values[item].error).filter(Boolean).join(', ');

  const modal: any = {
    Info: <>
      <Inputs>
        <Input
          name={capitalize(form.value.type)}
        >
          <AutoCompleteObjects
            name='Service template'

            value={form.values.service.value}

            onChange={(valueNew: any) => form.onChange('service', valueNew)}

            service={ServiceService}

            query={{
              type: form.value.type
            }}

            fullWidth
          />
        </Input>

        {form.value.type === 'class' && (
          <QuantityForm
            description='How many spaces does the class have'

            form={form}

            noUnit
          />
        )}

        <Input
          name='Clients'
        >
          <AutoCompleteObjects
            name='Clients'

            value={form.values.customers.value}

            onChange={(valueNew: any) => form.onChange('customers', valueNew)}

            service={CustomerService}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Client groups'
        >
          <AutoCompleteObjects
            name='Client groups'

            value={form.values.customer_groups.value}

            onChange={(valueNew: any) => form.onChange('customer_groups', valueNew)}

            service={CustomerGroupService}

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Trainers'
        >
          <AutoCompleteObjects
            name='Trainers'

            value={form.values.employees.value}

            onChange={(valueNew: any) => form.onChange('employees', valueNew)}

            service={OrganizationService}

            method='queryUsersPost'

            multiple

            fullWidth
          />
        </Input>

        <Input
          name='Trainer groups'
        >
          <AutoCompleteObjects
            name='Trainer groups'

            value={form.values.employee_groups.value}

            onChange={(valueNew: any) => form.onChange('employee_groups', valueNew)}

            service={UserGroupService}

            multiple

            fullWidth
          />
        </Input>

        {form.value.type === 'session' ? (
          <Input
            name='Session categories'
          >
            <AutoCompleteObjects
              name='Session category'

              value={form.values.session_categories.value}

              onChange={(valueNew: any) => form.onChange('session_categories', valueNew)}

              onAdd={(valueNew: any) => form.onChange('session_categories', [...(form.value.session_categories || []), valueNew])}

              service={CategoryService}

              query={{
                type: 'session',
                apps: ['personal-trainer']
              }}

              addProps={{
                type: 'session',
                apps: ['personal-trainer']
              }}

              multiple

              fullWidth

              add

              withColor
            />
          </Input>
        ) : (
          <Input
            name='Class categories'
          >
            <AutoCompleteObjects
              name='Class category'

              value={form.values.class_categories.value}

              onChange={(valueNew: any) => form.onChange('class_categories', valueNew)}

              onAdd={(valueNew: any) => form.onChange('class_categories', [...(form.value.class_categories || []), valueNew])}

              service={CategoryService}

              query={{
                type: 'class',
                apps: ['personal-trainer']
              }}

              addProps={{
                type: 'class',
                apps: ['personal-trainer']
              }}

              multiple

              fullWidth

              add

              withColor
            />
          </Input>
        )}

        <Input
          name='Locations'
        >
          <AutoCompleteObjects
            name='Locations'

            value={form.values.locations.value}

            onChange={(valueNew: any) => form.onChange('locations', valueNew)}

            onAdd={(valueNew: any) => form.onChange('locations', [...(form.value.locations || []), valueNew])}

            service={LocationService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />
        </Input>

        <Input
          name='Rooms'
        >
          <AutoCompleteObjects
            name='Rooms'

            value={form.values.rooms.value}

            onChange={(valueNew: any) => form.onChange('rooms', valueNew)}

            onAdd={(valueNew: any) => form.onChange('rooms', [...(form.value.rooms || []), valueNew])}

            service={RoomService}

            query={{
              apps: ['personal-trainer']
            }}

            addProps={{
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add
          />

          <Input
            startName={(
              <Switch
                value={form.values['room_shared'].value}

                onChange={(valueNew: any) => form.onChange('room_shared', valueNew)}

                size='small'
              />
            )}

            name='Shared room'

            description={`Check if room ${form.value.type} is in, is a shared room.`}

            size='small'
          />
        </Input>

        <Input
          name='Cancellation policy'

          description='How long until it can be canceled'

          endHeader={(
            <Line
              gap={1}

              direction='row'

              wrap='wrap'

              align='center'

              justify={{
                default: 'flex-start',
                700: 'flex-end'
              }}

              fullWidth={{
                default: false,
                700: true
              }}

              flexNo
            >
              <DurationForm
                name=''

                form={form}

                property='cancellation_policy'

                fullWidth={false}
              />
            </Line>
          )}
        />

        <Repeat
          form={form}

          description='Repeat the booking'

          fullWidth
        />

        <Input
          name='Integrations'

          description='Note: At the moment integration is one way only. That means we will create for example a Google Calendar event on booking add, but if you update booking date/time afterwards, you will have to manually update your Google Calendar event for it. You have to have integration connected in organization settings for this to work.'
        >
          <Select
            name='Integrations'

            value={form.value.integrations}

            onChange={(valueNew: any) => form.onChange('integrations', valueNew)}

            options={optionsBookingIntegrations}

            multiple

            clear

            fullWidth
          />
        </Input>

        <Input
          startName={(
            <Switch
              value={form.values['online_booking'].value}

              onChange={(valueNew: any) => form.onChange('online_booking', valueNew)}
            />
          )}

          name='Online booking'

          description='Check if you want this class to show on the website as available for users to choose for booking online'
        />
      </Inputs>
    </>,

    Finances: <>
      <Inputs>
        <PriceForm
          description='Class price per person'

          form={form}
        />

        <Input
          startName={(
            <Switch
              value={form.values['use_service'].value}

              onChange={(valueNew: any) => form.onChange('use_service', valueNew)}
            />
          )}

          name='Use client package / membership for payment'
        />

        <Input
          name='Discounts'

          description='Discounts to be applied for the payment/charge (optional)'
        >
          <AutoCompleteObjects
            name='Discount'

            value={form.values.discounts.value}

            query={{
              remaining: { $gt: 0 },
              apps: ['personal-trainer']
            }}

            onChange={(valueNew: any) => form.onChange('discounts', valueNew)}

            service={DiscountService}

            multiple

            fullWidth
          />
        </Input>

        <Input
          startName={(
            <Switch
              value={form.values['pay_override'].value}

              onChange={(valueNew: any) => form.onChange('pay_override', valueNew)}
            />
          )}

          name='Pay override'

          description='If selected this will be used as the payment details for the trainers, for this booking, instead their individual payment details are used.'
        />

        {form.value.pay_override && (
          <PayForm
            name='Booking trainer payment override'

            description={`If you want to set custom payment for this ${form.value.type} specifically, for all trainers. It will override each trainer's individual payment details.`}

            form={form}
          />
        )}

        <Input
          name='Payment categories'
        >
          <AutoCompleteObjects
            name='Payment category'

            value={form.values.payment_categories.value}

            onChange={(valueNew: any) => form.onChange('payment_categories', valueNew)}

            onAdd={(valueNew: any) => form.onChange('payment_categories', [...(form.value.payment_categories || []), valueNew])}

            service={CategoryService}

            query={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            addProps={{
              type: 'payment',
              apps: ['personal-trainer']
            }}

            multiple

            fullWidth

            add

            withColor
          />
        </Input>

        <Input
          name='Accounting'

          description='If clients have no service purchased, select how to handle their payment for this booking.'
        >
          <Buttons
            value={form.value.auto_payment ? 'auto-payment' : form.value.auto_charge ? 'auto-charge' : 'without-payment'}

            orientation={{
              default: 'horizontal',
              700: 'vertical'
            }}
          >
            <Chip
              value='auto-payment'

              onClick={() => form.onChange([
                ['auto_payment', true],
                ['auto_charge', false],
                ['without_payment', false]
              ])}
            >
              Auto payment
            </Chip>

            <Chip
              value='auto-charge'

              onClick={() => form.onChange([
                ['auto_payment', false],
                ['auto_charge', true],
                ['without_payment', false]
              ])}
            >
              Auto charge
            </Chip>

            <Chip
              value='without-payment'

              onClick={() => form.onChange([
                ['auto_payment', false],
                ['auto_charge', false],
                ['without_payment', true]
              ])}
            >
              Without payment
            </Chip>
          </Buttons>
        </Input>

        <Input
          startName={(
            <Switch
              value={form.values['email_receipt'].value}

              onChange={(valueNew: any) => form.onChange('email_receipt', valueNew)}
            />
          )}

          name='Email receipt'
        />
      </Inputs>
    </>,

    About: <>
      <Inputs>
        <Input
          name='Name'
        >
          <TextField
            placeholder='Name'

            valueDefault={form.values['name'].value}

            error={form.values['name'].error}

            helperText={form.values['name'].error}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <TextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            error={form.values['description'].error}

            helperText={form.values['description'].error}

            onChange={(valueNew: any) => form.onChange('description', innerHTMLToText(valueNew), undefined, { rerenderOnUpdate: false })}

            minRows={3}

            maxRows={4}

            multiline

            fullWidth
          />
        </Input>

        <MediaForm
          type='image'

          name='Image'

          form={form}
        />

        <MediaForm
          type='audio'

          name='Audio'

          form={form}
        />

        <MediaForm
          type='video'

          name='Video'

          form={form}
        />
      </Inputs>
    </>,

    Notes: <>
      <Inputs>
        <Input
          name='Notes private'

          description='Only visible to employees'
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_private.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_private', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>

        <Input
          name='Notes public'

          description='Visible to everyone '
        >
          <SmartTextField
            placeholder='Write here...'

            valueDefault={textToInnerHTML(form.values.notes_public.value || '')}

            onChange={(valueNew: string) => form.onChange('notes_public', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Booking'

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialEventNote },
        { name: 'Finances', Icon: IconMaterialWallet },
        { name: 'About', Icon: IconMaterialSmartDisplay },
        { name: 'Notes', Icon: IconMaterialNoteStackW100Rounded }
      ]}

      onChangeTab={onChangeTab}

      endHeaderLeft={mode === 'update' ? <>
        <Buttons
          value={form.value.type || 'session'}

          size='small'
        >
          {optionsBookingType.map((item, index) => (
            <Chip
              key={index}

              value={item.value}

              onClick={() => {
                form.onChange([
                  ['service', null],
                  ['session_categories', []],
                  ['class_categories', []],
                  ['type', item.value]
                ]);
              }}
            >
              {item.name}
            </Chip>
          ))}
        </Buttons>
      </> : capitalize(object?.type || 'session')}

      startHeaderRight={mode === 'update' ? <>
        <Input
          name='Starts at'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <CalendarMenu
            value={form.value}

            onChange={form.onChange}

            dateProperty='starts_at'

            closeOnChange={false}

            time

            noRepeat

            noRemove

            style={{
              marginLeft: -8
            }}
          />
        </Input>

        <DurationForm
          form={form}
        />
      </> : <>
        <Type
          version='b2'
        >
          {getDate(form.value.starts_at, 'entire')}
        </Type>

        <Input
          name='Duration'

          gap={0}

          TypeProps={{
            version: 't3'
          }}

          size='small'
        >
          <Type
            version='b2'
          >
            {!!Object.keys(form.value.duration || {}).length ? `${form.value.duration?.value} ${form.value.duration?.unit}` : 'No duration'}
          </Type>
        </Input>
      </>}

      footerLeftEnd={mode === 'update' ? <>
        <SelectAColor
          value={form.value.color}

          onChange={(valueNew: any) => form.onChange('color', valueNew)}
        />
      </> : (
        <Color
          color={form.value.color}
        />
      )}

      footerRightStart={<>
        <Templates
          model='bookings'

          value={form.value}

          onChange={onChangeTemplate}
        />

        {errors && (
          <Type
            color='error'
          >
            {errors}
          </Type>
        )}
      </>}

      object={object}

      add

      onChangeMode={onChangeMode}

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;
