import React from 'react';

import { AutoCompleteCurrency } from '@onesy/ui-react'
import { IAutoCompleteCurrency } from '@onesy/ui-react/AutoCompleteCurrency/AutoCompleteCurrency';

const Element: React.FC<IAutoCompleteCurrency> = props => {

  return (
    <AutoCompleteCurrency
      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      clear={false}

      {...props}
    />
  );
};

export default Element;
