import React from 'react';

import { arrayMoveItem, copy, getID, innerHTMLToText, is, textToInnerHTML } from '@onesy/utils';
import { IconButton, Label, Switch, Tooltip, Type, useForm, useSnackbars } from '@onesy/ui-react';
import { style } from '@onesy/style-react';

import IconMaterialDemography from '@onesy/icons-material-rounded-react/IconMaterialDemographyW100';
import IconMaterialListAlt from '@onesy/icons-material-rounded-react/IconMaterialListAltW100';
import IconMaterialAdd from '@onesy/icons-material-rounded-react/IconMaterialAddW100';
import IconMaterialKeyboardArrowUp from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialContentCopy from '@onesy/icons-material-rounded-react/IconMaterialContentCopyW100';
import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';

import { AutoCompleteObjects, Input, Inputs, ModalForm, NumericTextField, Select, SmartTextField, TextField } from 'ui';
import { AppService, CustomerService, FormService } from 'services';
import { getErrorMessage, optionsFormInputType } from 'utils';
import FormResponses from '../../pages/main/Clients/Forms/FormResponses';

const useStyle = style(theme => ({
  root: {

  },

  ...theme.classes(theme)
}), { name: 'onesy-Form' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object: object_,

    onConfirm
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [object, setObject] = React.useState(object_);
  const [tab, setTab] = React.useState('Form');
  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: object?.name,
        required: true,
        max: 1400,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'description': {
        name: 'Description',
        value: object?.description,
        max: 4400,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 4400 characters'
        }
      },
      'inputs': {
        name: 'Inputs',
        value: object?.inputs || []
      },
      'customers': {
        name: 'Customers',
        value: (object?.customers || []).map(item => ({
          ...item,

          value: item.id
        }))
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();
  }, []);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      ...refs.form.current.value,

      apps: ['personal-trainer']
    };

    if (is('array', body.customers)) body.customers = body.customers.map(item => ({
      id: item.id,
      name: item.name
    }));

    const result = !object?.id ? await FormService.add(body) : await FormService.update(object?.id, body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Form ${!object?.id ? 'added' : 'updated'}`
      });

      setObject(result.response.response);

      if (is('function', onConfirm)) onConfirm();

      if (!object?.id) onClose();
    }

    setLoading(false);
  }, [object, form, onConfirm, onClose]);

  const onAddInput = React.useCallback((index: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    inputs.push({
      id: getID(),
      type: 'text'
    });

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const onMoveInput = React.useCallback((indexPrevious: number, indexNew: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    arrayMoveItem(inputs, indexPrevious, indexNew);

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const onCopyInput = React.useCallback((index: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    const item = copy(inputs[index]);

    item.id = getID();
    item.name = `${item.name || ''} Copy`.trim();

    inputs.splice(index + 1, 0, item);

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const onRemoveInput = React.useCallback((index: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    inputs.splice(index, 1);

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const onAddInputOption = React.useCallback((index: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    if (!inputs[index].options) inputs[index].options = [];

    inputs[index].options.push({
      id: getID(),
      type: 'type',
      value: undefined
    });

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const onRemoveInputOption = React.useCallback((index: number, indexOption: number) => {
    const inputs = [...(refs.form.current.values.inputs.value || [])];

    inputs[index].options.splice(indexOption, 1);

    refs.form.current.onChange('inputs', inputs);
  }, []);

  const formOnChangeOptions: any = {
    rerenderOnUpdate: false
  };

  const inputProps: any = {
    fullWidth: true,
    size: 'small'
  };

  const iconProps: any = {
    size: 'large'
  };

  const modal: any = {
    'Form': (
      <Inputs
        gap={2}

        fullWidth

        className={classes.page}
      >
        <Input
          name='Name'
        >
          <TextField
            name='Name'

            valueDefault={textToInnerHTML(form.values['name'].value)}

            onChange={(valueNew: any) => form.onChange('name', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values.name.error}

            helperText={form.values.name.error}

            fullWidth
          />
        </Input>

        <Input
          name='Description'
        >
          <SmartTextField
            placeholder='Description'

            valueDefault={textToInnerHTML(form.values['description'].value || '')}

            onChange={(valueNew: any) => form.onChange('description', valueNew, undefined, { rerenderOnUpdate: false })}

            error={!!form.values.description.error}

            helperText={form.values.description.error}

            minRows={3}

            maxRows={4}

            multiline

            edit

            fullWidth
          />
        </Input>

        <Input
          name='Clients'

          description='Clients you want to assign the form to'
        >
          <AutoCompleteObjects
            name='Clients'

            value={form.values.customers.value}

            onChange={(valueNew: any) => form.onChange('customers', valueNew)}

            service={CustomerService}

            query={{
              apps: ['personal-trainer']
            }}

            error={form.values['customers'].error}

            helperText={form.values['customers'].error}

            multiple

            fullWidth
          />
        </Input>

        <Inputs
          name='Inputs'

          endName={(
            <Tooltip
              name='Add input'
            >
              <IconButton
                onClick={onAddInput}
              >
                <IconMaterialAdd {...iconProps} />
              </IconButton>
            </Tooltip>
          )}
        >
          {form.values.inputs.value.map((input: any, index: number) => (
            <Input
              key={input.id}

              name={(
                <Select
                  name='Type'

                  valueDefault={input.type}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.type`)}

                  options={optionsFormInputType}

                  {...inputProps}
                />
              )}

              endName={(
                <>
                  <Tooltip
                    name='Move input up'
                  >
                    <IconButton
                      onClick={() => onMoveInput(index, index - 1)}

                      disabled={!index}
                    >
                      <IconMaterialKeyboardArrowUp {...iconProps} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Move input down'
                  >
                    <IconButton
                      onClick={() => onMoveInput(index, index + 1)}

                      disabled={form.value.inputs?.length - 1 === index}
                    >
                      <IconMaterialKeyboardArrowDown {...iconProps} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Copy input'
                  >
                    <IconButton
                      onClick={() => onCopyInput(index)}
                    >
                      <IconMaterialContentCopy  {...iconProps} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    name='Remove input'
                  >
                    <IconButton
                      onClick={() => onRemoveInput(index)}
                    >
                      <IconMaterialRemove  {...iconProps} />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              expand
            >
              <Input
                name='Name'

                size='small'
              >
                <TextField
                  name='Name'

                  valueDefault={input.name}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.name`, formOnChangeOptions)}

                  {...inputProps}
                />
              </Input>

              <Input
                name='Description'

                size='small'
              >
                <SmartTextField
                  placeholder='Description'

                  valueDefault={textToInnerHTML(input.description || '')}

                  onChange={(valueNew: any) => form.onChange('inputs', innerHTMLToText(valueNew), `${index}.description`, formOnChangeOptions)}

                  minRows={3}

                  maxRows={4}

                  multiline

                  edit

                  {...inputProps}
                />
              </Input>

              {['confirm'].includes(input.type) && <>
                <Input
                  name='Confirm text'

                  size='small'
                >
                  <TextField
                    name='Confirm text'

                    valueDefault={input.confirm_text}

                    onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.confirm_text`, formOnChangeOptions)}

                    {...inputProps}
                  />
                </Input>
              </>}

              {['select'].includes(input.type) && (
                <Label
                  checkedDefault={input.multiple}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.multiple`)}
                >
                  <Switch size='small' />

                  Select multiple
                </Label>
              )}

              {['select', 'radios', 'checkboxes'].includes(input.type) && (
                <Input
                  name='Options'

                  endName={(
                    <Tooltip
                      name={`Add option`}
                    >
                      <IconButton
                        onClick={() => onAddInputOption(index)}

                        size='small'
                      >
                        <IconMaterialAdd size='regular' />
                      </IconButton>
                    </Tooltip>
                  )}

                  size='small'

                  style={{
                    marginTop: 12
                  }}
                >
                  {input.options?.map((itemOption: any, indexOption: number) => (
                    <Input
                      key={index}

                      start={(
                        <Type
                          version='l2'
                        >
                          Input option {indexOption + 1}
                        </Type>
                      )}

                      end={(
                        <Tooltip
                          name={`Remove option`}
                        >
                          <IconButton
                            onClick={() => onRemoveInputOption(index, indexOption)}

                            size='small'
                          >
                            <IconMaterialRemove size='regular' />
                          </IconButton>
                        </Tooltip>
                      )}

                      size='small'
                    >
                      <TextField
                        name='Option'

                        valueDefault={itemOption.value || ''}

                        onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.options.${indexOption}.value`, formOnChangeOptions)}

                        size='small'

                        fullWidth
                      />
                    </Input>
                  ))}
                </Input>
              )}

              {['slider'].includes(input.type) && <>
                <Input
                  name='Slider labels'

                  size='small'
                >
                  <TextField
                    name='Label at the start'

                    valueDefault={input.label_start}

                    onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.label_start`, formOnChangeOptions)}

                    {...inputProps}
                  />

                  <TextField
                    name='Label at the end'

                    valueDefault={input.label_end}

                    onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.label_end`, formOnChangeOptions)}

                    {...inputProps}
                  />
                </Input>
              </>}

              {['text'].includes(input.type) && <>
                <Label
                  checkedDefault={input.multiline}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.multiline`)}
                >
                  <Switch size='small' />

                  Multiline
                </Label>
              </>}

              {['slider'].includes(input.type) && <>
                <NumericTextField
                  name='Min'

                  valueDefault={input.min}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.min`, formOnChangeOptions)}

                  {...inputProps}
                />
              </>}

              {['rating', 'slider'].includes(input.type) && <>
                <NumericTextField
                  name='Max'

                  valueDefault={input.max}

                  onChange={(valueNew: any) => form.onChange('inputs', valueNew, `${index}.max`, formOnChangeOptions)}

                  {...inputProps}
                />
              </>}
            </Input>
          ))}
        </Inputs>
      </Inputs>
    ),

    'Responses': (
      <FormResponses
        name=''

        form={object}

        style={{
          padding: 0
        }}
      />
    )
  };

  return (
    <ModalForm
      {...props}

      tab={tab}

      tabs={[
        { name: 'Form', Icon: IconMaterialListAlt },

        ...(object ? [
          { name: 'Responses', Icon: IconMaterialDemography }
        ] : [])
      ]}

      onChangeTab={onChangeTab}

      object={object}

      add

      onSubmit={onSubmit}

      onNext={onNext}

      onClose={onClose}

      loading={loading}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;